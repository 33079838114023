import MainButton from "../buttonComponent/MainButton";

export default function ValidPop({
  title,
  desc,
  buttonsObject,
  showValidPop,
  bgcAction,
}) {
  return (
    <>
      {showValidPop && (
        <div
          onClick={bgcAction}
          className="bluredDiv"
          style={{ zIndex: "9999" }}
        ></div>
      )}
      <div className="validPop">
        <h2 className="validPop__title">{title}</h2>
        {desc && <p className="validPop__desc">{desc}</p>}
        <div className="validPop__buttonContainer">
          {buttonsObject &&
            buttonsObject.length <= 2 &&
            buttonsObject.map((button, k) => {
              return (
                <MainButton
                  buttonAction={(e) => button.action(e)}
                  buttonIcon={button.icon}
                  key={k}
                  className={`validPop__buttonContainer_${button.className}`}
                  buttonText={button.text}
                  buttonType={button.buttonType}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
