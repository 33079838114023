import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import TabBar from "./TabBar";
import Dashboard from "../customer/dashboard/Dashboard";
import { useEffect, useState } from "react";
import PrivateRouter from "../../privateRouter";
import useAuth from "../../hooks/useAuth";

export default function Layout() {
  useAuth();
  const { pathname } = useLocation();
  const roles = ["1", "2", "3", "4", "5", "6"];

  // state
  const [showNav, setShowNav] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      if (window.innerWidth >= 1024) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // if (showNav && innerWidth < 1024) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "auto";
    // }
  }, [showNav, innerWidth]);

  return (
    <>
      <div>
        {showNav && innerWidth < 1024 && (
          <div onClick={() => setShowNav(false)} className="activeBlurBg"></div>
        )}
        <TabBar setShowNav={setShowNav} showNav={showNav} />
        <div className="main">
          {
            <PrivateRouter roleList={roles}>
              {" "}
              {pathname === "/" ? <Dashboard /> : <Outlet />}{" "}
            </PrivateRouter>
          }
        </div>
      </div>
    </>
  );
}
