import PrivateRouter from "../privateRouter";
import AdminDashboard from "../routes/admin/AdminDashboard";
import AdminCustomer from "../routes/admin/AdminCustomer";
import AdminCustomersList from "../routes/admin/AdminCustomersList";
import AdminConnections from "../routes/admin/AdminConnections";
import AdminArticles from "../routes/admin/AdminArticles";
import AdminTVA from "../routes/admin/AdminTVA";
import AdminSettings from "../routes/admin/AdminSettings";
import AdminCatalogs from "../routes/admin/AdminCatalogs";

const roles = ["2"];

export const adminPaths = [
    {
        path: "admin-dashboard",
        element: <PrivateRouter roleList={roles}><AdminDashboard /></PrivateRouter>,
    },
    {
        path: "admin-customer/",
        element: <PrivateRouter roleList={roles}><AdminCustomer /></PrivateRouter>,
    },
    {
        path: "admin-customer/:id",
        element: <PrivateRouter roleList={roles}><AdminCustomer /></PrivateRouter>,
    },
    {
        path: "admin-customers-list",
        element: <PrivateRouter roleList={roles}><AdminCustomersList /></PrivateRouter>,
    },
    {
        path: "admin-connections",
        element:  <PrivateRouter roleList={roles}><AdminConnections /></PrivateRouter>,
    },
    {
        path: "admin-articles",
        element:<PrivateRouter roleList={roles}> <AdminArticles /></PrivateRouter>,
    },
    {
        path: "admin-catalog",
        element:  <PrivateRouter roleList={roles}><AdminCatalogs /></PrivateRouter>,
    },
    {
        path: "admin-catalog/:id",
        element:  <PrivateRouter roleList={roles}><AdminCatalogs /></PrivateRouter>,
    },
    {
        path: "admin-tva",
        element:  <PrivateRouter roleList={roles}><AdminTVA /></PrivateRouter>,
    },
    {
        path: "admin-settings",
        element:  <PrivateRouter roleList={roles}><AdminSettings /></PrivateRouter>,
    },
]