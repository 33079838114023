import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { DeleteApiContext } from "../../context/apiCalls/deleteApiCalls";
import ValidPop from "./popComponents/validPop";
import bin from "../../assets/images/icons/Picto/supprimer.svg";
import redBin from "../../assets/images/icons/Picto/supprimer-rouge.svg";
import whiteBin from "../../assets/images/icons/Picto/supprimer-blanc.svg";
import { toast } from "react-toastify";

export default function DeletButton({
  url,
  id,
  destination,
  className,
  setRefresh,
  deleteItemByNotId,
  refresh,
  validPopContent,
  color,
}) {
  const navigate = useNavigate();

  const deleteApiCalls = useContext(DeleteApiContext);
  const [showValidPop, setShowValidPop] = useState(false);

  const handleDelet = (e) => {
    e?.preventDefault();
    if (id && url && !id?.toLowerCase().includes("new")) {
      deleteApiCalls.deleteItem(url, id).then((data) => {
        data?.success || data?.status === "success" ? toast.success(validPopContent.toasterMessage || "L'élément a été supprimé avec succès") : toast.error("Erreur lors de la suppression");
        setShowValidPop(false);
        if (setRefresh) {
          if (refresh) {
            setRefresh(false);
          } else {
            setRefresh(true);
          }
        }
      });
    } else {
      deleteItemByNotId(id);
      setShowValidPop(false);
    }
    destination && navigate(destination);
  };

  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    { text: "Continuer", className: "continueButton", action: handleDelet },
  ];

  return (
    <div className={className}>
      {showValidPop && (
        <ValidPop
          title={validPopContent?.title || ""}
          desc={validPopContent?.desc || ""}
          toasterMessage={validPopContent?.toasterMessage}
          showValidPop={showValidPop}
          setShowValidPop={setShowValidPop}
          buttonsObject={buttonsObject}
          bgcAction={(e) => setShowValidPop(false)}
        />
      )}
      {color === "red" ? (
        <img
          onClick={() => setShowValidPop(true)}
          className={`mainDeleteButton`}
          src={redBin}
          alt="delete"
        />
      ) : color === "white" ? (
        <img
          onClick={() => setShowValidPop(true)}
          className={`mainDeleteButton`}
          src={whiteBin}
          alt="delete"
        />
      ) : (
        <img
          onClick={() => setShowValidPop(true)}
          className={`mainDeleteButton`}
          src={bin}
          alt="delete"
        />
      )}
    </div>
  );
}
