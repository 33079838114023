import { useContext, useEffect, useRef, useState, useCallback } from "react";
import Loader from "../../components/addOns/loader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import MainHeader from "../../components/addOns/headers/MainHeader";
import {
  faCircleCheck,
  faCopy,
  faDownload,
  // faFileMedical,
  faPaperPlane,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Card from "../../components/addOns/card";
import ValidPop from "../../components/addOns/popComponents/validPop";
import TotalAmonts from "../../components/addOns/TotalAmonts";
import Paging from "../../components/addOns/Paging";
import { PdfContext } from "../../context/PdfContext";
import { AppContext } from "../../context/appContext";
import MailPop from "../../components/addOns/MailPop";

function DepositsList() {
  const navigate = useNavigate();
  // Refs
  const firstNameRef = useRef("");
  const lastNameRef = useRef("");
  const depositRef = useRef("");
  const quoteRef = useRef("");
  const invoiceRef = useRef("");
  const [dateRange, setDateRange] = useState([]);

  // States
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("datedown");
  const [interlocutor, setInterlocutor] = useState([]);
  const [searchSelect, setSearchSelect] = useState("");
  const [depositState, setDepositState] = useState("");
  const [showValidPop, setShowValidPop] = useState(false);
  const [depositId, setDepositId] = useState();
  const [totalTTC, setTotalTTC] = useState(0);
  const [perPage, setPerPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [nbPages, setNbPages] = useState();
  const [listRefresh, setListRefresh] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [mailData, setMailData] = useState({ id: "", title: "" });

  const getApiContext = useContext(GetApiContext);
  const pdfContext = useContext(PdfContext);
  const appContext = useContext(AppContext);

  // Merged function: if event provided, extract searchData from refs, else use defaults.
  const fetchDeposits = useCallback(async (e = null) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const searchData = {
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          depositRef: depositRef.current.value,
          quoteRef: quoteRef.current.value,
          invoiceRef: invoiceRef.current.value,
          interlocutor: searchSelect,
          startDate: dateRange[0] ? dateRange[0].toLocaleDateString().split("T")[0] : null,
          endDate: dateRange[1] ? dateRange[1].toLocaleDateString().split("T")[0] : null,
        };
      const response = await getApiContext.getDepositsList(
        depositState,
        filter,
        currentPage,
        perPage,
        searchData
        );
      
      setInterlocutor(response.listInterlocutors);
      setDeposits(response.depositsList);
      setTotalTTC(response.totalAmount);
      setPerPage(response.perPage);
      setNbPages(response.nbPages);
      setCurrentPage(response.currentPage || 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [depositState, filter, currentPage, perPage, getApiContext, dateRange, searchSelect]);

  useEffect(() => {
    if (listRefresh) {
      fetchDeposits();
      setListRefresh(false);
    }
    if (isFirstLoad) {
      fetchDeposits();
      setIsFirstLoad(false);
    }
  }, [fetchDeposits, listRefresh, isFirstLoad]);
  
  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setCurrentPage(1);
    setDateRange([]);
    setSearchSelect("");
    setListRefresh(true);
  };

  const onSearchSelectChange = (e) => {
    setSearchSelect(e.target.value);
  };

  const handleOpenValidItem = (id) => {
    setShowValidPop(true);
    setDepositId(id);
  };

  const handleOpenCredit = () => {
    navigate(`/depositsList/deposit/${depositId}`);
  };

  // const openEmptyDeposit = () => {
  //   navigate("/depositsList/deposit/");
  // };

  const headerButtons = [
    // {
    //   title: "Nouveau acompte",
    //   className: "",
    //   icon: faFileMedical,
    //   action: openEmptyDeposit,
    //   showByRoleId: true,
    // },
  ];

  const sortOptions = {
    options: [
      { value: "datedown", content: "Date décroissante" },
      { value: "dateup", content: "Date croissante" },
      { value: "refup", content: "Références croissantes" },
      { value: "refdown", content: "Références décroissantes" },
    ],
  };

  const duplicateDeposit = (e) => {
    const dupId = e.target.parentNode.parentNode.id;
    getApiContext.getDeposit("", dupId).then((response) => {
      if (response?.data) {
        const deposit = response.data.deposit;
        navigate(`/depositsList/deposit/${deposit?.id}`);
      }
    });
  };

  return (
    <>
    <MailPop
      endpoint={"c-deposit-send-email"}
      id={mailData.id}
      itemName={"Acompte"}
      title={mailData.title}
    />
      <MainHeader headerTitle={"ACOMPTES"} objectButtons={headerButtons} />
      {(loading || pdfContext.loading) && <Loader />}
      {showValidPop && (
        <ValidPop
          bgcAction={(e) => setShowValidPop(false)}
          showValidPop={showValidPop}
          title={"Continuer vers cet avoir ?"}
          desc={"Cet avoir est validé, voulez-vous vraiment la modifier ?"}
          buttonsObject={[
            {
              text: "Retour",
              className: "backButton",
              action: (e) => setShowValidPop(false),
            },
            {
              text: "Continuer",
              className: "continueButton",
              action: handleOpenCredit,
            },
          ]}
        />
      )}
      <div className="listPage">
        <div className="listPage__filterContainer">
          <StateFilter
            setState={setDepositState}
            state={depositState}
            stateObject={[
              { valid: "", name: "Tous les acomptes" },
              { valid: 1, name: "Acomptes payés" },
              { valid: 0, name: "Acomptes impayés" }]}
            setListRefresh={setListRefresh}
          />
          <SearchComponent
            refresh={refresh}
            onSearchSelectChange={onSearchSelectChange}
            onSearchSubmit={fetchDeposits}
            contentObject={{
              ContentName: "ACOMPTE",
              Content: [
                { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
                { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
                { name: "refAconmpte", title: "Référence Acompte", type: "text", ref: depositRef },
                { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteRef },
                { name: "refFacture", title: "Référence Facture", type: "text", ref: invoiceRef },
                { name: "dateRange", title: "Date de création", type: "dateRangePicker", ref: dateRange },
              ],
            }}
            interlocutor={interlocutor}
            dateRange={dateRange}
            setDateRange={setDateRange}
            showFilter={true}
            setListRefresh={setListRefresh}
          />
          <SortByFilter
            setSort={setFilter}
            sortOptions={sortOptions}
            setListRefresh={setListRefresh}
          />
        </div>
        {deposits &&
          deposits.length > 0 &&
          deposits.map((deposit, k) => {
            const element = {
              id: deposit.id,
              valid: deposit.valid,
              state: deposit.valid === 1 || deposit.valid === "1" ? "paid" : "unpaid",
              date: deposit.depositDate,
              ref: deposit.ref,
              subject: deposit.subject,
              lastName: deposit.contact.lastName,
              firstName: deposit.contact.firstName,
              society: deposit.contact.society,
              depositRate: deposit.rate,
              totalTtc: deposit.totalTtc,
              directionPath: "/depositsList/deposit/",
              initials: deposit.user.initials,
              origin: deposit.invoice
                ? "Facture n°" + deposit.invoice.ref
                : deposit.quote
                ? "Devis n°" + deposit.quote.ref
                : "",
            };
            return (
              <Card
                key={k}
                tableName={"deposit"}
                setListRefresh={setListRefresh}
                deleteEndPoint="c-deactive-deposit"
                element={element}
                otherButtonList={[
                  {
                    title: "Télécharger",
                    icon: faDownload,
                    className: "edit",
                    action: () => pdfContext.handlePDF("deposit", deposit.id, false),
                  },
                  {
                    title: "Envoyer",
                    icon: faPaperPlane,
                    className: "delete",
                    action: (e) => {
                      setMailData({ id: deposit?.id, title: deposit?.subject });
                      appContext.setShowMailPop(true) }},
                  {
                    title: "Imprimer",
                    icon: faPrint,
                    className: "edit",
                    action: () => pdfContext.handlePDF("deposit", deposit.id, true),
                  },
                  {
                    title: "Dupliquer",
                    icon: faCopy,
                    className: "dup",
                    action: (e) => duplicateDeposit(e),
                  },
                ]}
                stateButtonsList={[
                  {
                    title: "Payé",
                    icon: faCircleCheck,
                    color: "stateGreen",
                    name: "paid",
                  },
                  {
                    title: "Non Payé",
                    icon: faCircleCheck,
                    color: "stateOrange",
                    name: "unpaid",
                  },
                ]}
                handleOpenValidItem={handleOpenValidItem}
                roleVerif={true}
                elementTitle={"ACOMPTE N°"}
              />
            );
          })}
        <TotalAmonts totalTTC={totalTTC} />
        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
          setListRefresh={setListRefresh}
        />
      </div>
    </>
  );
}

export default DepositsList;
