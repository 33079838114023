import {
  faCircleCheck,
  faClipboardList,
  faCopy,
  faDownload,
  faFileMedical,
  faPaperPlane,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { useContext, useEffect, useRef, useState, useCallback } from "react";
import Paging from "../../components/addOns/Paging";
import TotalAmonts from "../../components/addOns/TotalAmonts";
import MainHeader from "../../components/addOns/headers/MainHeader";
import Card from "../../components/addOns/card";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Loader from "../../components/addOns/loader";
import ValidPop from "../../components/addOns/popComponents/validPop";
import { useAuthState } from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";
import { PdfContext } from "../../context/PdfContext";
import { AppContext } from "../../context/appContext";
import MailPop from "../../components/addOns/MailPop";

function InvoicesList() {
  const { roleId } = useAuthState();
  const roleList = ["1", "2", "3", "4", "5", "6"];
  const roleVerif = roleVerify(roleList, roleId);

  const getApiContext = useContext(GetApiContext);
  const pdfContext = useContext(PdfContext);
  const appContext = useContext(AppContext);

  // refs
  const firstNameRef = useRef("");
  const lastNameRef = useRef("");
  const depositRef = useRef("");
  const invoiceRef = useRef("");
  const societyRef = useRef("");
  const [dateRange, setDateRange] = useState([]);

  let navigate = useNavigate();

  const [invoicesList, setInvoicesList] = useState();
  const [interlocutors, setInterlocutors] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [listRefresh, setListRefresh] = useState(false);
  const [perPage, setPerPage] = useState();
  const [nbPages, setNbPages] = useState();
  const [totalTTC, setTotalTTC] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [state, setState] = useState("all");
  const [sort, setSort] = useState("datedown");
  const [selectedInterlocutor, setSelectedInterlocutor] = useState();
  const [loading, setLoading] = useState(false);
  const [showValidPop, setShowValidPop] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [mailData, setMailData] = useState({ id: "", title: "" });

  useEffect(() => {
    document.querySelector("body").style.overflow = showValidPop
      ? "hidden"
      : "auto";
  }, [showValidPop]);

  const fetchInvoices = useCallback(async () => {
    if (roleVerif) {
      try {
        setLoading(true);
        const response = await getApiContext.getInvoicesList(
          currentPage,
          perPage,
          state,
          sort,
          firstNameRef.current.value,
          lastNameRef.current.value,
          depositRef.current.value,
          invoiceRef.current.value,
          societyRef.current.value,
          dateRange[0] ? dateRange[0].toLocaleDateString().split("T")[0] : null,
          dateRange[1] ? dateRange[1].toLocaleDateString().split("T")[0] : null,
          selectedInterlocutor
        );

        // console.log("response", response);
        
        setInvoicesList(response.lstInvoice);
        setInterlocutors(response.listInterlocutors);
        setPerPage(response.perPage);
        setNbPages(response.nbPages);
        setCurrentPage(response.currentPage);
        setTotalTTC(response.totalAmonts?.total_ttc);
        setTotalHT(response.totalAmonts?.total_ht);
        setTotalVAT(response.totalAmonts?.total_vat);
        setLoading(false);
        setListRefresh(false);
      } catch (error) {
        console.error("Erreur lors du chargement des factures :", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("role not verified");
    }
  }, [
    getApiContext,
    currentPage,
    perPage,
    state,
    sort,
    roleVerif,
    firstNameRef,
    lastNameRef,
    depositRef,
    invoiceRef,
    societyRef,
    dateRange,
    selectedInterlocutor,
  ]);

  useEffect(() => {
    if (isFirstLoad) {
      fetchInvoices();
      setIsFirstLoad(false);
    }
    if (listRefresh) {
      fetchInvoices();
    }
  }, [fetchInvoices, isFirstLoad, listRefresh]);

  const openEmptyInvoice = () => {
    navigate("/invoicesList/invoice/");
  };

  const duplicateInvoice = (e) => {
    const dupId = e.target.parentNode.parentNode.id;
    getApiContext.getInvoice("", dupId).then((response) => {
      const invoice = response.invoice;
      navigate(`/invoicesList/invoice/${invoice?.id}`);
    });
  };

  const openDeliveriesList = () => {
    navigate("deliveriesList");
  };

  const handleOpenDelivery = (e) => {
    const invoiceId = e.target.parentNode.parentNode.id;
    getApiContext
      .getDelivery(null, invoiceId, null)
      .then((response) => {
        console.log("response", response);
        const delivery = response.delivery;
        if (delivery && delivery.id) {
          navigate(`/invoicesList/deliveriesList/delivery/${delivery?.id}`);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const objectButtons = [
    {
      title: "Bons de livraison",
      className: "deliveryButton hide-text-650",
      icon: faClipboardList,
      action: openDeliveriesList,
      showByRoleId: false,
    },
    {
      title: "Nouvelle facture",
      icon: faFileMedical,
      action: openEmptyInvoice,
      showByRoleId: true,
      className: "hide-text-650",
    },
  ];

  const otherButtonList = [
    { title: "Télécharger", icon: faDownload, action: (e) => pdfContext.handlePDF("invoice", e.target.parentNode.parentNode.id, false) },
    { title: "Envoyer", icon: faPaperPlane, action: (e) => {
      let selectedInvoice = invoicesList.find((invoice) => invoice.invoiceId === e.target.parentNode.parentNode.id);
      setMailData({ id: selectedInvoice.invoiceId, title: selectedInvoice.subject });
      appContext.setShowMailPop(true) }},
    { title: "Imprimer", icon: faPrint, action: (e) => pdfContext.handlePDF("invoice", e.target.parentNode.parentNode.id, true) },
    { title: "Dupliquer", icon: faCopy, action: duplicateInvoice },
    {
      title: "Créer un bon de livraison",
      icon: faClipboardList,
      action: handleOpenDelivery,
    },
  ];

  const stateButtonsList = [
    {
      title: "Finalisé",
      icon: faCircleCheck,
      action: openEmptyInvoice,
      color: "stateGreen",
      name: "validated",
    },
    {
      title: "En cours",
      icon: faCircleCheck,
      action: openEmptyInvoice,
      color: "stateOrange",
      name: "in-progress",
    },
    {
      title: "Annulé",
      icon: faCircleCheck,
      action: openEmptyInvoice,
      color: "stateRed",
      name: "canceled",
    },
  ];

  const stateObject = [
    { valid: "all", name: "toutes les factures" },
    { valid: "validated", name: "vos factures finalisés" },
    { valid: "in-progress", name: "vos factures en cours" },
    { valid: "canceled", name: "vos factures annulés" },
  ];
  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setSort("datedown");
    setDateRange([]);
    setSelectedInterlocutor(null);
    setListRefresh(true);
  };

  const contentObject = {
    ContentName: "facture",
    Content: [
      { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
      { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
      {
        name: "refAconmpte",
        title: "Référence Acompte",
        type: "text",
        ref: depositRef,
      },
      {
        name: "refFacture",
        title: "Référence Facture",
        type: "text",
        ref: invoiceRef,
      },
      {
        name: "dateRange",
        title: "Date de création",
        type: "dateRangePicker",
        ref: dateRange,
      },
      { name: "society", title: "Société", type: "text", ref: societyRef },
    ],
  };

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    setListRefresh(true);
  };

  const onSearchSelectChange = (e) => {
    setSelectedInterlocutor(e.target.value);
    setListRefresh(true);
  };

  const sortOptions = {
    options: [
      { value: "datedown", content: "Date décroissante" },
      { value: "dateup", content: "Date croissante" },
      { value: "refup", content: "Références croissantes" },
      { value: "refdown", content: "Références décroissantes" },
    ],
  };
  const handleOpenValidItem = (id) => {
    setShowValidPop(true);
    setInvoiceId(id);
  };
  const handleOpenInvoice = () => {
    navigate(`/invoicesList/invoice/${invoiceId}`);
  };
  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    {
      text: "Continuer",
      className: "continueButton",
      action: handleOpenInvoice,
    },
  ];
  return (
    <>
      <MainHeader
        headerTitle={"FACTURES"}
        objectButtons={objectButtons} 
        roleVerif={roleVerif}
      />
      <MailPop
        endpoint={"c-invoice-send-email"}
        id={mailData.id}
        itemName={"Facture"}
        title={mailData.title}
      />
      {showValidPop && (
        <ValidPop
          bgcAction={(e) => setShowValidPop(false)}
          showValidPop={showValidPop}
          title={"Continuer vers cette facture ?"}
          desc={"Cette facture est validé, voulez-vous vraiment la modifier ?"}
          buttonsObject={buttonsObject}
        />
      )}
      {(loading || pdfContext.loading) && <Loader />}
      <div className="listPage">
        <div className="listPage__filterContainer">
          <StateFilter
            state={state}
            setState={setState}
            stateObject={stateObject}
            setListRefresh={setListRefresh}
          />
          <SearchComponent
            onSearchSelectChange={onSearchSelectChange}
            onSearchSubmit={onSearchSubmit}
            contentObject={contentObject}
            refresh={refresh}
            interlocutor={interlocutors}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setListRefresh={setListRefresh}
          />
          <SortByFilter
            setSort={setSort}
            sortOptions={sortOptions}
            setListRefresh={setListRefresh}
          />
        </div>
        {invoicesList &&
          invoicesList.map((invoice, k) => {
            const element = {
              id: invoice.invoiceId,
              valid: invoice.invoiceValid,
              state: invoice.invoiceState,
              date: invoice.invoiceDate,
              ref: invoice.invoiceRef,
              subject: invoice.subject,
              lastName: invoice.societyContactLastName,
              firstName: invoice.societyContactFirstName,
              society: invoice.societyContactSociety,
              totalHt: invoice.invoiceTotalHt,
              totalTtc: invoice.invoiceTotalTtc,
              directionPath: "/invoicesList/invoice/",
              initials: invoice.userInitials,
            };
            return (
              <Card
                key={k}
                roleVerif={roleVerif}
                handleOpenValidItem={handleOpenValidItem}
                tableName={"invoice"}
                setListRefresh={setListRefresh}
                deleteEndPoint={"c-deactive-invoice"}
                stateButtonsList={stateButtonsList}
                otherButtonList={otherButtonList}
                element={element}
                elementTitle={"FACTURE N°"}
              />
            );
          })}
        <TotalAmonts
          totalTTC={totalTTC}
          totalHT={totalHT}
          totalVAT={totalVAT}
        />
        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
          setListRefresh={setListRefresh}
        />
      </div>
    </>
  );
}

export default InvoicesList;
