import "../../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import DeletButton from "../../../addOns/deletButton";
import article_kart from "../../../../assets/images/icons/Picto/Achat.svg";
import refresh_picto from "../../../../assets/images/picto/refresh.svg";
import eraser from "../../../../assets/images/picto/eraser_picto.svg";

function QuoteLine({
  refresh,
  setRefresh,
  listUnit,
  onLineChange,
  listTva,
  line,
  changeOrderLine,
  group,
  removeLine,
  index,
  grpIndex,
  openCatalogues,
}) {
  const [localLine, setLocalLine] = useState({ ...line });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLocalLine({ ...line });
  }, [line]);

  useEffect(() => {
    const quantityDecimal = new Decimal(localLine.quantity || 0);
    const unitPriceDecimal = new Decimal(localLine.unitPrice || 0);
    const totalDecimal = quantityDecimal.times(unitPriceDecimal);
    setTotal(totalDecimal.toNumber());
  }, [localLine.quantity, localLine.unitPrice]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedLine = { ...localLine, [name]: value };
    setLocalLine(updatedLine);
    onLineChange(updatedLine.id, name, value);
  };

  const handleBlur = () => {
    onLineChange(localLine.id, "sync", localLine);
  };

  const validPopContent = {
    title: "Suppression",
    desc: `Êtes-vous sûr de vouloir supprimer cette ligne ?`,
    toasterMessage: "Ligne supprimée avec succès",
  };

  return (
    <div className="invoiceLine" id={localLine.numero}>
      <div className="invoiceLine__leftContent">
        <div className="invoiceLine__leftContent_header">
          <div className="invoiceLine__leftContent_header_leftPart">
            <p className="invoiceLine__leftContent_header_leftPart_number">
              {index + 1}
            </p>
            {line?.itemId ? (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button change-article-button"
                onClick={() => {
                  openCatalogues(index, grpIndex);
                }}
              >
                <img src={refresh_picto} alt="refresh_logo" />
                Changer l'article
              </button>
            ) : (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button add-article-button"
                onClick={() => {
                  openCatalogues(index, grpIndex);
                }}
              >
                <img src={article_kart} alt="article_kart" />
                Ajouter un article
              </button>
            )}
          </div>
          <div className="invoiceLine__leftContent_header_arrowContainer">
            <FontAwesomeIcon
              onClick={() => changeOrderLine(localLine.numero, "up")}
              style={
                localLine.numero <= 1
                  ? { opacity: 0.3, cursor: "not-allowed" }
                  : {}
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronUp}
            />
            <FontAwesomeIcon
              onClick={() => changeOrderLine(localLine.numero, "down")}
              style={
                localLine.numero === group.lines.length.toString()
                  ? { opacity: 0.3, cursor: "not-allowed" }
                  : {}
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronDown}
            />
            <DeletButton
              refresh={refresh}
              setRefresh={setRefresh}
              url={"c-delete-quote-line"}
              id={line.id}
              className={
                "invoiceLine__leftContent_header_arrowContainer_deleteButton"
              }
              validPopContent={validPopContent}
              color={"red"}
              deleteItemByNotId={removeLine}
            />
          </div>
        </div>
        <textarea
          onBlur={handleBlur}
          value={localLine.name || ""}
          className="invoiceLine__leftContent_textarea"
          name="name"
          onChange={handleInputChange}
          placeholder="Description de l'article"
          cols="30"
          rows="10"
        ></textarea>
        <div className="invoiceLine__leftContent_erasedContainer">
          <button
            className="invoiceLine__leftContent_erasedContainer_button"
            onClick={() => {
              onLineChange(line.id, "name", "");
              onLineChange(line.id, "itemId", null);
              onLineChange(line.id, "unitId", 0);
              onLineChange(line.id, "quantity", 1);
              onLineChange(line.id, "unitPrice", 0);
              onLineChange(line.id, "vatId", 0);

              console.log("localLine", localLine);

              setTotal(0);
            }}
          >
            <img src={eraser} alt="eraser" />
            Effacer l'article
          </button>
        </div>
      </div>
      <div className="invoiceLine__rightContent">
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="unit"
          >
            Unité
          </label>
          <select
            name="unitId"
            value={localLine.unitId || 0}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {listUnit.map((unit, i) => (
              <option key={i} value={unit.id}>
                {unit.unit_name}
              </option>
            ))}
          </select>
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="quantity"
          >
            Quantité
          </label>
          <input
            name="quantity"
            type="number"
            placeholder="Quantité"
            value={localLine.quantity || ""}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="invoiceLine__rightContent_inputContainer_input"
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="unitPrice"
          >
            Prix HT
          </label>
          <input
            name="unitPrice"
            type="number"
            placeholder="Prix HT"
            value={localLine.unitPrice || 0}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="invoiceLine__rightContent_inputContainer_input"
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="vatId"
          >
            TVA
          </label>
          <select
            name="vatId"
            value={localLine.vatId || 0}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {listTva.map((tva, i) => (
              <option key={i} value={tva.id}>
                {tva.rate} %
              </option>
            ))}
          </select>
        </div>
        <div className="invoiceLine__rightContent_totalContainer">
          <p className="invoiceLine__rightContent_totalContainer_total">
            Montant HT : &nbsp;<span>{total} €</span>
          </p>
          <p className="invoiceLine__rightContent_totalContainer_total line-discount-total">
            Montant après remise : &nbsp;<span> {total}&nbsp;€</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default QuoteLine;
