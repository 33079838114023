import { createContext } from "react";

export const GetApiContext = createContext(null);

export default function GetApiProvider(props) {
  return (
    <GetApiContext.Provider
      value={{
        getInvoice: API.getInvoice,
        getInvoicesList: API.getInvoicesList,
        getContactsList: API.getContactsList,
        getInterlocutorsList: API.getInterlocutorsList,
        getVatList: API.getVatList,
        getUnitsList: API.getUnitsList,
        transferQuoteToInvoice: API.transferQuoteToInvoice,
        getQuotesList: API.getQuotesList,
        getContact: API.getContact,
        getQuote: API.getQuote,
        getMargin: API.getMargin,
        getDeposit: API.getDeposit,
        getDashboardData: API.getDashboardData,
        contactQuoteListGet: API.contactQuoteListGet,
        contactInvoiceListGet: API.contactInvoiceListGet,
        getSettings: API.getSettings,
        getDepositsList: API.getDepositsList,
        getCatalogsList: API.getCatalogsList,
        getOrderList: API.getOrderList,
        getOrder: API.getOrder,
        getCreditList: API.getCreditList,
        getCredit: API.getCredit,
        getDeliveriesList: API.getDeliveriesList,
        getDelivery: API.getDelivery,
        quoteDepositsPaid: API.quoteDepositsPaid,
        getQuotePDF: API.getQuotePDF,
        getInvoicePDF: API.getInvoicePDF,
        getDeliveryPDF: API.getDeliveryPDF,
        getDepositPDF: API.getDepositPDF,
        getOrderPDF: API.getOrderPDF,
        getCreditPDF: API.getCreditPDF,
        getAdminDashboard: API.getAdminDashboard,
        getAdminContact: API.getAdminContact,
        getAdminCatalog: API.getAdminCatalog,
        getAdminSettings: API.getAdminSettings,
      }}
    >
      {props.children}
    </GetApiContext.Provider>
  );
}
let token = localStorage.getItem("acces_token");

let headers = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    ...(token && { Authorization: "Bearer " + token }),
  },
};
const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

const API = {
  getInvoice: async (id, dup) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-invoice&id=${id}&dup=${dup}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getInvoicesList: async (
    currentPage,
    perPage,
    state,
    sort,
    firstName,
    lastName,
    depositRef,
    invoiceRef,
    society,
    startDate,
    endDate,
    interlocuteur
  ) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-invoices-list&page=${currentPage}&nombre=${perPage}&state=${state}&sort=${sort}&firstName=${firstName}&lastName=${lastName}&depositRef=${depositRef}&invoiceRef=${invoiceRef}&society=${society}&startDate=${startDate}&endDate=${endDate}&interlocuteur=${interlocuteur}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getContactsList: async (contactState, currentPage, perPage, sort, filters = {}) => {
    updateHeaders();
    // Build query string for filters. Default to empty if not provided.
    const {
      lastName = "",
      firstName = "",
      society = "",
      tel = "",
      city = "",
      letter = ""
    } = filters;
    
    const query = `&state=${contactState}&page=${currentPage}&perPage=${perPage}&sort=${sort}&lastName=${encodeURIComponent(lastName)}&firstName=${encodeURIComponent(firstName)}&society=${encodeURIComponent(society)}&tel=${encodeURIComponent(tel)}&city=${encodeURIComponent(city)}&letter=${encodeURIComponent(letter)}`;
    try {
      let response = await fetch(`${capboxApi}c-contacts-list-pop${query}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getInterlocutorsList: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-interlocutor-list`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },

  getVatList: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-vat-list`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getUnitsList: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-unit-list`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  transferQuoteToInvoice: async (quoteId) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-invoice&quote=${quoteId}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getInvoiceByQuote: async (quoteId) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-invoice&quote=${quoteId}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getContact: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-contact-pop&id=${id}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getQuotesList: async (sort, quoteState, currentPage, perPage) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-quotes-list&sort=${sort}&state=${quoteState}&page=${currentPage}&nombre=${perPage}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getQuote: async (quoteId, dup) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-quote&id=${quoteId}&dup=${dup}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getMargin: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-margin&id=${id}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getDeposit: async (id, dup, idQuote, idInvoice) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-deposit&id=${id}&dup=${dup}&idQuote=${idQuote}&idInvoice=${idInvoice}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getDashboardData: async (selectedDate, rate, selectedMonth) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-dashboard&year=${selectedDate}&rate=${rate}&month=${selectedMonth}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  contactQuoteListGet: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-contact&id=${id}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  contactInvoiceListGet: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-contact&id=${id}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getSettings: async (updateToken) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-settings`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getDepositsList: async (valid, filter, currentPage, perPage, filters = "") => {
    updateHeaders();
    const {
      firstName = "",
      lastName = "",
      depositRef = "",
      quoteRef = "",
      invoiceRef = "",
      interlocutor = "",
      startDate = "",
      endDate = "",
    } = filters;
    try {
      let response = await fetch(
        `${capboxApi}c-deposits-list&valid=${valid}&sort=${filter}&currentPage=${currentPage}&perPage=${perPage}&firstName=${firstName}&lastName=${lastName}&depositRef=${depositRef}&quoteRef=${quoteRef}&invoiceRef=${invoiceRef}&interlocutor=${interlocutor}&startDate=${startDate}&endDate=${endDate}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getCatalogsList: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}c-catalogs-list`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getOrderList: async (state, sort, currentPage, perPage) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-order-forms-list&state=${state}&sort=${sort}&currentPage=${currentPage}&perPage=${perPage}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getOrder: async (id, dup) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-order-form&id=${id}&dup=${dup}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getCreditList: async (creditState, sort, currentPage, perPage) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-credits-list&state=${creditState}&sort=${sort}&currentPage=${currentPage}&perPage=${perPage}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getCredit: async (id, dup) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-credit&id=${id}&dup=${dup}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getDeliveriesList: async (sort, currentPage, perPage) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-deliveries-list&sort=${sort}&currentPage=${currentPage}&parPage=${perPage}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getDelivery: async (id, invoiceId, dup) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-delivery&id=${id}&invoice=${invoiceId}&dup=${dup}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  quoteDepositsPaid: async (quoteId) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}c-quote-deposits-paid&id=${quoteId}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getQuotePDF: async (quoteId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-quote-print&id=${quoteId}`,
        headers
      );

      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },
  getInvoicePDF: async (invoiceId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-invoice-print&id=${invoiceId}`,
        headers
      );

      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },
  getDeliveryPDF: async (deliveryId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-delivery-print&id=${deliveryId}`,
        headers
      );
      
      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },

  getDepositPDF: async (depositId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-deposit-print&id=${depositId}`,
        headers
      );

      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },
  getOrderPDF: async (orderId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-order-print&id=${orderId}`,
        headers
      );

      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },
  getCreditPDF: async (creditId) => {
    updateHeaders();

    try {
      const response = await fetch(
        `${capboxApi}c-credit-print&id=${creditId}`,
        headers
      );

      // Vérifier le type de contenu de la réponse
      const contentType = response.headers.get("content-type");
      // console.log("Réponse :", await response.text());

      if (response.ok) {
        if (contentType === "application/pdf") {
          // Retourne le PDF sous forme de Blob
          const pdfBlob = await response.blob();
          return { type: "pdf", data: pdfBlob };
        } else if (contentType === "application/json") {
          // Retourne la réponse JSON en cas d'erreur
          const json = await response.json();
          return { type: "json", data: json };
        } else {
          throw new Error("Type de réponse inattendu : " + contentType);
        }
      } else {
        // Gestion des erreurs HTTP
        const errorJson = await response.json();
        throw new Error(errorJson.error || "Erreur inconnue");
      }
    } catch (e) {
      console.error("Erreur dans getQuotePDF :", e);
      throw e; // Relance l'erreur pour la gérer côté appelant
    }
  },
  getAdminDashboard: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}admin-dashboard`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getAdminContact: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(
        `${capboxApi}admin-customer&id=${id}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getAdminCatalog: async (id) => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}admin-catalog&id=${id}`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
  getAdminSettings: async () => {
    updateHeaders();
    try {
      let response = await fetch(`${capboxApi}admin-settings`, headers);
      return handleApiResponse(response);
    } catch (e) {
      console.log(e);
    }
  },
};

const handleApiResponse = async (response) => {
  try {
    const text = await response.text();
    // console.log("Réponse brute:", text);

    let token, responseData;
    if (text.includes("}{")) {
      // Si la réponse contient plusieurs objets JSON (token & data)
      const [tokenText, responseDataText] = text.split(/(?<=\})(?=\{)/);
      try {
        token = JSON.parse(tokenText);
        responseData = JSON.parse(responseDataText);
      } catch (e) {
        console.error(
          "Erreur lors du parsing JSON:",
          e,
          "-------------------------------------------------------------------------------------------- Reponse brute:",
          text
        );
        return;
      }
      if (token && token.token) {
        localStorage.setItem("acces_token", token.token);
      }
    } else {
      // Si la réponse contient un seul objet JSON (data)
      try {
        responseData = JSON.parse(text);
      } catch (e) {
        console.error(
          "Erreur lors du parsing JSON:",
          e,
          "-------------------------------------------------------------------------------------------- Reponse brute:",
          text
        );
        return;
      }
    }

    //console.log('Response Data:', responseData);
    return responseData;
  } catch (error) {
    console.error("Erreur lors du traitement de la réponse API:", error);
  }
};

function updateHeaders() {
  token = localStorage.getItem("acces_token");
  headers = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: "Bearer " + token }),
    },
  };
}
