import { Link, matchPath } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { useContext } from "react";

export default function NavButton({ path, activePathName, name, link, setShowNav }) {
  const { innerWidth } = useContext(AppContext);
  const handleActive = () => {
    // Vérifie si le chemin correspond exactement ou s'il correspond à un sous-chemin
    const isExactMatch = matchPath({ path: activePathName, end: true }, path);
    const isSubPathMatch = matchPath(
      { path: activePathName, end: false },
      path
    );

    // Évite que `/` soit actif pour d'autres chemins
    if (activePathName === "/" && path !== "/") {
      return "";
    }

    // Retourne actif si correspondance exacte ou sous-chemin
    return isExactMatch || isSubPathMatch ? "activeNavButton" : "";
  };

  return (
    <li className="navigation__container_links_ul_li">
      <Link
        className={`navigation__container_links_ul_li_link ${handleActive()}`}
        to={link}
        onClick={() => {
          if (innerWidth < 1024) setShowNav(false);
        }}
      >
        <span className="navigation__container_links_ul_li_link_span"></span>
        {name}
      </Link>
    </li>
  );
}
