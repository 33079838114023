import { useContext, useEffect, useRef, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { useAuthState } from "../../context/authContext";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import MainButton from "../../components/addOns/buttonComponent/MainButton";
import Accountant from "../../components/settings/accountant";
import AccountantPop from "../../components/settings/accountantPop";
import ValidPop from "../../components/addOns/popComponents/validPop";
import { toast } from "react-toastify";

export default function Contact() {
  let navigate = useNavigate();
  const appContext = useContext(AppContext);
  const getApiCalls = useContext(GetApiContext);
  const postApiCalls = useContext(PostApiContext);

  let { id } = useParams();

  const { roleId } = useAuthState();
  useEffect(() => {
    appContext.setContactId(id);
  }, [appContext, id]);

  // Ref
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const societyRef = useRef();
  const functionRef = useRef();
  const adress1Ref = useRef();
  const adress2Ref = useRef();
  const postalRef = useRef();
  const cityRef = useRef();
  const mobilePhoneRef = useRef();
  const landlinePhoneRef = useRef();
  const faxPhoneRef = useRef();
  const customerEmailRef = useRef();
  const societyEmailRef = useRef();
  const commentRef = useRef();

  // useState
  const [contact, setContact] = useState({});
  const [society, setSociety] = useState({});
  const [creationDate, setCreationDate] = useState("");
  const [selectedCivility, setSelectedCivility] = useState("");
  const [catalogAccess, setCatalogAccess] = useState("partial");
  const [accountant, setAccountant] = useState({});
  const [showAccountantPop, setShowAccountantPop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [validPopTitle, setValidPopTitle] = useState("");
  const [validPopDesc, setValidPopDesc] = useState("");
  const [showValidPop, setShowValidPop] = useState(false);
  const [validPopAction, setValidPopAction] = useState(null);

  const options = useMemo(
    () => [
      { value: "1", label: "M." },
      { value: "2", label: "Mme" },
      { value: "3", label: "Mlle" },
      { value: "4", label: "M. et Mme" },
    ],
    []
  );

  useEffect(() => {
    getApiCalls.getAdminContact(id).then((response) => {
      setContact(response.user);
      setSociety(response.user?.society);
      setAccountant(response.accountant);
      setCatalogAccess(response.user?.society?.catalogAccess || "partial");

      const date = new Date(response.user?.creationDate || Date.now());
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      setCreationDate(formattedDate);
    });
  }, [getApiCalls, id]);

  const handleFormSubmission = () => {
    //Display navigator error if form is not valid
    const form = document.getElementById("form");

    if (form.checkValidity()) {
      form.requestSubmit(); // Soumet le formulaire si valide
    } else {
      form.reportValidity(); // Affiche les erreurs de validation
    }
  };

  const onValidContact = async (e) => {
    e?.preventDefault();
    const values = {
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      civility: selectedCivility,
      function: functionRef.current?.value,
      customerEmail: customerEmailRef.current?.value,
      creationDate: creationDate,

      societyId: society.id,
      address1: adress1Ref.current?.value,
      address2: adress2Ref.current?.value,
      postal: postalRef.current?.value,
      city: cityRef.current?.value,
      societyName: societyRef.current?.value,
      mobilePhone: mobilePhoneRef.current?.value,
      landlinePhone: landlinePhoneRef.current?.value,
      faxPhone: faxPhoneRef.current?.value,
      societyEmail: societyEmailRef.current?.value,
      catalogAccess: catalogAccess,

      comment: commentRef.current?.value,
    };

    postApiCalls
      .postAdminContact(id, values)
      .then((response) => {
        console.log(response);

        if (values.type) {
        if (response.status === "success") {
          id ? toast.success("Le contact a bien été enregistré") : toast.success("Le contact a bien été créé");
          navigate(appContext.redirection || "/admin-customers-list");
          appContext.setRedirection(null);
          appContext.setShowContactPopup(false);
        } else {
          toast.error("Une erreur est survenue lors de l'enregistrement du contact");
        }}
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const returnsIdentifiers = async (e) => {
  e?.preventDefault();

  const values = {
    mail: customerEmailRef.current?.value,
  };

  const toastId = toast.loading("Envoi des identifiants en cours...");

  try {
    const response = await postApiCalls.adminReturnsIdentifiers(id, values);

    if (response.status === "error") {
      toast.update(toastId, {
        render: "Échec de l'envoi des identifiants. Veuillez vérifier l'email.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } else {
      toast.update(toastId, {
        render: "Identifiants envoyés avec succès",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    }
    setShowValidPop(false);
  } catch (error) {
    toast.update(toastId, {
      render: "Erreur lors de l'envoi des identifiants",
      type: "error",
      isLoading: false,
      autoClose: 5000,
    });
  }
};

  useEffect(() => {
    const civilityId = contact?.civility_id;
    const option = options.find((option) => option.value === civilityId);
    setSelectedCivility(option ? option.value : "civilité");
  }, [contact?.civility_id, contact?.type, options]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedCivility(selectedValue);
  };

  const headerButtonsList = {
    mainButton: {
      className: "hide-text-650",
      buttonIcon: faArrowLeft,
      buttonText: id ? "Sauvegarder" : "Créer le contact",
      buttonAction: (e) => {
        handleFormSubmission();
      },
    },
  };

  const handleShowAccountantPop = () => {
    setShowAccountantPop(!showAccountantPop);
    appContext.setBlur(!appContext.blur);
  };

  const handleContactClosure = () => {
    const values = {
      active: society?.active === "1" ? "0" : "1",
    };

    console.log("values", values);

    postApiCalls
      .postContactClosure(id, values)
      .then((response) => {
        setContact(response.user);
        setSociety(response.user?.society);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validPopContent = {
    title: validPopTitle,
    desc: validPopDesc,
  };

  const validPopButtons = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    {
      text: "Continuer",
      className: "continueButton",
      action: validPopAction,
    },
  ];

  return (
    <>
      {showValidPop && (
        <ValidPop
          title={validPopContent?.title || ""}
          desc={validPopContent?.desc || ""}
          showValidPop={showValidPop}
          setShowValidPop={setShowValidPop}
          buttonsObject={validPopButtons}
          bgcAction={(e) => setShowValidPop(false)}
        />
      )}
      {showAccountantPop && (
        <AccountantPop
          accountant={accountant}
          handleShowAccountantPop={handleShowAccountantPop}
          setRefresh={setRefresh}
          refresh={refresh}
          societyId={society.id}
        />
      )}
      <EditingMainHeader
        defaultRef={contact?.ref}
        headerTitle="CONTACT"
        headerButtonsList={headerButtonsList}
        id={id}
        roleId={roleId}
        backRedirect={"/admin-customers-list"}
      />
      <div className={`${appContext.blur ? "blur" : ""} listPage`}>
        <form id="form" onSubmit={(e) => onValidContact(e)}>
          <div className="allContactContent" style={{ display: "block" }}>
            {/* CONTACT */}
            <div className="contactContent">
              <div>
                <h3 className="title">Informations représentant</h3>
              </div>
              <div className="inputs">
                <div className="clientInfos">
                  <div className="form-group">
                    <input
                      className="input "
                      defaultValue={contact?.last_name}
                      name="lastName"
                      required
                      ref={lastNameRef}
                      type="text"
                      placeholder="Nom*"
                    />
                    <input
                      className="input "
                      defaultValue={contact?.first_name}
                      name="firstName"
                      required
                      ref={firstNameRef}
                      type="text"
                      placeholder="Prenom*"
                    />
                    <select
                      className="select"
                      name="Civilite"
                      id="Civilite"
                      value={selectedCivility}
                      onChange={handleChange}
                    >
                      <option className="option" value="">
                        -- Sélectionnez --
                      </option>
                      {options.map((option) => (
                        <option
                          className="option"
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <input
                      className="input"
                      type="text"
                      name="function"
                      defaultValue={contact?.function}
                      ref={functionRef}
                      placeholder="Fonction"
                    />
                    <input
                      className="input"
                      type="email"
                      name="email"
                      required
                      defaultValue={contact?.email}
                      ref={customerEmailRef}
                      placeholder="Email*"
                    />

                    <p className="Infos">
                      *Informations obligatoires{" "}
                      <span className="underline"></span>
                    </p>
                  </div>
                </div>

                {/* Société */}
                <div>
                  <h3 className="title">Informations société</h3>
                </div>
                <div className="clientInfos">
                  <div className="form-group">
                    <input
                      className="input "
                      type="text"
                      name="adress1"
                      defaultValue={society?.adress1}
                      ref={adress1Ref}
                      placeholder="Adresse"
                    />
                    <input
                      className="input "
                      type="text"
                      name="adress2"
                      defaultValue={society?.adress2}
                      ref={adress2Ref}
                      placeholder="Adresse 2"
                    />
                    <input
                      className="input "
                      type="text"
                      name="postal"
                      defaultValue={society?.postalCode}
                      ref={postalRef}
                      placeholder="Code postal"
                    />
                    <input
                      className="input "
                      type="text"
                      name="city"
                      defaultValue={society?.city}
                      ref={cityRef}
                      placeholder="Ville"
                    />
                    <input
                      className="input"
                      type="text"
                      name="society"
                      defaultValue={society?.societyName}
                      ref={societyRef}
                      placeholder="Nom de la société"
                    />
                    <input
                      className="input"
                      type="text"
                      name="mobilePhone"
                      defaultValue={society?.mobilePhone}
                      ref={mobilePhoneRef}
                      placeholder="Téléphone portable"
                    />
                    <input
                      className="input"
                      type="text"
                      name="landlinePhone"
                      defaultValue={society?.landlinePhone}
                      ref={landlinePhoneRef}
                      placeholder="Téléphone fixe"
                    />
                    <input
                      className="input"
                      type="text"
                      name="faxPhone"
                      defaultValue={society?.faxPhone}
                      ref={faxPhoneRef}
                      placeholder="Fax"
                    />
                    <input
                      className="input"
                      type="email"
                      name="email"
                      defaultValue={society?.email}
                      ref={societyEmailRef}
                      placeholder="Email"
                    />

                    <p className="Infos">
                      *Informations obligatoires{" "}
                      <span className="underline"></span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="title">INFORMATIONS DU COMPTE</h3>
                  <section className="admin-customer__contact_account">
                    <div className="admin-customer__contact_account_left">
                      <p className="boldFont">Accès aux fonctionnalités</p>
                      <div className="admin-customer__contact_account_left_inputs">
                        <label>
                          <input
                            type="checkbox"
                            name="access"
                            value="complet"
                            checked={catalogAccess === "full"}
                            onChange={() => setCatalogAccess("full")}
                          />
                          <span className="boldFont">Complet</span>
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            name="access"
                            value="partiel"
                            checked={catalogAccess === "partial"}
                            onChange={() => setCatalogAccess("partial")}
                          />
                          <span className="boldFont">Partiel</span>
                          (hors catalogue CAP&nbsp;ACHAT)
                        </label>
                      </div>
                      <p className="boldFont">
                        Envoyer l'e-mail des identifiants au client
                      </p>
                      <div className="admin-customer__contact_account_left_inputs_email-sending">
                        <div>
                          <p>Génère un e-mail automatique contenant :</p>
                          <p>- Le nom du compte et sa date d'ouverture</p>
                          <p>- Le login et le mot de passe aléatoire</p>
                        </div>
                        <MainButton
                          buttonText="Renvoyer un mail"
                          buttonAction={() => {
                            setValidPopTitle("Renvoyer les identifiants");
                            setValidPopDesc(
                              "Voulez-vous vraiment renvoyer les identifiants ? Cette action enverra un message à l'adresse e-mail du client."
                            );
                            setValidPopAction(() => returnsIdentifiers);
                            setShowValidPop(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="admin-customer__contact_account_right">
                      <p className="boldFont">Date ouverture du compte</p>
                      <input
                        value={creationDate}
                        onChange={(e) => setCreationDate(e.target.value)}
                      ></input>
                      <p className="boldFont">Date fermeture du compte</p>
                      <input disabled></input>
                    </div>
                  </section>
                </div>
                <div className="comment">
                  <h4 className="title">
                    Commentaires sur le suivi de ce compte client
                  </h4>
                  <textarea
                    defaultValue={society?.comment}
                    ref={commentRef}
                    placeholder="Commentaire"
                  ></textarea>
                </div>
              </div>
              <Accountant
                handleShowAccountantPop={handleShowAccountantPop}
                accountant={accountant}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </div>
          </div>
        </form>
        <div className="admin-customer__contact_contact-closure">
          {id !== undefined && (
            <MainButton
              buttonText={
                society?.active === "1"
                  ? "Fermer le compte"
                  : "Réouvrir le compte"
              }
              buttonAction={() => {
                setValidPopTitle(
                  society?.active === "1"
                    ? "Fermeture du compte"
                    : "Réouverture du compte"
                );
                setValidPopDesc(
                  society?.active === "1"
                    ? "Voulez-vous vraiment fermer le compte ?"
                    : "Voulez-vous vraiment réouvrir le compte ?"
                );
                setValidPopAction(() => handleContactClosure);
                setShowValidPop(true);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
