import React, { useEffect, useState, useContext, useCallback } from "react";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { TableContainer } from "@mui/material";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import BasicInput from "../../components/addOns/inputComponents/basicInput";
import MainButton from "../../components/addOns/buttonComponent/MainButton";

export default function AdminTVA() {
  const [listRefresh, setListRefresh] = useState(false);
  const [tvaList, setTvaList] = useState([]);

  const postApiCalls = useContext(PostApiContext);

  const onValidFilter = useCallback(
    async (id, active) => {
      const values = {
        id: id,
        active: active + "", // Convertit en string pour que l'API ne récupère pas null
      };

      try {
        const response = await postApiCalls.adminTVAList(values);
        if (response.vatList) {
          const vatData = Object.keys(response.vatList).map((key) => ({
            ...response.vatList[key],
            label: key, // Ajoute la clé comme libellé
          }));
          setTvaList(vatData);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    [postApiCalls]
  );

  useEffect(() => {
    onValidFilter();
  }, [onValidFilter]);

  useEffect(() => {
    if (listRefresh) {
      onValidFilter();
      setListRefresh(false);
    }
  }, [onValidFilter, listRefresh]);

  const handleIconClick = async (id, active) => {
    await onValidFilter(id, active);
  };

  const addNewTva = async (e) => {
    e.preventDefault();
    const newTva = e.target.newTva.value;
    const values = {
      rate: newTva,
    };

    try {
      const response = await postApiCalls.adminNewTva(values);
      if (response.vatList) {
        const vatData = Object.keys(response.vatList).map((key) => ({
          ...response.vatList[key],
          label: key, // Ajoute la clé comme libellé
        }));
        setTvaList(vatData);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <EditingMainHeader headerTitle="Liste des TVA" headerButtonsList={{}} />
      <div className="listPage admin-tva">
        <form
          id="searchFilterForm"
          onSubmit={addNewTva}
          className="admin-tva__new-tva-form"
        >
          <label htmlFor="newTva">Créer une nouvelle Tva :</label>
          <div className="admin-tva__new-tva-form_input">
          <BasicInput
            id="newTva"
            name="newTva"
            type="text"
            placeholder={"Taux en %"}
          />
          <MainButton buttonIcon={"sauvegarder"} buttonText={"Valider"} className="hide-text-650" />
          </div>
        </form>
        <TableContainer className="admin-connections">
          <table className="admin-connections__table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Taux</th>
                <th>Libellé</th>
                <th>Actif</th>
              </tr>
            </thead>
            <tbody className="admin-connections__table_body">
              {tvaList.map((tva, index) => (
                <tr key={index} className="admin-connections__table_body_row">
                  <td>{tva.id}</td>
                  <td>{tva.label}</td>
                  <td>{tva.rate} %</td>
                  <td>
                    {tva.active === "1" ? (
                      <span
                        style={{
                          color: "green",
                          fontSize: "120%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleIconClick(tva.id, 0)}
                      >
                        ✔
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          fontSize: "120%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleIconClick(tva.id, 1)}
                      >
                        ✖
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}
