import React, { useRef, useState, useContext } from "react";
import Footer from "../../components/Authentification/footer";
import BasicInput from "../../components/addOns/inputComponents/basicInput";
import MainButton from "../../components/addOns/buttonComponent/MainButton";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Authentification/header";
import leftArrow from "../../assets/images/picto/left-arrow.svg";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { toast } from "react-toastify";

export default function ForgotPassword() {
    const [error, setError] = useState("");
    const identifier = useRef();
    const navigate = useNavigate();
    const postApiCalls = useContext(PostApiContext);

    const handleSubmit = async (e) => {
      e?.preventDefault();

      if (!identifier.current.value.trim()) {
            setError("L'identifiant ou le mail doit être rempli");
            return;
        }
        setError("");
    
      const values = {
        identifier: identifier.current.value,

      };
    
      const toastId = toast.loading("Envoi des identifiants en cours...");
    
      try {
        const response = await postApiCalls.forgotPassword(values);

        if (response.status === "success") {
          toast.update(toastId, {
            render: "Identifiants envoyés avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          toast.update(toastId, {
            render: "Échec de l'envoi des identifiants. Veuillez vérifier l'email.",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
          if (response.error) {
          setError(response.error);
        }
        }
      } catch (error) {
        // console.error("Erreur lors de l'envoi des identifiants:", error);
        toast.update(toastId, {
          render: "Erreur lors de l'envoi des identifiants",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
    };

    return (
        <>
            <Header navigate={navigate} />
            <div className="loginFormPosition">
                <section className="loginForm">
                    <h1 className="loginForm__title">
                        <span className="loginForm__title_span">Mot de passe oublié</span>
                        <hr className="loginForm__line" />
                    </h1>
                    <div className="loginForm__content">
                        <div className="loginForm__content_textContainer">
                            <h2 className="loginForm__content_textContainer_title">
                                Mot de passe oublié ?
                            </h2>
                            <p className="loginForm__content_textContainer_text">
                                Entrez votre email et recevez un lien pour réinitialiser votre mot de passe
                            </p>
                            <button
                                className="loginForm__content_textContainer_button"
                                onClick={() => navigate("/")}
                            >
                                <img src={leftArrow} alt="←" />
                                Revenir
                            </button>
                        </div>
                        <form className="loginForm__content_form" onSubmit={handleSubmit}>
                            <BasicInput
                                autoComplete={"email"}
                                labelName={"Identifiant / Email"}
                                id={"loginId"}
                                className={"loginForm__content_form_inputContainer first-label"}
                                refs={identifier}
                                placeholder={""}
                                required={true}
                            />
                            {error && <div className="error-message">{error}</div>}
                            <MainButton
                                className="loginForm__content_form_button"
                                buttonText={"Envoyer un lien"}
                                buttonAction={handleSubmit}
                            />
                        </form>
                    </div>
                    <Footer navigate={navigate} />
                </section>
            </div>
            <p className="copyright">2025 - Capbox • Tous droits réservés</p>
        </>
    );
}