import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationGroup from "../../components/settings/InformationGroup";
import { useContext, useEffect, useState } from "react";
import Accountant from "../../components/settings/accountant";
import AccountantPop from "../../components/settings/accountantPop";
import Interlocutor from "../../components/settings/interlocutor";
import InterlocutorPop from "../../components/settings/InterlocutorPop";
import {
  faCircleInfo,
  faDownload,
  faFloppyDisk,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
export default function Settings() {
  // State
  const [society, setSociety] = useState({});
  const [interlocutors, setInterlocutors] = useState([]);
  const [accountant, setaccountant] = useState();
  const [interlocutorDataFilter, setInterlocutorDataFilter] = useState({});
  const [showInterlocutorPop, setShowInterlocutorPop] = useState(false);
  const [idInterlocutor, setIdInterlocutor] = useState(null);
  const [showAccountantPop, setShowAccountantPop] = useState(false);
  const [blur, setBlur] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getApiContext = useContext(GetApiContext);

  useEffect(() => {
    getApiContext.getSettings().then((response) => {
      setSociety(response.society);
      setInterlocutors(response.interlocutors);
      setaccountant(response.accountant);
    });
  }, [getApiContext, refresh]);

  const handleShowAccountantPop = () => {
    let body = document.body;
    setShowAccountantPop(!showAccountantPop);
    if (showAccountantPop) {
      setBlur(false);
      body.style.overflow = "visible";
    } else {
      setBlur(true);
      body.style.overflow = "hidden";
    }
  };
  const handleShowInterlocutorPop = (e) => {
    let body = document.body;
    setShowInterlocutorPop(!showInterlocutorPop);
    if (showInterlocutorPop) {
      setBlur(false);
      body.style.overflow = "visible";
      setInterlocutorDataFilter([]);
    } else {
      setBlur(true);
      body.style.overflow = "hidden";
    }
  };
  const updateInterlocutor = (id) => {
    handleShowInterlocutorPop(id);
    setIdInterlocutor(id);
    const filteredInterlocutors = interlocutors.filter(
      (interlocutor) => interlocutor.id === id
    );
    setInterlocutorDataFilter(filteredInterlocutors);
  };

  const handleGuideButtonClick = () => {
    var form = document.getElementById("settingsForm");
    form.submit();
    window.open(
      "https://capdevis.fr/docs/notice_utilisation_cap_box.pdf",
      "_blank"
    );
  };

  const handleLicenceButtonClick = () => {
    var form = document.getElementById("settingsForm");
    form.submit();
    window.open(
      "https://capdevis.fr/docs/licence_d_utilisation_cap_box.pdf",
      "_blank"
    );
  };
  return (
    <>
      {/* <TabBar /> */}
      {showAccountantPop && (
        <AccountantPop
          accountant={accountant}
          handleShowAccountantPop={handleShowAccountantPop}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {showInterlocutorPop && (
        <InterlocutorPop
          handleShowInterlocutorPop={handleShowInterlocutorPop}
          idInterlocutor={idInterlocutor}
          interlocutorDataFilter={interlocutorDataFilter}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      <div className={`${blur && "blur"} settingsContainer`}>
        <div className="settingsHeader">
          <div className="setHeaderLeftGroup">
            <FontAwesomeIcon icon={faGear} />
            <p>Paramètres</p>
          </div>
          <div className="setHeaderRightGroup">
            <div
              className="setHeaderContent settingsLicence"
              onClick={handleLicenceButtonClick}
            >
              <FontAwesomeIcon icon={faDownload} />
              <p>Télécharger/Visualiser la licence d'utilisation du logiciel</p>
            </div>
            <div
              className="setHeaderContent settingsUserGuideContent"
              onClick={handleGuideButtonClick}
            >
              <p>GUIDE UTILISATEUR</p>
              <FontAwesomeIcon icon={faCircleInfo} />
            </div>

            <button form="settingsForm" className="settingsSave" type="submit">
              <FontAwesomeIcon className="icon" icon={faFloppyDisk} />
              Sauvegarder
            </button>
          </div>
        </div>
        <div className="settignsWarning">
          <p>
            <span>Important</span> : Ceci est l'espace de paramètrage de votre
            compte.
          </p>
          <p>
            Il est important de bien remplir ce formulaire afin d'avoir des
            documents établis en bonne et due forme.
          </p>
        </div>
        <InformationGroup
          society={society}
          interlocutors={interlocutors}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <Interlocutor
          interlocutors={interlocutors}
          handleShowInterlocutorPop={handleShowInterlocutorPop}
          updateInterlocutor={updateInterlocutor}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <Accountant
          handleShowAccountantPop={handleShowAccountantPop}
          accountant={accountant}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </div>
    </>
  );
}
