import MainButton from "../buttonComponent/MainButton";
import { useEventScroll } from "../../../hooks/useEventClickListener";
import DropDownButton from "../buttonComponent/DropDownButton";

export default function MainHeader({
  headerTitle,
  objectButtons,
  headerDropdownButton,
  dropDownTitle,
}) {
  const { hasScrolled } = useEventScroll();

  return (
    <>
      <header className="mainHeader">
        <div className="mainHeader__headerContainer">
          <h1 className="mainHeader__headerContainer_title">
            VOS <span className=""> {headerTitle}</span>
          </h1>
          {objectButtons && (
            <div className="mainHeader__headerContainer_buttonContainer">
              {headerDropdownButton && (
                <DropDownButton
                  items={headerDropdownButton}
                  dropDownTitle={dropDownTitle || ""}
                />
              )}
              {objectButtons
                .filter(
                  (button) =>
                    button.showByRoleId === false ||
                    (button.showByRoleId === true && button.verifRole !== true)
                )
                .map((button, index) => {
                  return (
                    <MainButton
                      key={index}
                      buttonIcon={button.icon}
                      buttonText={button.title}
                      buttonAction={button.action}
                      className={`mainHeader__headerContainer_buttonContainer_${button.className}`}
                    />
                  );
                })}
            </div>
          )}
        </div>
        <hr
          className="mainHeader__line"
          style={!hasScrolled ? { boxShadow: "none" } : {}}
        />
      </header>
    </>
  );
}
