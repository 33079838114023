import React from "react";
import { Pagination } from "@mui/material";

const Paging = ({ perPage, setPerPage, setCurrentPage, currentPage, nbPages, setListRefresh, perPageOptions }) => {

  const changePerPage = (e) => {
    if (setPerPage) {
      setCurrentPage(1);
      setPerPage(e.target.value);
    }
  };

  return (

    <div className="pagination">
      <label>Éléments par page </label>
      <select name='perPage' value={perPage} onChange={(e) => { changePerPage(e); if (setListRefresh) setListRefresh(true) }}>
        {perPageOptions ? perPageOptions.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        )) :
          <>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="300">300</option>
            <option value="500">500</option>
          </>
        }
      </select>
      {/* Double left arrow button to go to the first page */}
      <div className="pagination">
        <Pagination
          count={nbPages}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          onChange={(event, page) => {
            setCurrentPage(page)
            if (setListRefresh) setListRefresh(true);
          }}
          className="select-page"
          page={currentPage}
        />
      </div>
    </div>
  );
};

export default Paging;