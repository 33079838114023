import { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { DeleteApiContext } from "../../context/apiCalls/deleteApiCalls";
import ValidPop from "./popComponents/validPop";
export default function AddFile({
  fileType,
  setFile,
  extentionFiles,
  receivedFile,
  setRefresh,
  refresh,
  dropZoneWidth,
  handleDeleteFile,
  dropzoneTitle,
  deleteEndpoint,
  adminPDFType,
  navigate,
}) {
  // State
  const [fichier, setFichier] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showValidPop, setShowValidPop] = useState(false);

  const deleteApiCall = useContext(DeleteApiContext);

  // New function to reset all file data
  function clearData() {
    setFichier(null);
    setSelectedImage(null);
    setFile(null);
    receivedFile = null;
  }

  const handleDeleteButtonClick = () => {
    setShowValidPop(false);
    clearData();
    var path = "";

    if (!extentionFiles.includes("csv")) {
      //TODO : vérifier que ça marche
      deleteEndpoint
        ? (path = deleteEndpoint)
        : extentionFiles.includes("pdf")
        ? (path = "c-delete-cgv")
        : (path = "c-delete-logo");
      deleteApiCall.deleteItem(path, null, adminPDFType).then((response) => {
        if (setRefresh) {
          setRefresh(!refresh);
        }
      });
    }
  };

  // Update useEffect to clear data when refresh changes (pop closed)
  useEffect(() => {
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  function showFileSelected(e) {
    if (e) {
      const extension = e.name.split(".").pop().toLowerCase();
      let error = document.getElementsByClassName("image-error");
      if (extentionFiles.includes(extension)) {
        if (extension === "pdf") {
          let maxSize = 5 * 1024 * 1024;
          if (e.size > maxSize) {
            error[0].innerHTML = "Le fichier est trop lourd";
            return;
          }
          const imageUrl = URL.createObjectURL(e);
          setSelectedImage(imageUrl);

          setFichier(e);
          setFile(e);
        } else if (extension === "csv") {
          setFichier(e);
          setFile(e);
          setSelectedImage(null);
        } else {
          const imageUrl = URL.createObjectURL(e);
          const img = new Image();
          img.src = imageUrl;

          img.onload = function () {
            const width = img.width;
            const height = img.height;
            if (width > 280 || height > 60) {
              error[0].innerHTML = "L'image est trop grande";
            } else {
              setSelectedImage(imageUrl);
              setFichier(e);
              setFile(e);
            }
          };
        }
      } else {
        error[0].innerHTML = "Format de fichier non pris en charge";
      }
    }
  }

  const handleDepotFichier = (event) => {
    event.preventDefault();
    const fichierDepose = event.dataTransfer.files[0];

    showFileSelected(fichierDepose);
  };

  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    {
      text: "Continuer",
      className: "continueButton",
      action: () => {
        handleDeleteFile ? handleDeleteFile() : handleDeleteButtonClick();
        setShowValidPop(false);
        if (navigate) navigate();
      },
      buttonType: "button",
    },
  ];

  return (
    <>
      {!receivedFile && !selectedImage && !fichier ? (
        <div
          className="file-input-container dropzone"
          title={
            extentionFiles.includes("pdf")
              ? "Format accepté : PDF"
              : "Formats acceptés : PNG, JPG, JPEG"
          }
          style={dropZoneWidth && { width: dropZoneWidth }}
        >
          <input
            id={`fileInput-${fileType}`}
            type="file"
            accept={extentionFiles.map((ext) => `.${ext}`).join(",")}
            onDrop={handleDepotFichier}
            onChange={(e) => {
              showFileSelected(e.target.files[0]);
            }}
            style={{ display: "none" }}
          />
          <label htmlFor={`fileInput-${fileType}`} className="fileLabel">
            <div>
              <p>
                <FontAwesomeIcon className="fileIcon" icon={faFileArrowDown} />{" "}
                Glissez ou sélectionnez
              </p>
              <p className={"fileLabelSpan"}>
                {dropzoneTitle
                  ? dropzoneTitle
                  : extentionFiles.includes("pdf")
                  ? "vos cgv"
                  : extentionFiles.includes("png")
                  ? " votre logo"
                  : "votre csv"}
              </p>
            </div>
            <div>
              <p className={"image-error"}></p>
              <p className={"fileLabelSpan"}>
                {extentionFiles.includes("png")
                  ? "(Taille max: 280x60 pixels)"
                  : extentionFiles.includes("pdf")
                  ? "(Taille max: 5Mo)"
                  : ""}
              </p>
            </div>
          </label>
        </div>
      ) : (
        <>
          {showValidPop && (
            <ValidPop
              title={"Suppression"}
              desc={"Êtes-vous sûr de vouloir supprimer le fichier ?"}
              showValidPop={showValidPop}
              buttonsObject={buttonsObject}
            />
          )}
          <div
            className="fileZone dropzone"
            style={dropZoneWidth && { width: dropZoneWidth }}
          >
            {extentionFiles.includes("png") ||
            extentionFiles.includes("jpg") ? (
              <img
                className="showSelectedImage"
                // ici lors du lancement il faudra remplacer ca par l'emplacement du dossier sur le serveur
                src={
                  selectedImage
                    ? selectedImage
                    : `http://localhost/CAPBOXV2-SERVER/uploads/imagesFiles/${receivedFile}`
                }
                alt="logo de l'entreprise"
              />
            ) : (
              <>
                <div className="showFileName">
                  <FontAwesomeIcon className="fileNameIcon" icon={faFilePdf} />
                  <p className="fileName">
                    {fichier?.name
                      ? fichier.name
                      : receivedFile?.name
                      ? receivedFile.name
                      : receivedFile}
                  </p>
                </div>
              </>
            )}
            <div>
              <p
                onClick={(e) => setShowValidPop(true)}
                className="fileEditButton"
              >
                Supprimer
              </p>
              <p className={"image-error"}></p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
