import NewUser from "../routes/sup-admin/NewAdmin";
import PrivateRouter from "../privateRouter";

const roles = ["1"];

export const supPaths = [
    {
        path: "sup-new",
        element:  <PrivateRouter roleList={roles}><NewUser /></PrivateRouter>,
    },
]