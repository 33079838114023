import { createContext } from "react";

export const DeleteApiContext = createContext(null);

export default function DeleteApiProvider(props) {
  return (
    <DeleteApiContext.Provider
      value={{
        deleteItem: API.deleteItem,
      }}
    >
      {props.children}
    </DeleteApiContext.Provider>
  );
}

const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

let token = localStorage.getItem("acces_token");

let headers = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    ...(token && { Authorization: "Bearer " + token }),
  },
};

const API = {
  deleteItem: async (endpoint, id, type) => {
    updateHeaders();
    try {
      const response = await fetch(
        `${capboxApi}${endpoint}&id=${id}&type=${type}`,
        headers
      );
      return handleApiResponse(response);
    } catch (e) {
      // console.log(e);
    }
  },
};

const handleApiResponse = async (response) => {
  try {
    const text = await response.text();
    // console.log("Réponse brute:", text);

    let token, responseData;
    if (text.includes("}{")) {
      // Si la réponse contient plusieurs objets JSON (token & data)
      const [tokenText, responseDataText] = text.split(/(?<=\})(?=\{)/);
      try {
        token = JSON.parse(tokenText);
        responseData = JSON.parse(responseDataText);
      } catch (e) {
        console.error("Erreur lors du parsing JSON:", e);
        return;
      }
      if (token && token.token) {
        localStorage.setItem("acces_token", token.token);
      }
    } else {
      // Si la réponse contient un seul objet JSON (data)
      try {
        responseData = JSON.parse(text);
      } catch (e) {
        console.error("Erreur lors du parsing JSON:", e);
        console.log("Réponse brute:", text);
        return;
      }
    }

    // console.log("Response Data:", responseData);
    return responseData;
  } catch (error) {
    console.error("Erreur lors du traitement de la réponse API:", error);
  }
};

function updateHeaders() {
  token = localStorage.getItem("acces_token");
  headers = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: "Bearer " + token }),
    },
  };
}