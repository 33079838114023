import React, { useEffect } from "react";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import AddFile from "../../components/addOns/addFile";
import { useState, useContext } from "react";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { useAuthState } from "../../context/authContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export default function AdminSettings() {
  const [guideFile, setGuideFile] = useState(null);
  const [licenceFile, setLicenceFile] = useState(null);
  const [defaultGuideFile, setDefaultGuideFile] = useState(null);
  const [defaultLicenceFile, setDefaultLicenceFile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [guideError, setGuideError] = useState(false);
  const [licenceError, setLicenceError] = useState(false);

  const postApiCalls = useContext(PostApiContext);
  const getApiCalls = useContext(GetApiContext);
  const { roleId } = useAuthState();

  const baseURL =
    window.location.hostname === "localhost"
      ? "http://localhost/CAPBOXV2-SERVER/uploads/pdf/"
      : "https://capdevis.fr/docs/";

  useEffect(() => {
    getApiCalls.getAdminSettings().then((response) => {
      setDefaultGuideFile(response.guide);
      setDefaultLicenceFile(response.licence);
    });
  }, [getApiCalls, refresh]);

  const handlePost = () => {
    let values = {
      uploadGuide: guideFile,
      uploadLicence: licenceFile,
    };

    postApiCalls.postAdminSettings(values).then((response) => {
      setDefaultGuideFile(response.guide);
      setDefaultLicenceFile(response.licence);
    });
  };

  const headerButtonsList = {
    mainButton: {
      buttonIcon: "sauvegarder",
      buttonText: "Sauvegarder",
      buttonAction: () => handlePost(),
    },
  };

  return (
    <>
      <EditingMainHeader
        headerTitle="Paramètres"
        headerButtonsList={headerButtonsList}
        roleId={roleId}
      />
      <div className="admin-settings">
        <div className="admin-settings__container">
          <h3 className="admin-settings__container_title">
            Guide utilisateur (format pdf)
          </h3>
          <AddFile
            extentionFiles={["pdf"]}
            dropzoneTitle="le guide utilisateur"
            setFile={setGuideFile}
            receivedFile={defaultGuideFile?.name}
            refresh={refresh}
            setRefresh={setRefresh}
            deleteEndpoint={"a-delete-pdf"}
            adminPDFType="guide"
            fileType={"guide"}
          />
          <div className={"admin-settings__container_pdf-viewer"}>
            {guideError || defaultGuideFile === null ? (
              <div
                style={{ color: "red", textAlign: "center", marginTop: "20px" }}
              >
                📄 Le fichier PDF est introuvable. Veuillez vérifier le
                document.
              </div>
            ) : (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={`${baseURL}${
                    defaultGuideFile?.name
                  }?refresh=${Date.now()}`}
                  defaultScale={0.4}
                  onDocumentLoadFailure={() => setGuideError(true)}
                  onDocumentLoadError={(error) => {
                    console.error("Erreur de chargement du PDF :", error);
                  }}
                />
              </Worker>
            )}
          </div>
        </div>
        <div className="admin-settings__container">
          <h3 className="admin-settings__container_title">
            Licence utilisateur (format pdf)
          </h3>
          <AddFile
            extentionFiles={["pdf"]}
            dropzoneTitle="la licence utilisateur"
            setFile={setLicenceFile}
            receivedFile={defaultLicenceFile?.name}
            refresh={refresh}
            setRefresh={setRefresh}
            deleteEndpoint={"a-delete-pdf"}
            adminPDFType="licence"
            fileType={"licence"}
          />
          <div className={"admin-settings__container_pdf-viewer"}>
            {licenceError || defaultLicenceFile === null ? (
              <div
                style={{ color: "red", textAlign: "center", marginTop: "20px" }}
              >
                📄 Le fichier PDF est introuvable. Veuillez vérifier le
                document.
              </div>
            ) : (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={`${baseURL}${
                    defaultLicenceFile?.name
                  }?refresh=${Date.now()}`} // Forcer React à re-render le Viewer, sans utiliser de paramètre key, qui peut générer une erreur si actualisé trop rapidement
                  defaultScale={0.4}
                  onDocumentLoadFailure={() => setLicenceError(true)}
                  onDocumentLoadError={(error) => {
                    console.error("Erreur de chargement du PDF :", error);
                  }}
                />
              </Worker>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
