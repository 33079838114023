import React from "react";
import { useState, useRef, useContext, useEffect, useCallback } from "react";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
// import { useNavigate } from "react-router-dom";
import Loader from "../../components/addOns/loader";
import AdminCustomerCard from "../../components/admin/AdminCustomerCard";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import Paging from "../../components/addOns/Paging";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";

import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function AdminCustomersList() {
  // const navigate = useNavigate();
  const [customerList, setCustomerList] = useState();
  const [customerState, setCustomerState] = useState("all");
  const [loading, setLoading] = useState(true);
  const [listRefresh, setListRefresh] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [sort, setSort] = useState("datedown");

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [nbPages, setNbPages] = useState();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const societyRef = useRef();
  const mobilePhoneRef = useRef();
  const cityRef = useRef();
  const landlinePhoneRef = useRef();

  const postApiCalls = useContext(PostApiContext);

  const navigate = useNavigate();

  const objectButtons = [
    {
      title: "Nouveau contact",
      className: "hide-text-650",
      icon: faFileMedical,
      action: () => navigate(`/admin-customer/`),
      showByRoleId: true,
    },
  ];

  const stateObject = [
    { valid: "all", name: "tous les clients" },
    { valid: "active", name: "clients actifs" },
    { valid: "unactive", name: "clients désactivés" },
    { valid: "full-access", name: "Accès complet" },
    { valid: "partial-access", name: "Accès partiel" },
  ];

  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setListRefresh(true);
  };

  const onValidFilter = useCallback(
    async (e) => {
      e?.preventDefault();
      setCustomerList(null);
      setLoading(true);

      const values = {
        lastName: lastNameRef.current?.value,
        firstName: firstNameRef.current?.value,
        society: societyRef.current?.value,
        city: cityRef.current?.value,
        mobilePhone: mobilePhoneRef.current?.value,
        landlinePhone: landlinePhoneRef.current?.value,
      };

      try {
        const response = await postApiCalls.adminCustomersList(
          values,
          sort,
          customerState,
          currentPage,
          perPage
        );
        setCustomerList(response.customers);
        setPerPage(response.perPage);
        setNbPages(response.nbPages);
        setCurrentPage(response.currentPage);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [postApiCalls, sort, customerState, currentPage, perPage]
  );

  useEffect(() => {
    if (listRefresh) {
      onValidFilter();
      setListRefresh(false);
    }
  }, [onValidFilter, listRefresh]);

  useEffect(() => {
    if (isFirstLoad) {
      onValidFilter();
      setIsFirstLoad(false);
    }
  }, [onValidFilter, isFirstLoad]);

  const sortOptions = {
    options: [
      { value: "datedown", content: "Date de création décroissante" },
      { value: "dateup", content: "Date de création croissante" },
    ],
  };

  return (
    <>
      {loading && <Loader />}
      <div className="">
        <MainHeader headerTitle={"clients"} objectButtons={objectButtons} />
        <div className="listPage">
          <StateFilter
            setState={setCustomerState}
            state={customerState}
            stateObject={stateObject}
            setListRefresh={setListRefresh}
          />
          <SearchComponent
            refresh={refresh}
            onSearchSelectChange={onValidFilter}
            onSearchSubmit={onValidFilter}
            setListRefresh={setListRefresh}
            contentObject={{
              ContentName: "Client",
              Content: [
                {
                  name: "lastName",
                  title: "Nom",
                  type: "text",
                  ref: lastNameRef,
                },
                {
                  name: "firstName",
                  title: "Prénom",
                  type: "text",
                  ref: firstNameRef,
                },
                { name: "city", title: "Ville", type: "text", ref: cityRef },
                {
                  name: "mobilePhone",
                  title: "Mobile",
                  type: "text",
                  ref: mobilePhoneRef,
                },
                {
                  name: "landlinePhone",
                  title: "Fixe",
                  type: "text",
                  ref: landlinePhoneRef,
                },
                {
                  name: "society",
                  title: "Société",
                  type: "text",
                  ref: societyRef,
                },
              ],
            }}
          />
          <SortByFilter
            setSort={setSort}
            sortOptions={sortOptions}
            setListRefresh={setListRefresh}
          />
          <div style={{ marginTop: "2rem" }} className="cards-list">
            {customerList &&
              customerList.map((customer, k) => {
                return (
                  <AdminCustomerCard
                    key={k}
                    contactId={customer.user_id}
                    refcontact={customer.ref}
                    firstName={customer.u_first_name}
                    lastName={customer.u_last_name}
                    society={customer.society_name}
                    adress={customer.adress1}
                    CP={customer.postal_code}
                    city={customer.city}
                    mobile_phone={customer.mobile_phone}
                    landline_phone={customer.landline_phone}
                    openContact={() =>
                      navigate(`/admin-customer/${customer.user_id}`)
                    }
                    active={customer.active}
                    access={customer.catalog_access}
                  />
                );
              })}
          </div>
        </div>
        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
          setListRefresh={setListRefresh}
        />
        <a href="#!" alt="" className="add-button">
          <i className="fa-solid fa-plus"></i>
        </a>
      </div>
    </>
  );
}
