import DeletButton from "./../addOns/deletButton";
import { useAuthState } from "../../context/authContext";
export default function Accountant({
  accountant,
  handleShowAccountantPop,
  setRefresh,
  refresh,
}) {
  const { roleId } = useAuthState();

  const validPopContent = {
    title: "Suppression",
    desc: "Êtes-vous sûr de vouloir supprimer le compte comptable ?",
    toasterMessage: "Le comptable a été supprimé avec succès",
  };

  return (
    <>
      <div className="accountant">
        <h2 className="accountant__title">COMPTABLE</h2>
        <div className=" accountant__group">
          <div
            onClick={roleId <= 3 ? handleShowAccountantPop : ""}
            className="accountantContent accountant__group_content"
          >
            <div className={"accountant__group_content_name"}>
              <label htmlFor="">Nom / Prénom</label>
              <p>{accountant?.first_name}</p>
              <p>{accountant?.last_name}</p>
            </div>
            <div className="accountantMail accountant__group_content_mail">
              <label htmlFor="">Mail</label>
              <p>{accountant?.email}</p>
            </div>
          </div>
          {roleId <= 3 && (
            <DeletButton
              url={"c-delete-accountant"}
              className={"interloButton"}
              id={accountant?.id}
              setRefresh={setRefresh}
              refresh={refresh}
              validPopContent={validPopContent}
              color={"white"}
            />
          )}
        </div>
      </div>
    </>
  );
}
