import React, { useEffect } from "react";
import MainHeader from "../../components/addOns/headers/MainHeader";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { useContext, useCallback, useState, useRef } from "react";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import { TableContainer } from "@mui/material";
import Paging from "../../components/addOns/Paging";
import Loader from "../../components/addOns/loader";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import CreateAdminItemPop from "../../components/admin/catalog/CreateAdminItemPop";
import supprimerRouge from "../../assets/images/icons/Picto/supprimer-rouge.svg";
import ValidPop from "../../components/addOns/popComponents/validPop";

export default function AdminArticles() {
  const postApiCalls = useContext(PostApiContext);

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [nbPages, setNbPages] = useState(1);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [catalogList, setCatalogList] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [subfamilyList, setSubfamilyList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [formState, setFormState] = useState({
    catalog: "",
    family: "",
    subFamily: "",
    brand: "",
    supplier: "",
    manufacturerRef: "",
    distributerRef: "",
    designation: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const manufacturerRef = useRef();
  const distributerRef = useRef();
  const designationRef = useRef();

  const [showArticlePopup, setShowArticlePopup] = useState(false);
  const [showValidPop, setShowValidPop] = useState(false);
  const [itemCopy, setItemCopy] = useState({});
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [articlePopState, setArticlePopState] = useState("");
  // const [unitList, setUnitList] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const onValidFilter = useCallback(
    async (e) => {
      e?.preventDefault();
      setArticles([]);
      setLoading(true);

      const values = {
        catalog: formState.catalog,
        family: formState.family,
        subFamily: formState.subFamily,
        brand: formState.brand,
        supplier: formState.supplier,
        manufacturerRef: manufacturerRef.current?.value,
        distributerRef: distributerRef.current?.value,
        designation: designationRef.current?.value,
      };

      try {
        const response = await postApiCalls.adminItemsList(
          values,
          currentPage,
          perPage
        );
        setArticles(response.listItems);
        setCatalogList(response.listCatalogs);
        setFamilyList(response.familyList);
        setSubfamilyList(response.subFamilyList);
        setBrandList(response.brandList);
        setSuppliers(response.supplierList);
        setNbPages(response.nbPages);
      } catch (error) {
        // console.log(error);
      }

      setLoading(false);
      setRefresh(false);
    },
    //eslint-disable-next-line
    [postApiCalls, currentPage, perPage, refresh, formState]
  );

  useEffect(() => {
    onValidFilter();
  }, [onValidFilter]);

  const searchContentObject = {
    ContentName: "Article",
    Content: [
      {
        name: "catalog",
        title: "Catalogue",
        type: "select",
        value: formState.catalog,
        options: catalogList?.map((catalog) => ({
          label: catalog.name,
          id: catalog.id,
        })),
      },
      {
        name: "family",
        title: "Famille",
        type: "select",
        value: formState.family,
        options: familyList?.map((family) => ({
          label: family.family,
        })),
      },
      {
        name: "subFamily",
        title: "Sous-famille",
        type: "select",
        value: formState.subFamily,
        options: subfamilyList?.map((subfamily) => ({
          label: subfamily.subfamily,
        })),
      },
      {
        name: "brand",
        title: "Marque",
        type: "select",
        value: formState.brand,
        options: brandList?.map((brand) => ({
          label: brand.brand,
        })),
      },
      {
        name: "supplier",
        title: "Fournisseur",
        type: "select",
        value: formState.supplier,
        options: suppliers?.map((supplier) => ({
          label: supplier.supplier,
        })),
      },
      {
        name: "manufacturerRef",
        title: "Ref. Fabricant",
        type: "text",
        ref: manufacturerRef,
      },
      {
        name: "distributerRef",
        title: "Ref. Distributeur",
        type: "text",
        ref: distributerRef,
      },
      {
        name: "designation",
        title: "Désignation",
        type: "text",
        ref: designationRef,
      },
    ],
  };

  const onSearchSelectChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const refreshForm = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setFormState({
      catalog: "",
      family: "",
      subFamily: "",
      brand: "",
      supplier: "",
      manufacturerRef: "",
      distributerRef: "",
      designation: "",
    });
    setRefresh(true);
  };

  const handleCheckboxChange = (id) => {
    if (selectedArticles.includes(id)) {
      setSelectedArticles((prevState) =>
        prevState.filter((articleId) => articleId !== id)
      );
    } else {
      setSelectedArticles((prevState) => [...prevState, id]);
    }
  };

  useEffect(() => {
    setShowDeleteButton(selectedArticles.length > 0);
  }, [selectedArticles]);

  const handleCreateItem = (e) => {
    setSelectedArticle(null);
    setItemCopy({});
    setArticlePopState("create");
    setShowArticlePopup(true);
  };

  const headerButtons = [
    {
      title: "Nouvel article",
      className: "hide-text-650",
      icon: faFileMedical,
      action: handleCreateItem,
      showByRoleId: false,
    },
  ];

  const handleDeleteItems = async (selectedArticles) => {
    const values = {
      articlesList: selectedArticles,
    };

    try {
      postApiCalls.deleteAdminItems(values).then(() => {
        setRefresh(!refresh);
        setSelectedArticles([]);
        setShowDeleteButton(false);
        setShowValidPop(false);
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const ValidPopButtonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    {
      text: "Continuer",
      className: "continueButton",
      action: () => handleDeleteItems(selectedArticles),
    },
  ];

  return (
    <>
      {showValidPop && (
        <ValidPop
          setShowValidPop={setShowValidPop}
          showValidPop={showValidPop}
          buttonsObject={ValidPopButtonsObject}
          title={"Suppression d'articles"}
          desc={"Êtes-vous sûr de vouloir supprimer ces articles ?"}
          bgcAction={() => setShowValidPop(false)}
        />
      )}
      {showArticlePopup && (
        <>
          <CreateAdminItemPop
            setShowArticlePopup={setShowArticlePopup}
            itemCopy={itemCopy}
            setItemCopy={setItemCopy}
            subfamilyList={subfamilyList}
            familyList={familyList}
            brandList={brandList}
            supplierList={suppliers}
            // unitList={unitList}
            catalogList={catalogList}
            refresh={refresh}
            setRefresh={setRefresh}
            articlePopState={articlePopState}
            setArticlePopState={setArticlePopState}
            selectedArticle={selectedArticle}
            setSelectedArticle={setSelectedArticle}
          />
          <div
            onClick={() => setShowArticlePopup(false)}
            className="bluredDiv"
          ></div>
        </>
      )}
      {loading && <Loader />}
      <MainHeader
        headerTitle="Articles"
        objectButtons={headerButtons}
        // roleId={roleId}
      />
      <div className="listPage">
        <SearchComponent
          contentObject={searchContentObject}
          onSearchSubmit={onValidFilter}
          onSearchSelectChange={onSearchSelectChange}
          refresh={refreshForm}
          title={"Rechercher"}
          defaultShowFilter={true}
        />
        {showDeleteButton && (
          <button
            className="admin-articles__delete-button"
            onClick={() => setShowValidPop(true)}
          >
            <img src={supprimerRouge} alt={"poubelle"} />
            <p>Supprimer la sélection</p>
          </button>
        )}
        <TableContainer
          className="admin-connections"
          style={{ maxHeight: "80vh", overflowY: "auto" }}
        >
          <table className="admin-connections__table">
            <thead className="admin-connections__table_head">
              <tr>
                <th></th>
                <th>Famille</th>
                <th>Sous famille</th>
                <th>Marque</th>
                <th>Fournisseur</th>
                <th>Désignation</th>
                <th>Ref Fab</th>
                <th>Ref Dist</th>
                <th>PA HT</th>
                <th>PV HT</th>
              </tr>
            </thead>
            <tbody className="admin-connections__table_body">
              {articles?.map((article) => (
                <tr
                  key={article.id}
                  className="admin-connections__table_body_row"
                  onClick={() => {
                    setSelectedArticle(article);
                    setArticlePopState("edit");
                    setShowArticlePopup(true);
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedArticles.includes(article.id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => handleCheckboxChange(article.id)}
                    />
                  </td>
                  <td className="lightFont">{article.family}</td>
                  <td className="lightFont">{article.subfamily}</td>
                  <td className="lightFont">{article.brand}</td>
                  <td className="lightFont">{article.supplier}</td>
                  <td className="lightFont">{article.name}</td>
                  <td className="lightFont">
                    {article.refMaker ?? "Non disponible"}{" "}
                  </td>
                  <td className="lightFont">
                    {article.refDistributor ?? "Non disponible"}
                  </td>
                  <td className="lightFont">{article.netPrice}</td>
                  <td className="lightFont">{article.basePrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <Paging
        perPage={perPage}
        setPerPage={setPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        nbPages={nbPages}
      />
    </>
  );
}
