import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faPhone,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

function AdminCustomerCard(props) {
  return (
    <section
      onClick={() => props.openContact(props.valid)}
      className="cards"
      key={props.contactId}
      id={props.contactId}
    >
      <div className="tileCard cards__contactContainer">
        <div className="cards__contactContainer_titleContainer">
          <div className="cards__contactContainer_titleContainer_title">
            <p className="cards__contactContainer_titleContainer_title_name">
              {props?.lastName} {props?.firstName}
            </p>
            <div className="cards__contactContainer_titleContainer_title_stateContainer">
              {/* <p className="cards__contactContainer_titleContainer_title_stateContainer_state">
                {props.active === "1" ? "Actif" : "Désactivé"}
              </p> */}
              <p className="cards__contactContainer_titleContainer_title_stateContainer_state">
                {props.access === "full" ? "Complet" : "Partiel"}
              </p>
              <FontAwesomeIcon
                id={"mark" + props.id}
                style={{ marginLeft: "1rem" }}
                className={`cards__titleContainer_stateContainer_icon state${
                  props.active === "1" ? "Green" : "Red"
                }`}
                icon={faCircleCheck}
              />
            </div>
          </div>
          <p className="cards__contactContainer_society">{props.society}</p>
          <div className="cards__contactContainer_address">
            <FontAwesomeIcon icon={faHouse} />{" "}
            <p>
              {props.adress} {props.CP}{" "}
              <span className={"cards__contactContainer_address_city"}>
                {props.city}
              </span>
            </p>
          </div>
          <div className="cards__contactContainer_phone-number">
            {props.landline_phone || props.mobile_phone ? (
              <>
                <FontAwesomeIcon icon={faPhone} />
                <p className="ttc">
                  {props.landline_phone}
                  {props.landline_phone && props.mobile_phone ? " - " : ""}
                  {props.mobile_phone}
                </p>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPhone} />
                <p>Pas de numéro de téléphone renseigné</p>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminCustomerCard;
