import { useContext } from "react";
import EditingMainHeader from "../../addOns/headers/EditingMainHeader";
import ItemPopForm from "../../customer/catalogues/cataloguePops/itemPopForm";
import Contact from "../../../routes/contact/contact";
import { AppContext } from "../../../context/appContext";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";
import { useAuthState } from "../../../context/authContext";
// Si vous souhaitez conserver la logique de suppression, vous pouvez l'intégrer via un ValidPop ou une autre solution.

export default function CreateAdminItemPop({
  itemCopy,
  setItemCopy,
  catalogList,
  brandList,
  familyList,
  subfamilyList,
  supplierList,
  unitList,
  refresh,
  setRefresh,
  selectedArticle,
  articlePopState,
  setShowArticlePopup,
  setArticlePopState,
  setSelectedArticle,
}) {
  const postApiContext = useContext(PostApiContext);
  const appContext = useContext(AppContext);
  const { roleId } = useAuthState();

  // Fonction de soumission de formulaire pour modification/création
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const values = {
      id: articlePopState === "edit" ? selectedArticle?.id : null,
      catalogId: selectedArticle?.catalog.id,
      family: selectedArticle?.family,
      subFamily: selectedArticle?.subfamily,
      brand: selectedArticle?.brand,
      supplier: selectedArticle?.supplier,
      designation: selectedArticle?.name || selectedArticle?.designation,
      refMaker: selectedArticle?.refMaker,
      refDistributor: selectedArticle?.refDistributor,
      packageItem: selectedArticle?.package,
      auPrice: selectedArticle?.auPrice,
      netPrice: selectedArticle?.netPrice,
      basePrice: selectedArticle?.basePrice,
      active: 1,
    };

    postApiContext
      .postValidAdminItem(values)
      .then((response) => {
        setShowArticlePopup(false);
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Construction du headerButtonsList pour EditingMainHeader
  const headerButtonsList = {
    ...(articlePopState === "display" &&
      (selectedArticle?.adminCatalogId || selectedArticle?.societyId) && {
        dropdown: {
          modifier: {
            content: "Modifier",
            handleClick: () => setArticlePopState("edit"),
            redir: false,
          },
          dupliquer: {
            content: "Dupliquer",
            handleClick: () => {
              setItemCopy(selectedArticle);
              setArticlePopState("create");
            },
            redir: false,
          },
          supprimer: {
            content: "Supprimer",
            handleClick: () => {
              // Ici, vous pouvez déclencher une logique de suppression (par exemple ouvrir un ValidPop)
              // Pour l'exemple, on ne fait qu'un console.log
              console.log("Suppression de l'article");
            },
            redir: true,
          },
        },
      }),
    ...((articlePopState === "edit" || articlePopState === "create") && {
      mainButton: {
        buttonIcon: "sauvegarder",
        buttonText: "Sauvegarder",
        buttonAction: (e) => handleEditSubmit(e),
        className: "hide-text-650"
      },
    }),
  };

  return (
    <>
      {appContext.showContactPopup && (
        <>
          <div
            onClick={() => appContext.setShowContactPopup(false)}
            className="bluredDiv"
          ></div>
          <div
            style={{
              width: "90%",
              height: "90vh",
              backgroundColor: "#fff",
              zIndex: 99999,
              position: "absolute",
              margin: "5vh 5%",
              overflow: "hidden",
            }}
          >
            <Contact />
          </div>
        </>
      )}
      <form className="itemPop" onSubmit={handleEditSubmit} action="submit">
        <EditingMainHeader
          headerTitle={
            articlePopState === "create"
              ? "Créer un article"
              : articlePopState === "edit"
              ? "Modifier l'article"
              : articlePopState === "display"
              ? "Informations de l'article"
              : ""
          }
          setShowArticlePopup={setShowArticlePopup}
          headerButtonsList={headerButtonsList}
          roleId={roleId}
        />
        <ItemPopForm
          handleEditItemSubmit={handleEditSubmit}
          dataLists={{
            catalogList,
            brandList,
            familyList,
            subfamilyList,
            supplierList,
            unitList,
          }}
          selectedArticle={selectedArticle}
          setSelectedArticle={setSelectedArticle}
          articlePopState={articlePopState}
          noForm={true}
        />
      </form>
    </>
  );
}
