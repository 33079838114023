import {useContext, useEffect, useRef, useState, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import ContactCard from "../../components/customer/contacts/contactCard";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import Loader from "../../components/addOns/loader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import Paging from "../../components/addOns/Paging";

function ContactList({ selectMode = false, onSelectContact, setContacts}) {

    const navigate = useNavigate();
    const [contactList, setContactList] = useState();
    const [contactState, setContactState] = useState("all");
    const [loading, setLoading] = useState(true);
    const [sort] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [nbPages, setNbPages] = useState();
    const [filterLetter, setFilterLetter] = useState("");

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const mobilePhoneRef = useRef();
    const cityRef = useRef();

    const getApiCalls = useContext(GetApiContext)

    const [bla, setBla] = useState(false);

    const clearFilter = () => {
        setFilterLetter("");
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        societyRef.current.value = "";
        mobilePhoneRef.current.value = "";
        cityRef.current.value = "";
        setContactList(null);
        setCurrentPage(1);
        setBla(true);
    };

    const fetchContacts = useCallback(async () => {
        setLoading(true);
        const filters = {
            lastName: lastNameRef.current?.value || "",
            firstName: firstNameRef.current?.value || "",
            society: societyRef.current?.value || "",
            city: cityRef.current?.value || "",
            tel: mobilePhoneRef.current?.value || "",
            letter: filterLetter
        };
        try {
            const response = await getApiCalls.getContactsList(contactState, currentPage, perPage, sort, filters)
                // console.log("response contact list :", response);
                if (response.listContacts && response.listContacts.length) {
                    setContactList(response.listContacts);
                } else {
                    setContactList(null);
                }
                setPerPage(response.perPage);
                setNbPages(response.nbPages);
                setCurrentPage(response.currentPage);
                if (setContacts) {
                    setContacts(response.listContacts);
                }
            }
            catch(error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        , [contactState, currentPage, perPage, sort, filterLetter, getApiCalls, setContacts]);

    useEffect(() => {
        fetchContacts();
        setBla(false);
    }, [filterLetter, bla, currentPage, perPage, sort, getApiCalls, contactState, setContacts, fetchContacts]);

    const openContact = (contactId) => {
        if (selectMode) {
            onSelectContact(contactId);
        } else {
            navigate(`/contactsList/contact/${contactId}`);
        }
    }

    const onValidFilter = async (e) => {
        e?.preventDefault();
        setContactList(null);
        fetchContacts();
    };

    const objectButtons = [
        {
            title: "Nouveau contact",
            icon: faFileMedical,
            action: () => navigate("/contactsList/contact"),
            showByRoleId: true,
            className: " hide-text-650"
        },
    ]

    const stateObject = [
        {valid: "all", name: "tous les contacts"},
        {valid: "customer", name: "vos clients"},
        {valid: "provider", name: "vos fournisseurs"},
        {valid: "prospect", name: "vos prospects"},
    ];
    return (
        <>
            {loading && <Loader/>}
            <div className="">
                <MainHeader headerTitle={"CONTACTS"} objectButtons={objectButtons}/>
                <div className="listPage">
                    <StateFilter setState={setContactState} state={contactState} stateObject={stateObject} />

                    <SearchComponent
                        refresh={clearFilter}
                        onSearchSelectChange={onValidFilter}
                        onSearchSubmit={onValidFilter}
                        contentObject={{
                            ContentName: "CONTACT",
                            Content: [
                                {name: "lastName", title: "Nom", type: "text", ref: lastNameRef},
                                {name: "firstName", title: "Prénom", type: "text", ref: firstNameRef},
                                {name: "mobilePhone", title: "Mobile", type: "text", ref: mobilePhoneRef},
                                {name: "city", title: "Ville", type: "text", ref: cityRef},
                                {name: "society", title: "Société", type: "text", ref: societyRef},
                            ]
                        }}
                        />
                    <div style={{marginTop: "2rem"}} className="cards-list">
                        {contactList &&
                            contactList.map((contact, k) => {
                                return (
                                    <ContactCard
                                        key={k}
                                        contactId={contact.id}
                                        refcontact={contact.ref}
                                        firstName={contact.firstName}
                                        lastName={contact.lastName}
                                        society={contact.society}
                                        adress={contact.adress1}
                                        CP={contact.postal}
                                        city={contact.city}
                                        mobilePhone={contact.mobilePhone}
                                        function={contact.function}
                                        type={contact.type}
                                        openContact={openContact}
                                    />
                                );
                            })}
                    </div>
                </div>
                {!loading && (
                        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
        //   setListRefresh={setListRefresh}
        />
                )}
                <a href="#!" alt="" className="add-button">
                    <i className="fa-solid fa-plus"></i>
                </a>
            </div>
        </>
    );
}

export default ContactList;
