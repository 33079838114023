import { useContext, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { toast } from "react-toastify";

export default function AccountantPop({
  accountant,
  handleShowAccountantPop,
  setRefresh,
  refresh,
  societyId,
}) {
  const lastNameRef = useRef("");
  const firstNameRef = useRef("");
  const mailRef = useRef("");

  const PostApiCall = useContext(PostApiContext);
  const id = accountant?.id || "";

  const handleAccountantSubmit = (e) => {
    e.preventDefault();
    const value = {
      lastName: lastNameRef.current.value || accountant?.last_name,
      firstName: firstNameRef.current.value || accountant?.first_name,
      email: mailRef.current.value || accountant?.email,
    };

    //verif entrée formulaire pour éviter les champs vides
    const isFormVal = Object.values(value).every(
      (field) => field !== "" && field !== null && field !== undefined
    );
    if (isFormVal) {
  const toastId = toast.loading("Ajout du comptable en cours...");

  PostApiCall.settingsAccountantPost(id, value)
    .then((response) => {
      console.log("response", response);

      if (response.status === "success") {
        toast.update(toastId, {
          render: "Le comptable a bien été ajouté. Un mail lui a été envoyé avec ses identifiants",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId, {
          render: "Une erreur est survenue lors de l'ajout du comptable",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }

      setRefresh(!refresh);
    })
    .catch((error) => {
      toast.update(toastId, {
        render: "Erreur serveur : Impossible d'ajouter le comptable",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      console.error(error);
    });

  handleShowAccountantPop();
} else {
  toast.error("Veuillez remplir tous les champs");
}

  };

  return (
    <>
      <form
        onSubmit={handleAccountantSubmit}
        type="submit"
        className="popUp interlocutorSettingsPop"
      >
        <div className="titleContent">
          <h3 className="title">Créer un nouveau Comptable</h3>
          <FontAwesomeIcon
            className="closeIcon"
            onClick={handleShowAccountantPop}
            icon={faXmark}
          />
        </div>
        <div className="content">
          <input
            required
            ref={lastNameRef}
            defaultValue={accountant?.last_name}
            className="input"
            type="text"
            placeholder="Nom"
            id={"lastName"}
          />
          <input
            required
            ref={firstNameRef}
            defaultValue={accountant?.first_name}
            className="input"
            type="text"
            placeholder="Prénom"
          />

          <input
            required
            ref={mailRef}
            defaultValue={accountant?.email}
            className="input"
            type="mail"
            placeholder="Mail"
          />
        </div>
        {!id && <p>Un email lui sera envoyé avec ses identifiants</p>}
        <button className="submitButton">Valider</button>
      </form>
    </>
  );
}
