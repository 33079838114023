import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stateTransition } from "../../../utils/constants";
import telecharger from "../../../assets/images/icons/Picto/telecharger.svg";
import imprimer from "../../../assets/images/icons/Picto/imprimer.svg";
import envoyer from "../../../assets/images/icons/Picto/envoyer.svg";
import dupliquer from "../../../assets/images/icons/Picto/dupliquer.svg";
import dupliquerenfacture from "../../../assets/images/icons/Picto/dupliquerenfacture.svg";

export default function OptionsButtonPop({
  icon,
  title,
  action,
  className,
  color,
  name,
}) {
  // Map des images associées aux titres
  const imageMap = {
    Télécharger: telecharger,
    Imprimer: imprimer,
    Envoyer: envoyer,
    Dupliquer: dupliquer,
    Transférer: dupliquerenfacture,
    "Transférer en facture": dupliquerenfacture,
    "Créer un acompte": dupliquer,
  };

  return (
    <>
      <button
        name={name}
        className={`cards__options_content_button ${
          stateTransition.translate === title ? stateTransition.color : ""
        } ${className ?? ""}`}
        onClick={action}
      >
        {/* Si `icon` correspond à une clé d'image dans `imageMap`, affiche l'image, sinon affiche l'icône FontAwesome */}
        {imageMap[title] ? (
          <img
            src={imageMap[title]}
            alt={title}
            className={`cards__options_content_button_icon ${color}`}
          />
        ) : (
          icon && (
            <FontAwesomeIcon
              className={`cards__options_content_button_icon ${color}`}
              icon={icon}
            />
          )
        )}
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </button>
    </>
  );
}
