import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// Pictos imports
import telecharger from "../../../assets/images/icons/Picto/telecharger.svg";
import imprimer from "../../../assets/images/icons/Picto/imprimer.svg";
import envoyer from "../../../assets/images/icons/Picto/envoyer.svg";
import dupliquer from "../../../assets/images/icons/Picto/dupliquer.svg";
import dupliquerenfacture from "../../../assets/images/icons/Picto/dupliquerenfacture.svg";
import modifier from "../../../assets/images/icons/Picto/modifier.svg";
import supprimer from "../../../assets/images/icons/Picto/supprimer.svg";

import { faClipboard } from "@fortawesome/free-solid-svg-icons";

export default function DropDownButton({ items, dropDownTitle }) {
    const [open, setOpen] = useState(false); // état ouvert/fermé
    const toggleDropdown = () => setOpen(!open);

    const imageMap = {
        Télécharger: telecharger,
        Imprimer: imprimer,
        Envoyer: envoyer,
        Dupliquer: dupliquer,
        Transférer: dupliquerenfacture,
        Modifier: modifier,
        "Créer un acompte": dupliquer,
        Supprimer: supprimer,
    };

    return (
        <div className="toolbar__container_tools_dropdown">
            <div
                className="toolbar__container_tools_dropdown_dropdown-toggle button"
                onClick={toggleDropdown}
            >
                <p className="hide-text">
                    {dropDownTitle ? dropDownTitle : "Plus d'actions"}
                </p>
                <FontAwesomeIcon icon={faPlus} />
            </div>
            <div
                className={`toolbar__container_tools_dropdown_dropdown-menu dropdown-menu ${open ? "open" : ""}`}
            >
                {Object?.entries(items).map(([key, value]) => (
                    <div key={key} href="" onClick={value.handleClick}>
                        {value.icon ? (
                            <FontAwesomeIcon icon={faClipboard} />
                        ) : (
                            <img
                                src={imageMap[value.content]}
                                alt={value.content}
                                className="dropdown-icon"
                            />
                        )}
                        <p>{value.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}