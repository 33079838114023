import {useContext, useEffect} from "react";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { toast } from "react-toastify";

export default function InterlocutorPop({
    handleShowInterlocutorPop,
    interlocutorDataFilter,
    setRefresh,
    refresh,
}) {

  const PostApiCall = useContext(PostApiContext);
  // STATE
  const [civility, setCivility] = useState("1");
  const [id, setId] = useState("");
  const [interlocutorData, setInterlocutorData] = useState(
    interlocutorDataFilter[0]
  );

  // REF
  const lastNameRef = useRef("");
  const firstNameRef = useRef("");
  const functionRef = useRef("");
  const mailRef = useRef("");
  const initialsRef = useRef("");

  useEffect(() => {
    if (interlocutorDataFilter[0]) {
      setInterlocutorData(interlocutorDataFilter[0]);
      setId(interlocutorDataFilter[0].id);
    }
  }, [interlocutorData, interlocutorDataFilter]);

  const handleCivilityChange = (e) => {
    setCivility(e.target.value);
  };

  const handleInterlocutorSubmit = (e) => {
    e.preventDefault();
    const value = {
      civility: civility,
      lastName: lastNameRef.current.value,
      firstName: firstNameRef.current.value,
      function: functionRef.current.value,
      email: mailRef.current.value,
      initials: initialsRef.current.value || interlocutorData.initials,
    };

    //verif entrée formulaire pour éviter les champs vides
    const isFormValid = Object.values(value).every(
      (field) => field !== "" && field !== null && field !== undefined
    );

    if (isFormValid) {
  const toastId = toast.loading("Ajout de l'interlocuteur en cours...");

  PostApiCall.settingsInterlocutorPost(id, value)
    .then((response) => {
      if (response.status === "success") {
        toast.update(toastId, {
          render: id ? "L'interlocuteur a bien été enregistré" : "L'interlocuteur a bien été ajouté. Un mail lui a été envoyé avec ses identifiants",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId, {
          render: "Une erreur est survenue lors de l'ajout de l'interlocuteur",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }

      console.log(response);
      setRefresh(!refresh);
    })
    .catch((error) => {
      toast.update(toastId, {
        render: "Erreur serveur : Impossible d'ajouter l'interlocuteur",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      console.error(error.response?.data || "Erreur inconnue");
    });

  handleShowInterlocutorPop();
} else {
  toast.error("Formulaire invalide. Veuillez remplir tous les champs");
}
  };

  return (
    <>
      <form
        onSubmit={handleInterlocutorSubmit}
        className="popUp interlocutorSettingsPop"
      >
        <div className="titleContent">
          <h3 className="title">
            {id ? "Modifier un" : "Créer un nouvel"} Interlocuteur
          </h3>
          <FontAwesomeIcon className="closeIcon" onClick={handleShowInterlocutorPop} icon={faXmark} />
        </div>
        <div className="content">
          <select className="select" defaultValue={interlocutorData?.civility_id} onChange={handleCivilityChange} >
            <option className="option" value="1">
              Mr
            </option>
            <option className="option" value="2">
              Mme
            </option>
          </select>
          <input
            required
            defaultValue={interlocutorData?.last_name}
            ref={lastNameRef}
            className="input"
            type="text"
            placeholder="Nom"
          />
          <input
            required
            defaultValue={interlocutorData?.first_name}
            ref={firstNameRef}
            className="input"
            type="text"
            placeholder="Prénom"
          />
          <input
            defaultValue={interlocutorData?.function}
            required
            ref={functionRef}
            className="input"
            type="text"
            placeholder="Fonction"
          />
          <input
            required
            defaultValue={interlocutorData?.email}
            ref={mailRef}
            className="input"
            type="mail"
            placeholder="Mail"
          />
          <input
            required
            defaultValue={interlocutorData?.initials}
            ref={initialsRef}
            className="input"
            type="text"
            placeholder="Initials"
          />
        </div>
        {!id && <p>Un email lui sera envoyé avec ses identifiants</p>}
        <button className="submitButton">{id ? "Valider" : "Créer le compte"}</button>
      </form>
    </>
  );
}
