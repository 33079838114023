import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { GetApiContext } from './apiCalls/getApiCalls';

export const PdfContext = createContext();

export default function PdfProvider ({ children }) {
    const [loading, setLoading] = useState(false);

    const getApiContext = useContext(GetApiContext);

    const getPDF = async (elementType, id) => {
        setLoading(true);
        try {
            let response;
            switch (elementType) {
                case 'quote':
                    response = await getApiContext.getQuotePDF(id);
                    break;
                case 'invoice':
                    response = await getApiContext.getInvoicePDF(id);
                    break;
                case 'delivery':
                    console.log("delivery", id);
                    response = await getApiContext.getDeliveryPDF(id);
                    break;
                case 'deposit':
                    response = await getApiContext.getDepositPDF(id);
                    break;
                case 'order':
                    response = await getApiContext.getOrderPDF(id);
                    break;
                case 'credit':
                    response = await getApiContext.getCreditPDF(id);
                    break;
                default:
                    throw new Error(`Unsupported elementType: ${elementType}`);
            }
            
            if (!response || !response.data) {
                console.error("Aucune donnée PDF reçue.");
                setLoading(false);
                return null;
            }

            // Convertir la réponse en Blob et créer une URL temporaire
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            setLoading(false);
            return fileURL;
        } catch (error) {
            console.error("Erreur lors de la récupération du PDF :", error);
            setLoading(false);
            return null;
        }
    };

    const handlePDF = async (elementType, id, isPrinting) => {
        console.log("handlePDF", elementType, id, isPrinting);
        try {
            const fileURL = await getPDF(elementType, id);
            console.log("fileURL", fileURL);

            if (!fileURL) {
                console.error("Impossible de récupérer l'URL du PDF.");
                setLoading(false);
                return;
            }

            const newTab = window.open(fileURL);

            if (newTab) {
                if (isPrinting) {
                    newTab.onload = () => {
                        newTab.print();
                    };
                }
            } else {
                console.error("Impossible d'ouvrir un nouvel onglet. Vérifiez les bloqueurs de pop-up.");
                setLoading(false);
            }
        } catch (error) {
            console.error("Erreur lors de l'impression du PDF :", error);
            setLoading(false);
        }
    };

    return (
        <PdfContext.Provider value={{ loading, handlePDF}}>
            {children}
        </PdfContext.Provider>
    );
};

PdfProvider.propTypes = {
    children: PropTypes.node.isRequired,
};