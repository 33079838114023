import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import "../../../styles/style.css";
import Decimal from "decimal.js";
import article_kart from "../../../assets/images/icons/Picto/Achat.svg";
import refresh from "../../../assets/images/picto/refresh.svg";
import DeletButton from "../../addOns/deletButton";
import eraser from "../../../assets/images/picto/eraser_picto.svg";

function OrderLine(props) {
  const [index] = useState(props?.index);
  const [quantity, setQuantity] = useState(props?.quantity);
  const [unitPrice, setUnitPrice] = useState(props?.unitPrice);
  const [totalHT, setTotalHT] = useState(
    props.line.unitPrice * props.line.quantity || 0
  );

  const onChange = (e) => {
    let value = e.target.value;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "quantity":
        if (validated) {
          let newLine = { ...props.line, quantity: value };
          console.log("newLine", newLine);

          props.updateOrder(newLine);
          setQuantity(value);
        } else {
          value = 1;
          let newLine = { ...props.line, quantity: value };
          props.updateOrder(newLine);
          setQuantity(value);
        }
        break;
      case "unitPrice":
        if (validated) {
          let newLine = { ...props.line, unitPrice: value };
          props.updateOrder(newLine);
          setUnitPrice(value);
        } else {
          value = 0;
          let newLine = { ...props.line, unitPrice: value };
          props.updateOrder(newLine);
          setUnitPrice(value);
        }
        break;
      case "tva":
        let newTva = parseInt(e.target.value);
        let newLine = { ...props.line, vatId: newTva };
        props.updateOrder(newLine);
        break;
      case "unit":
        let newUnit = parseInt(e.target.value);
        let newLineUnit = { ...props.line, unitId: newUnit };
        props.updateOrder(newLineUnit);
        break;
    }
  };

  const formatInput = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "quantity":
        let newQuantity = e.target.value;

        newQuantity = parseFloat(
          parseFloat(newQuantity.replace(",", ".")).toFixed(2)
        );
        if (isNaN(newQuantity)) {
          newQuantity = "1.00";
        } else {
          newQuantity = parseFloat(newQuantity).toFixed(2);
        }
        setQuantity(newQuantity);
        var newTotalHT = (
          parseFloat(newQuantity) * parseFloat(unitPrice)
        ).toFixed(2);
        var newLine = {
          ...props.line,
          quantity: newQuantity,
          unitPrice: unitPrice,
          totalHT: newTotalHT,
          index: index,
        };
        console.log("newLine", newLine);

        props.updateOrder(newLine);
        break;
      case "unitPrice":
        let newUnitPrice = e.target.value;
        newUnitPrice = parseFloat(
          parseFloat(newUnitPrice.replace(",", ".")).toFixed(2)
        );
        if (isNaN(newUnitPrice)) {
          newUnitPrice = "0.00";
        } else {
          newUnitPrice = parseFloat(newUnitPrice).toFixed(2);
        }
        newTotalHT = (parseFloat(quantity) * parseFloat(newUnitPrice)).toFixed(
          2
        );
        newLine = {
          ...props.line,
          quantity: quantity,
          unitPrice: newUnitPrice,
          totalHT: newTotalHT,
          index: index,
        };
        props.updateOrder(newLine);
        setUnitPrice(newUnitPrice);
        break;
      case "designation":
        let newDesignation = e.target.value;
        newLine = { ...props.line, name: newDesignation };
        props.updateOrder(newLine);
        console.log("changement désgination, new line", newLine);
        break;
      case "tva":
        let newTva = parseInt(e.target.value);
        if (newTva === null || newTva === undefined) {
          newTva = 8;
        }
        newLine = { ...props.line, vatId: newTva };
        /* setLine(newLine); */
        props.updateOrder(newLine);
        break;
      case "unit":
        let newUnit = parseInt(e.target.value);
        if (newUnit === null || newUnit === undefined) {
          newUnit = 1;
        }
        newLine = { ...props.line, unitId: newUnit };
        /* setLine(newLine); */
        props.updateOrder(newLine);
        break;
    }
  };

  const changeDesignation = (e) => {
    let value = e.target.value;
    let newLine = { ...props.line, name: value };
    props.updateOrder(newLine);
  };

  useEffect(() => {
    const quantityDecimal = new Decimal(props?.line.quantity || 0);
    const unitPriceDecimal = new Decimal(props?.line.unitPrice || 0);
    const totalDecimal = unitPriceDecimal.times(quantityDecimal);
    setTotalHT(totalDecimal.toNumber());
  }, [props.line.quantity, props.line.unitPrice]);

  const validPopContent = {
    title: "Suppression",
    desc: `Êtes-vous sûr de vouloir supprimer cette ligne ?`,
    toasterMessage: "Ligne supprimée avec succès",
  };

  const resetLine = () => {
    const newLine = {
      ...props.line,
      quantity: 1,
      unitPrice: 0,
      unitId: 1,
      vatId: 0,
      name: "",
    };

    props.updateOrder(newLine); // Mise à jour en une seule fois
    setQuantity(1);
    setUnitPrice(0);
    setTotalHT(0);
  };

  return (
    <div className="invoiceLine" id={props.line.numero}>
      <div className="invoiceLine__leftContent">
        <div className="invoiceLine__leftContent_header">
          <div className="invoiceLine__leftContent_header_leftPart">
            <p className="invoiceLine__leftContent_header_leftPart_number">
              {props.line.numero}
            </p>
            {props.line?.itemId ? (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button change-article-button"
                onClick={() => props.openCatalogues(props.index)}
              >
                <img src={refresh} alt="refresh_logo" />
                Changer l'article
              </button>
            ) : (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button add-article-button"
                onClick={() => props.openCatalogues(props.index)}
              >
                <img src={article_kart} alt="article_kart" />
                Ajouter un article
              </button>
            )}
          </div>
          <div className="invoiceLine__leftContent_header_arrowContainer">
            <FontAwesomeIcon
              onClick={() => {
                props.changeOrderLine(props.index, "up");
              }}
              style={
                props.line.numero <= 1 && {
                  opacity: 0.3,
                  cursor: "not-allowed",
                }
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronUp}
            />
            <FontAwesomeIcon
              onClick={() => {
                props.changeOrderLine(props.index, "down");
              }}
              style={
                props.line.numero === props.linesLength.toString() && {
                  opacity: 0.3,
                  cursor: "not-allowed",
                }
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronDown}
            />
            <DeletButton
              deleteItemByNotId={props.removeItem}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              url={"c-delete-order-line"}
              id={props.line.id}
              className={
                "invoiceLine__leftContent_header_arrowContainer_deleteButton"
              }
              validPopContent={validPopContent}
              color={"red"}
            />
          </div>
        </div>
        <textarea
          placeholder="Désignation"
          className={"invoiceLine__leftContent_textarea"}
          title="Désignation"
          name="designation"
          value={props?.designation || ""}
          onChange={changeDesignation}
          onBlur={formatInput}
          rows={10}
          cols={30}
        ></textarea>
        <div className="invoiceLine__leftContent_erasedContainer">
          <button
            className="invoiceLine__leftContent_erasedContainer_button"
            onClick={() => {
              resetLine();
              setTotalHT(0);
            }}
          >
            <img src={eraser} alt="eraser" />
            Effacer l'article
          </button>
        </div>
      </div>
      <div className="invoiceLine__rightContent">
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="unit"
          >
            Unité
          </label>
          <select
            name="unit"
            value={props.line.unitId || 1}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {props.listUnit.map((unit, i) => {
              return (
                <option
                  key={i}
                  value={unit.id}
                  className="invoiceLine__rightContent_inputContainer_input_option"
                >
                  {unit.unit_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="quantity"
          >
            Quantité
          </label>
          <input
            type="number"
            placeholder="Quantité"
            name="quantity"
            title="Quantité"
            value={props?.quantity || 1}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="htPrice"
          >
            Prix&nbsp;HT
          </label>
          <input
            type="number"
            placeholder="Prix HT"
            name="unitPrice"
            title="Prix HT"
            value={props?.unitPrice || 0.0}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          />
        </div>
        <div className="invoiceLine__rightContent_inputContainer">
          <label
            className="invoiceLine__rightContent_inputContainer_label"
            htmlFor="vat"
          >
            TVA
          </label>
          <select
            name="tva"
            value={parseInt(props.line.vatId || 4)}
            onChange={onChange}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {props.listTva.map((tva, i) => {
              return (
                <option
                  key={i}
                  value={tva.id}
                  className="invoiceLine__rightContent_inputContainer_input_option"
                >
                  {tva.rate} %
                </option>
              );
            })}
          </select>
        </div>
        <div className="invoiceLine__rightContent_totalContainer">
          <p className="invoiceLine__rightContent_totalContainer_total">
            Montant HT : &nbsp;<span>{totalHT}&nbsp;€</span>
          </p>
          <p className="invoiceLine__rightContent_totalContainer_total line-discount-total">
            Montant après remise : &nbsp;<span> {totalHT}&nbsp;€</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderLine;
