import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sauvegarder from "../../../assets/images/icons/Picto/Sauvegarder_white.svg";

export default function MainButton({
  buttonIcon,
  buttonText,
  buttonAction,
  className,
  buttonType,
}) {

  return (
    <>
      <button
        onClick={buttonAction}
        className={`toolbar__container_button button ${className}`}
        type={buttonType}
      >
        {buttonIcon && buttonIcon === "sauvegarder" ? (
          <img
            src={sauvegarder}
            alt="save"
            className="toolbar__container_button_icon"
          />
        ) : (
          buttonIcon && (
            <FontAwesomeIcon
              icon={buttonIcon}
              className="toolbar__container_button_icon"
            />
          )
        )}
        <p className={className?.includes("hide-text-650") ? "hide-text-650" : className === "mainButton" || (className?.includes("hide-text") && !className?.includes("hide-text-650")) ? "hide-text" : ""} style={{color: "white"}}>{buttonText}</p>
      </button>
    </>
  );
}
