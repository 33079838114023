import { useContext, useState } from "react";
import { PostApiContext } from "../../../../context/apiCalls/postApiCalls";
import Contact from "../../../../routes/contact/contact";
import { AppContext } from "../../../../context/appContext";
import ItemPopForm from "./itemPopForm";
import EditingMainHeader from "../../../addOns/headers/EditingMainHeader";
import { useAuthState } from "../../../../context/authContext";
import { DeleteApiContext } from "../../../../context/apiCalls/deleteApiCalls";
import ValidPop from "../../../addOns/popComponents/validPop";
import { toast } from "react-toastify";

export default function CreatePersonalItemPop({
  catalogList,
  brandList,
  familyList,
  subfamilyList,
  supplierList,
  unitList,
  refresh,
  setRefresh,
  selectedArticle,
  setSelectedArticle,
  articlePopState,
  setShowArticlePopup,
  setArticlePopState,
}) {
  const { roleId } = useAuthState();
  const postApiContext = useContext(PostApiContext);
  const deleteApiContext = useContext(DeleteApiContext);
  const appContext = useContext(AppContext);

  const [showValidPop, setShowValidPop] = useState(false);

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const values = {
      id: articlePopState === "edit" ? selectedArticle?.id : null,
      catalogName: selectedArticle.catalogName, // si catalogName, ça créé un catalogue
      catalogueId:
        selectedArticle.catalog?.id || selectedArticle.personalCatalogId,
      family: selectedArticle.family,
      subfamily: selectedArticle.subfamily,
      brand: selectedArticle.brand,
      supplier: selectedArticle.supplier,
      name: selectedArticle.name || selectedArticle.designation,
      refMaker: selectedArticle.refMaker,
      refDistributor: selectedArticle.refDistributor,
      packageItem: selectedArticle.package,
      netPrice: selectedArticle.netPrice,
      basePrice: selectedArticle.basePrice,
    };

    postApiContext
      .postValidPersonalItem(values)
      .then((response) => {
        console.log(response);
        response.status === "success" ? values.id ? toast.success("L'article a bien été enregistré") : toast.success("L'article a bien été créé") : toast.error("Erreur lors de la création de l'article");
        setShowArticlePopup(false);
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataLists = {
    catalogList,
    brandList,
    familyList,
    subfamilyList,
    supplierList,
    unitList,
  };

  return (
    <>
      {showValidPop && (
        <ValidPop
          title={"Suppression"}
          desc={"Êtes-vous sûr de vouloir supprimer et article ?"}
          buttonsObject={[
            {
              text: "Retour",
              className: "backButton",
              action: (e) => setShowValidPop(false),
            },
            {
              text: "Continuer",
              className: "continueButton",
              action: () => {
                deleteApiContext
                  .deleteItem("c-delete-personalItem", selectedArticle.id)
                  .then((response) => {
                    console.log(response);
                    response.status === "success" ? toast.success("L'article a bien été supprimé") : toast.error("Erreur lors de la suppression de l'article");
                    setRefresh(!refresh);
                    setShowValidPop(false);
                    setShowArticlePopup(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              },
            },
          ]}
          showValidPop={showValidPop}
          bgcAction={() => setShowValidPop(false)}
        />
      )}

      {appContext.showContactPopup && (
        <>
          <div
            onClick={() => appContext.setShowContactPopup(false)}
            className="bluredDiv"
          ></div>
          <div
            style={{
              width: "90%",
              height: "90vh",
              backgroundColor: "#fff",
              zIndex: 99999,
              position: "absolute",
              margin: "5vh 5%",
              overflow: "hidden",
            }}
          >
            <Contact />
          </div>
        </>
      )}
      <form className="itemPop" onSubmit={handleEditSubmit} action="submit">
        <EditingMainHeader
          backRedirect={
            articlePopState !== "display"
              ? () => setArticlePopState("display")
              : null
          }
          headerTitle={
            articlePopState === "create"
              ? "Créer un article"
              : articlePopState === "edit"
              ? "Modifier l'article"
              : articlePopState === "display"
              ? "Informations de l'article"
              : ""
          }
          setShowArticlePopup={setShowArticlePopup}
          headerButtonsList={{
            ...(articlePopState === "display" &&
            (selectedArticle?.adminId === null ||
              selectedArticle?.societyId !== null)
              ? {
                  dropdown: {
                    modifier: {
                      content: "Modifier",
                      handleClick: () => setArticlePopState("edit"),
                      redir: false,
                    },
                    dupliquer: {
                      content: "Dupliquer",
                      handleClick: () => {
                        setSelectedArticle({ ...selectedArticle, id: null });
                        setArticlePopState("create");
                      },
                      redir: false,
                    },
                    supprimer: {
                      content: "Supprimer",
                      handleClick: () => {
                        setShowValidPop(true);
                      },
                      redir: true,
                    },
                  },
                }
              : {}),
            ...(articlePopState === "edit" || articlePopState === "create"
              ? {
                  mainButton: {
                    buttonIcon: "sauvegarder",
                    buttonText: "Sauvegarder",
                    buttonAction: (e) => handleEditSubmit(e),
                    className: "hide-text-650",
                  },
                }
              : {}),
          }}
          roleId={roleId}
        />
        <ItemPopForm
          handleEditItemSubmit={handleEditSubmit}
          dataLists={dataLists}
          selectedArticle={selectedArticle}
          setSelectedArticle={setSelectedArticle}
          articlePopState={articlePopState}
          noForm={true}
        />
      </form>
    </>
  );
}
