import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceFormContainer from "../../components/customer/invoices/invoice/form/invoiceFormContainer";
import RecapContainer from "../../components/customer/invoices/invoice/recap/recapContainer";
import { useContext, useEffect, useRef, useState } from "react";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { PdfContext } from "../../context/PdfContext";
import { AppContext } from "../../context/appContext";
import Decimal from "decimal.js";
import { generateId } from "../../utils/generateId";
import Loader from "../../components/addOns/loader";
import { useAuthState } from "../../context/authContext";
import { getCurrentDate } from "../../utils/utils";
import { format } from "date-fns";
import MailPop from "../../components/addOns/MailPop";
import { toast } from "react-toastify";

function Invoice() {
  const { userId, roleId } = useAuthState();
  const { id } = useParams();
  const navigate = useNavigate();
  const getApiContext = useContext(GetApiContext);
  const postApiContext = useContext(PostApiContext);
  const appContext = useContext(AppContext);
  const pdfContext = useContext(PdfContext);

  const [invoice, setInvoice] = useState({});
  const [interlocutorsList, setInterlocutorsList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const [contactId, setContactId] = useState("");
  const [groups, setGroups] = useState([]);
  const [interlocutorId, setInterlocutorId] = useState("");
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [unitsList, setUnitsList] = useState([]);
  const [totalHt, setTotalHt] = useState(0);
  const [totalTtc, setTotalTtc] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [newListTva, setNewListTva] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [linkDeposit, setLinkDeposit] = useState();
  const [margin, setMargin] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  // refs
  const refRef = useRef("");
  const titleRef = useRef("");
  const dateRef = useRef(getCurrentDate());

  const formDatas = {
    titleRef,
    setContactId,
    setInterlocutorId,
  };

  const getApiContextRef = useRef(getApiContext);
  const groupsRef = useRef(groups);
  const invoiceRef = useRef(invoice);
  const vatListRef = useRef(vatList);
  const newListTvaRef = useRef(newListTva);

  useEffect(() => {
    getApiContextRef.current = getApiContext;
    groupsRef.current = groups;
    invoiceRef.current = invoice;
    vatListRef.current = vatList;
    newListTvaRef.current = newListTva;
  }, [getApiContext, groups, invoice, vatList, newListTva]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLoading(true);
    if (id) {
      getApiContextRef.current.getInvoice(id).then((data) => {
        // console.log(data);
        if (!data.error) {
          dateRef.current = data.invoice?.invoiceDate;
          setInterlocutorsList(data.listInterlocutors);
          setInvoice(data.invoice);

          setVatList(data.listTva);
          setUnitsList(data.unitList);
          setTotalHt(data.invoice?.totalHt);
          setTotalTtc(data.invoice?.totalTtc);
          setRefresh(false);
          setMargin(data.margin);
          setLinkDeposit(data.deposit);
          setComment(data.invoice?.comment);
          if (newListTvaRef.current?.length < 1) {
            setNewListTva(data.listTva);
          }
        }
      });
    } else {
      refRef.current.value = "FAC" + format(new Date(), "dMyyms");
      let newInvoice = { ...invoiceRef.current };
      let newId = generateId(groupsRef.current);
      if (!newInvoice.groups) newInvoice.groups = [];
      newInvoice.groups.push({
        id: newId,
        invoiceId: invoiceRef.current.id,
        lines: [
          {
            id: newId,
            invoiceGroupId: newId,
            itemId: null,
            name: "",
            numero: 1,
            quantity: 0,
            unitId: 0,
            unitPrice: 0,
            vatId: vatListRef.current[0]?.id ?? "0",
            total: 0,
          },
        ],
        numero: groupsRef.current.length + 1 || 1,
        title: "",
      });
      setInvoice(newInvoice);
      getApiContextRef.current.getInterlocutorsList().then((data) => {
        setInterlocutorsList(data?.data);
      });
      getApiContextRef.current.getVatList().then((data) => {
        if (data) {
          setVatList(data?.data);
        }
      });
      getApiContextRef.current.getUnitsList().then((data) => {
        setUnitsList(data.data);
      });
    }
    setLoading(false);
  }, [id, refresh]);

  useEffect(() => {
    let newInvoice = { ...invoiceRef.current };
    if (invoiceRef.current?.groups) {
      let newListTva = [...vatListRef.current];
      for (let k = 0; k < newListTva.length; k++) {
        newListTva[k].value = 0;
      }
      newInvoice.groups.forEach((grp, i) => {
        grp.lines.forEach((line, j) => {
          for (let k = 0; k < newListTva.length; k++) {
            if (newListTva[k].id === line.vatId) {
              let totalAmount =
                parseFloat(line.quantity) * parseFloat(line.unitPrice);
              let lineTva = (totalAmount * newListTva[k].rate) / 100;
              newListTva[k].value = newListTva[k].value + lineTva;
              newListTva[k].value = parseFloat(newListTva[k].value.toFixed(2));
            }
          }
        });
      });
      setNewListTva(newListTva);
    }
  }, [groups, invoice, vatList]);

  useEffect(() => {
    let newInvoice = { ...invoiceRef.current };
    if (invoiceRef.current?.groups) {
      if (!invoiceRef.current.discount) {
        newInvoice.totalHt = totalInvoice;
        setInvoice(newInvoice);
      } else {
        let total = new Decimal(totalInvoice);
        let discount = new Decimal(
          newInvoice.discount ? newInvoice.discount : 0
        );
        const pourcentTotalDiscount = total.mul(discount.div(100)).toNumber();
        const totalDiscount = total
          .minus(pourcentTotalDiscount)
          .toNumber()
          .toFixed(2);
        setTotalHt(totalDiscount);
        newInvoice.totalHt = totalDiscount;
        setInvoice(newInvoice);
      }
    }
  }, [totalInvoice]);

  useEffect(() => {
    if (invoiceRef.current?.totalHt) {
      let newInvoice = { ...invoiceRef.current };
      let amountTotalTva = 0;
      for (let k = 0; k < vatListRef.current.length; k++) {
        amountTotalTva += vatListRef.current[k].value;
      }
      const calc =
        parseFloat(invoiceRef.current.totalHt) + parseFloat(amountTotalTva);
      setTotalTtc(calc);
      setInvoice(newInvoice);
    }
  }, [invoice?.totalHt, vatList]);

  async function createDeposit(e) {
    e.preventDefault();
    const values = {
      idInvoice: id,
      ref: linkDeposit.length + 1,
      depositDate: new Date(),
    };

    try {
      const response = await postApiContext.postDeposit(values);
      response.data.deposit ? toast.success("L'acompte a bien été créé") : toast.error("Une erreur est survenue lors de la création de l'acompte");
      console.log(response.data.deposit.id); // L'ID est bien disponible ici
      return response.data.deposit.id; // Retourne l'ID
    } catch (error) {
      console.log(error);
      throw error; // Facultatif : relancer l'erreur pour la gestion en amont
    }
  }

  const handleSubmitInvoice = async (e, path = "/invoicesList") => {
    e?.preventDefault();

    if (interlocutorId || invoice.userId || userId) {
      console.log("interlocutorId", interlocutorId || invoice.userId || userId);
      const values = {
        quoteId: invoice.quoteId,
        refQuote: invoice.refQuote,
        ref: refRef.current?.value,
        contactId: contactId ? contactId : invoice.contact?.id,
        invoiceDate: dateRef.current ?? invoice.invoiceDate,
        title: titleRef.current?.value,
        interlocutor: interlocutorId ?? invoice.userId ?? userId,
        discount: invoice.discount ? invoice.discount : 0,
        deposit: invoice.deposit,
        comment: comment,
        totEstimateHours: margin.estimatedHours,
        averageHourlyRate: margin.averageHourlyRateHT,
        purchaseCosts: margin.purchaseCostsHT,
        fixedCosts: margin.fixedCosts,
        variableCosts: margin.variableCosts,
        totalHt: invoice.totalHt,
        totalTtc: totalTtc ? totalTtc : invoice.totalTtc,
        groups: groups,
        createMargin: path.includes("margin") ? 1 : 0,
      };

      postApiContext.postInvoice(values, id).then((data) => {
        localStorage.setItem(
          "parentPath",
          "/invoicesList/invoice/" + data?.invoice?.id
        );
        console.log("data", data);
        data?.invoice?.id ? id ? toast.success("La facture a bien été enregistrée") : toast.success("La facture a bien été créée") : toast.error("Une erreur est survenue lors de l'enregistrement de la facture");
        if (data && path.includes("margin")) {
          path = path + (data.margin?.id ?? "");
        }
        navigate(path);
      });
    } else {
      console.log(interlocutorId, invoice.userId);
      alert("Veuillez renseigner un interlocuteur");
    }
  };
  const handleDiscountChange = (e) => {
    if (!e.target.value) {
      setTotalHt(totalInvoice);
    } else if (invoice) {
      let newInvoice = { ...invoice };
      newInvoice.discount = e ? e.target.value : 0;
      let total = new Decimal(totalInvoice);
      let discount = new Decimal(newInvoice.discount ? newInvoice.discount : 0);
      const pourcentTotalDiscount = total.mul(discount.div(100)).toNumber();
      const totalDiscount = total
        .minus(pourcentTotalDiscount)
        .toNumber()
        .toFixed(2);
      setTotalHt(totalDiscount);
      newInvoice.totalHt = totalDiscount;
      console.log("newInvoice", newInvoice);

      setInvoice(newInvoice);
    }
  };

  const handleDiscountBlur = (e) => {
    console.log("e", e.target.value);

    if (e.target.value) {
      let discountPercent = e.target.value;
      let discountPrice = new Decimal(totalInvoice)
        .mul(discountPercent)
        .div(100)
        .toNumber();
      setDiscountPrice(discountPrice);
    }
  };

  const duplicateInvoice = () => {
    handleSubmitInvoice(null, "");
    console.log("----------------submit---------------");
    getApiContext.getInvoice("", id).then((response) => {
      console.log("reponse dup", response);
      const invoice = response?.invoice;
      if (invoice?.id !== undefined) {
        navigate(`/invoicesList/invoice/${invoice?.id}`);
      }
    });
  };

  const handleOpenDelivery = (e) => {
    const invoiceId = id;
    getApiContext
      .getDelivery(null, invoiceId, null)
      .then((response) => {
        console.log("response", response);
        const delivery = response.delivery;
        if (delivery && delivery.id) {
          navigate(`/invoicesList/deliveriesList/delivery/${delivery?.id}`);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const headerButtonsList = {
    dropdown: {
      telecharger: {
        content: "Télécharger",
        handleClick: () => pdfContext.handlePDF("invoice", id, false),
        redir: false,
      },
      imprimer: {
        content: "Imprimer",
        handleClick: () =>  pdfContext.handlePDF("invoice", id, true),
        redir: false,
      },
      envoyer: {
        content: "Envoyer",
        handleClick: () => {
          appContext.setShowMailPop(true);
        },
        redir: false,
      },
      duplicate: {
        handleClick: duplicateInvoice,
        content: "Dupliquer",
        redir: "/invoicesList",
      },
      delivery: {
        handleClick: handleOpenDelivery,
        icon: "faClipboardList",
        content: "Créer un bon de livraison",
        redir: "/deliveriesList",
      },
    },
    mainButton: {
      buttonIcon: "sauvegarder",
      buttonText: id ? "Sauvegarder" : "Créer la facture",
      buttonAction: handleSubmitInvoice,
      className: "hide-text"
    },
  };

  const deleteDeposit = async (id) => {
    try {
      let response = await postApiContext.desactivateDeposit(id);
      if (response.status === "success") {
        let newLinkDeposit = [...linkDeposit];
        let index = newLinkDeposit.findIndex((deposit) => deposit.id === id);
        newLinkDeposit.splice(index, 1);
        setLinkDeposit(newLinkDeposit);
        toast.success("L'acompte a bien été supprimé");
      } else {
        toast.error("Une erreur est survenue lors de la suppression de l'acompte");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la suppression de l'acompte");
    }
  };

  return (
    <>
        <MailPop
          endpoint={"c-invoice-send-email"}
          id={invoice.id}
          itemName={"Facture"}
          title={refRef.current?.value}
        />
      {((!invoice && loading) || pdfContext.loading) && <Loader />}
      <EditingMainHeader
        roleId={roleId}
        headerTitle={"Facture n°"}
        dateRef={dateRef}
        setShowCalendar={setShowCalendar}
        showCalendar={showCalendar}
        destination={"/invoicesList"}
        endPoint={"c-deactive-invoice"}
        id={invoice?.id}
        refRef={refRef}
        defaultRef={invoice?.ref}
        headerButtonsList={headerButtonsList}
        backRedirect={"/invoicesList"}
      />
      <div className="listPage">
        <div className="invoiceContent">
          <InvoiceFormContainer
            setMargin={setMargin}
            handleSubmitInvoice={handleSubmitInvoice}
            setRefresh={setRefresh}
            setTotalInvoice={setTotalInvoice}
            unitsList={unitsList}
            setGroups={setGroups}
            groups={groups}
            formDatas={formDatas}
            setInvoice={setInvoice}
            vatList={vatList}
            interlocutorsList={interlocutorsList}
            invoice={invoice}
            comment={comment}
            setComment={setComment}
          />
          <RecapContainer
            linkDeposit={linkDeposit}
            invoiceId={invoice?.id}
            handleSubmitInvoice={handleSubmitInvoice}
            deleteDeposit={deleteDeposit}
            totalTtc={totalTtc ? totalTtc : invoice?.totalTtc}
            totalHt={totalHt ? totalHt : invoice?.totalHt}
            discount={invoice?.discount}
            discountPrice={discountPrice}
            createDeposit={createDeposit}
            handleDiscountBlur={handleDiscountBlur}
            handleDiscountChange={handleDiscountChange}
            totalInvoice={totalInvoice}
            vatList={vatList}
            setRefresh={setRefresh}
            margin={margin}
            setMargin={setMargin}
          />
        </div>
      </div>
    </>
  );
}

export default Invoice;
