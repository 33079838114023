import BasicInput from "../../../addOns/inputComponents/basicInput";
import MainButton from "../../../addOns/buttonComponent/MainButton";
import { useContext } from "react";
import { AppContext } from "../../../../context/appContext";

export default function ItemPopForm(props) {
  const {
    handleEditItemSubmit,
    dataLists,
    noForm, // Prop optionnelle pour désactiver le wrapper <form>
    articlePopState,
    selectedArticle,
    setSelectedArticle, // On s'appuie sur selectedArticle et son setter pour gérer les valeurs
  } = props;

  const { catalogList, brandList, familyList, subfamilyList, supplierList } =
    dataLists;
  const appContext = useContext(AppContext);

  // Pour le select catalogue : si la valeur vaut "0", on affiche le placeholder
  const selectedCatalog = selectedArticle
    ? selectedArticle?.catalog?.name === "0"
      ? ""
      : selectedArticle?.catalog?.id ?? ""
    : "";

    const { innerWidth } = useContext(AppContext);

  // Contenu interne commun
  const innerContent = (
    <div className="itemPop__container container">
      <BasicInput
        defaultValue={selectedArticle?.name || ""}
        placeholder={
          articlePopState === "display"
            ? "Cet article n'a pas de nom"
            : "Désignation de l'article"
        }
        value={selectedArticle?.name ?? ""}
        onChange={(e) =>
          setSelectedArticle({ ...selectedArticle, name: e.target.value })
        }
        className="itemPop__container_name-input"
        readOnly={articlePopState === "display"}
      />
      <h3>Détails</h3>
      <div
        className="itemPop__container_content content"
        style={
          articlePopState === "display"
            ? {
                flexFlow: "row wrap",
                justifyContent: innerWidth > 930 ? "space-between" : "center",
                flexDirection: "row !important",
                gap: innerWidth < 930 ? "10px" : "30px",
              }
            : { flexDirection: "column" }
        }
      >
        <div className="itemPop__container_content_select-div select">
          <div className="itemPop__container_content_select-div_left">
            <label htmlFor="catalog">Catalogue : </label>
            {articlePopState === "display" ? (
              <BasicInput
                defaultValue={selectedArticle?.catalog?.name || ""}
                value={selectedArticle?.catalog?.name}
                inputStyle={{ margin: "10px 0" }}
                readOnly={true}
                style={{ display: "flex" }}
              />
            ) : (
              <select
                onChange={(e) => {
                  const selectedId = e.target.value;
                  // Chercher l'objet catalogue correspondant dans catalogList
                  const selectedCat =
                    catalogList?.find(
                      (cat) => cat.id.toString() === selectedId
                    ) || null;
                  setSelectedArticle({
                    ...selectedArticle,
                    catalog: selectedCat,
                  });
                }}
                name="catalog"
                id="catalog"
                className="itemPop__container_select"
                value={selectedCatalog}
              >
                <option value="" disabled>
                  Choisir un catalogue
                </option>
                {catalogList &&
                  catalogList.map((element, index) => (
                    <option value={element.id} key={index}>
                      {element.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          {articlePopState !== "display" && (
            <BasicInput
              placeholder={"Créer un catalogue"}
              value={selectedArticle?.catalog || ""}
              onChange={(e) =>
                setSelectedArticle({
                  ...selectedArticle,
                  catalogName: e.target.value,
                })
              }
              inputStyle={{ margin: "10px 0" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}
            />
          )}
        </div>
        <div className="select">
          <div className="itemPop__container_content_select-div_left">
            <label htmlFor="brand">Marque : </label>
            {articlePopState === "display" ? (
              <BasicInput
                defaultValue={selectedArticle?.brand || ""}
                value={selectedArticle.brand}
                inputStyle={{ margin: "10px 0" }}
                readOnly={true}
                style={{ display: "flex" }}
              />
            ) : (
              <select
                onChange={(e) =>
                  setSelectedArticle({
                    ...selectedArticle,
                    brand: e.target.value,
                  })
                }
                name="brand"
                id="brand"
                className="itemPop__container_select"
                value={selectedArticle?.brand || ""}
              >
                <option value="" disabled>
                  Choisir une marque
                </option>
                {brandList?.map((element, index) => (
                  <option value={element.brand} key={index}>
                    {element.brand}
                  </option>
                ))}
              </select>
            )}
          </div>
          {articlePopState !== "display" && (
            <BasicInput
              placeholder={"Créer une marque"}
              value={selectedArticle?.brand || ""}
              onChange={(e) =>
                setSelectedArticle({
                  ...selectedArticle,
                  brand: e.target.value,
                })
              }
              inputStyle={{ margin: "10px 0" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}
            />
          )}
        </div>
        <div className="select">
          <div className="itemPop__container_content_select-div_left">
            <label htmlFor="family">Famille : </label>
            {articlePopState === "display" ? (
              <BasicInput
                defaultValue={selectedArticle?.family || ""}
                value={selectedArticle.family}
                inputStyle={{ margin: "10px 0" }}
                readOnly={true}
                style={{ display: "flex" }}
              />
            ) : (
              <select
                onChange={(e) =>
                  setSelectedArticle({
                    ...selectedArticle,
                    family: e.target.value,
                  })
                }
                name="family"
                id="family"
                className="itemPop__container_select"
                value={selectedArticle?.family || ""}
              >
                <option value="" disabled>
                  Choisir une famille
                </option>
                {familyList?.map((element, index) => (
                  <option value={element.family} key={index}>
                    {element.family}
                  </option>
                ))}
              </select>
            )}
          </div>
          {articlePopState !== "display" && (
            <BasicInput
              placeholder={"Créer une famille"}
              value={selectedArticle?.family || ""}
              onChange={(e) =>
                setSelectedArticle({
                  ...selectedArticle,
                  family: e.target.value,
                })
              }
              inputStyle={{ margin: "10px 0" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}
            />
          )}
        </div>
        <div className="select">
          <div className="itemPop__container_content_select-div_left">
            <label htmlFor="subfamily">Sous famille : </label>
            {articlePopState === "display" ? (
              <BasicInput
                defaultValue={selectedArticle?.subfamily || ""}
                value={selectedArticle.subfamily}
                inputStyle={{ margin: "10px 0" }}
                readOnly={true}
                style={{ display: "flex" }}
              />
            ) : (
              <select
                onChange={(e) =>
                  setSelectedArticle({
                    ...selectedArticle,
                    subfamily: e.target.value,
                  })
                }
                name="subfamily"
                id="subfamily"
                className="itemPop__container_select"
                value={selectedArticle?.subfamily || ""}
              >
                <option value="" disabled>
                  Choisir une sous famille
                </option>
                {subfamilyList?.map((element, index) => (
                  <option value={element.subfamily} key={index}>
                    {element.subfamily}
                  </option>
                ))}
              </select>
            )}
          </div>
          {articlePopState !== "display" && (
            <BasicInput
              placeholder={"Créer une sous famille"}
              value={selectedArticle?.subFamily || ""}
              onChange={(e) =>
                setSelectedArticle({
                  ...selectedArticle,
                  subFamily: e.target.value,
                })
              }
              inputStyle={{ margin: "10px 0" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}
            />
          )}
        </div>
        <div className="select">
          <div className="itemPop__container_content_select-div_left">
            <label htmlFor="supplier">Fournisseur : </label>
            {articlePopState === "display" ? (
              <BasicInput
                defaultValue={selectedArticle?.supplier || ""}
                value={selectedArticle.supplier}
                inputStyle={{ margin: "10px 0" }}
                readOnly={true}
                style={{ display: "flex" }}
              />
            ) : (
              <select
                onChange={(e) =>
                  setSelectedArticle({
                    ...selectedArticle,
                    supplier: e.target.value,
                  })
                }
                name="supplier"
                id="supplier"
                className="itemPop__container_select"
                value={selectedArticle?.supplier || ""}
                style={{ marginBottom: "0"}}
              >
                <option value="" disabled>
                  Choisir un fournisseur
                </option>
                {supplierList?.map((element, index) => (
                  <option value={element.supplier} key={index}>
                    {element.supplier}
                  </option>
                ))}
              </select>
            )}
          </div>
          {articlePopState !== "display" && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
            <MainButton
              buttonText={"Créer un fournisseur"}
              className={"CreateItemSupplierButton"}
              buttonAction={(e) => {
                e.preventDefault();
                appContext.setRedirection("/catalogList");
                appContext.setShowContactPopup(true);
              }}
            />
            </div>
          )}
        </div>
      </div>
      <div className="itemPop__container_second-block">
        <div className="itemPop__container_second-block_col">
          <BasicInput
            defaultValue={selectedArticle?.refDistributor || ""}
            value={selectedArticle?.refDistributor || ""}
            labelName={"ref distributeur"}
            onChange={(e) =>
              setSelectedArticle({
                ...selectedArticle,
                refDistributor: e.target.value,
              })
            }
            className="itemPop__container_second-block_col_input"
          />
          <BasicInput
            defaultValue={selectedArticle?.refMaker || ""}
            value={selectedArticle?.refMaker || ""}
            labelName={"ref fabriquant"}
            onChange={(e) =>
              setSelectedArticle({
                ...selectedArticle,
                refMaker: e.target.value,
              })
            }
            className="itemPop__container_second-block_col_input"
          />
          <BasicInput
            defaultValue={selectedArticle?.package || ""}
            value={selectedArticle?.package || ""}
            labelName={"Conditionnement"}
            onChange={(e) =>
              setSelectedArticle({
                ...selectedArticle,
                package: e.target.value,
              })
            }
            className="itemPop__container_second-block_col_input"
          />
        </div>
        <div className="itemPop__container_second-block_col">
          <BasicInput
            defaultValue={selectedArticle?.netPrice || ""}
            value={selectedArticle?.netPrice || ""}
            labelName={"Prix d'achat"}
            onChange={(e) =>
              setSelectedArticle({
                ...selectedArticle,
                netPrice: e.target.value,
              })
            }
            className="itemPop__container_second-block_col_input"
          />
          <BasicInput
            defaultValue={selectedArticle?.basePrice || ""}
            value={selectedArticle?.basePrice || ""}
            labelName={"Prix de vente"}
            onChange={(e) =>
              setSelectedArticle({
                ...selectedArticle,
                basePrice: e.target.value,
              })
            }
            className="itemPop__container_second-block_col_input"
          />
        </div>
      </div>
    </div>
  );

  if (noForm) {
    return <div className="form">{innerContent}</div>;
  } else {
    return (
      <form action="submit" className="form" onSubmit={handleEditItemSubmit}>
        {innerContent}
      </form>
    );
  }
}
