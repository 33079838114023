import BasicInput from "../../addOns/inputComponents/basicInput";
import { TextareaAutosize } from "@mui/material";
import AddFile from "../../addOns/addFile";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../context/appContext";

export default function CatalogPopForm(props) {
  let { refs, setters } = props;

  let { dateRef, updateDateRef, blocNoteRef, CSVFileRef } = refs;

  const { setCSVFile } = setters;

  const navigate = useNavigate();

  const { innerWidth } = useContext(AppContext);

  return (
    <>
      <div className="container">
        <div className="content">
          {!props.noTitle && <h3>Catégories</h3>}
          <div className={"content-block"}>
            <div className="content" style={{ width: "auto", gap: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px 0 10px 0",
                }}
              >
                <BasicInput
                  refs={dateRef}
                  defaultValue={props.selectedCatalog?.date}
                  placeholder={new Date().toLocaleDateString("fr-FR")}
                  labelName={"Date du catalogue fournisseur"}
                  style={{ display: "flex", gap: "10px", alignItems: innerWidth < 500 ? "start" : "center", flexDirection: innerWidth < 500 ? "column" : "row", }}
                  inputStyle={{ border: "1px solid lightgray" }}
                />
                <BasicInput
                  refs={updateDateRef}
                  defaultValue={""}
                  labelName={"Date de mise à jour CAP BOX"}
                  style={{ display: "flex", gap: "10px", alignItems: innerWidth < 500 ? "start" : "center", flexDirection: innerWidth < 500 ? "column" : "row", }}
                  inputStyle={{ border: "1px solid lightgray" }}
                />
              </div>
              <TextareaAutosize
                ref={blocNoteRef}
                defaultValue={props.selectedCatalog?.comment}
                style={{
                  width: "100%",
                  minHeight: "100px",
                  maxHeight: "300px",
                }}
                placeholder="Bloc note"
              />
            </div>
            <div>
              <div className="dropZoneFileContainer">
                <AddFile
                  key={`fileType-csv-${props.refresh}`} // Force unmount/remount when refresh changes
                  fileType={"csv"}
                  setFile={setCSVFile}
                  receivedFile={CSVFileRef.current}
                  extentionFiles={["csv"]}
                  setRefresh={props.setRefresh}
                  refresh={props.refresh}
                  dropZoneWidth={"100%"}
                  handleDeleteFile={(e) => {
                    e?.preventDefault();
                    setCSVFile(null);
                    CSVFileRef.current = null;
                  }}
                  navigate={() => navigate(`/admin-catalog`)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
