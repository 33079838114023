import { useRef, useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";
import { GetApiContext } from "../../../context/apiCalls/getApiCalls";
import BasicInput from "../../addOns/inputComponents/basicInput";
// import { AppContext } from "../../../context/appContext";
import CatalogPopForm from "./CatalogPopForm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AdminCatalogCreate({
  refresh,
  setRefresh,
  catalogPopState,
}) {
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const id = useParams().id;

  const nameRef = useRef(selectedCatalog?.name ?? "");
  const dateRef = useRef(selectedCatalog?.date ?? "");
  const updateDateRef = useRef(selectedCatalog?.updateDate ?? "");
  const blocNoteRef = useRef(selectedCatalog?.blocNote ?? "");
  const CSVFileRef = useRef(null);

  const navigate = useNavigate();
  let getApiCalls = useContext(GetApiContext);
  let postApiCalls = useContext(PostApiContext);

  useEffect(() => {
    if (!id || id === "new") return;
    getApiCalls.getAdminCatalog(id).then((response) => {
      setSelectedCatalog(response);
    });
  }, [getApiCalls, id]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const values = {
      id: selectedCatalog?.id || null,
      name: nameRef.current.value,
      date: dateRef.current.value || new Date().toLocaleDateString("fr-FR"),
      updateDate: updateDateRef.current.value,
      blocNote: blocNoteRef.current.value,
      csvFile: CSVFileRef.current,
    };

    const importPromise = postApiCalls.postValidCatalog(values);

  const toastId = toast.loading("Importation du fichier CSV en cours...");

  try {
    const response = await importPromise; // Attente de la résolution de la promesse

    if (response.status === "error") {
      toast.update(toastId, {
        render: "Échec de l'importation. Le fichier est probablement trop lourd.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } else {
      toast.update(toastId, {
        render: "Importation réussie !",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    }
      CSVFileRef.current = null;
      setRefresh(!refresh);
      navigate(`/admin-catalog`);
  } catch (error) {
    toast.update(toastId, {
      render: "Échec de l'importation. Le fichier est probablement trop lourd.",
      type: "error",
      isLoading: false,
      autoClose: 5000,
    });
  }
  };

  const refs = {
    dateRef,
    updateDateRef,
    blocNoteRef,
    CSVFileRef,
  };

  //   const validPopContent = {
  //     title: "Suppression",
  //     desc: "Êtes-vous sûr de vouloir supprimer cet article ?",
  //   };

  return (
    <>
      {id && (
        <>
          <div
            className="bluredDiv"
            onClick={() => navigate(`/admin-catalog`)}
          ></div>
          <form
            className="itemPop"
            onSubmit={handleEditSubmit}
            action="submit"
            style={{ padding: "2rem" }}
          >
            <div className="itemPop__header">
              <h2 className="itemPop__header_title">
                {catalogPopState === "create"
                  ? "Créer un catalogue"
                  : catalogPopState === "edit"
                  ? "Modifier le catalogue"
                  : catalogPopState === "display"
                  ? ""
                  : ""}
              </h2>
              <FontAwesomeIcon
                className="cross"
                onClick={() => navigate(`/admin-catalog`)}
                icon={faXmark}
              />
            </div>
            <BasicInput
              placeholder={
                catalogPopState === "display"
                  ? "Ce catalogue n'a pas de nom"
                  : "Désignation du catalogue"
              }
              refs={nameRef}
              defaultValue={selectedCatalog?.name || ""}
              className={"itemPop__name-input"}
              readOnly={catalogPopState === "display"}
              inputStyle={{ border: "1px solid lightgray" }}
            />
            <CatalogPopForm
              refs={refs}
              setters={{
                setCSVFile: (file) => {
                  CSVFileRef.current = file;
                },
              }}
              refresh={refresh}
              setRefresh={setRefresh}
              selectedCatalog={selectedCatalog}
              noTitle={true}
            />
            <button className="button" style={{ margin: "20px 0 0 0" }}>
              Confirmer
            </button>
          </form>
        </>
      )}
    </>
  );
}
