function DeliveryInfos({
    invoiceRef,
    interlocutor,
    subject,
    setSubject,
    societyContact,
    handleInterChange,
    interlocutorSelected,
}) {

    return (
        <div className="editingInfos">
            <div className="editingInfos__titleContainer">
                <h2 className="editingInfos__titleContainer_title">informations</h2>
                <hr className="editingInfos__titleContainer_line"/>
            </div>
            <div className='editingInfos__firstSection'>
                <div className="editingInfos__firstSection_nameContainer">
                    <h3 className="editingInfos__firstSection_nameContainer_title">Référence facture :</h3>
                    <input className="editingInfos__firstSection_nameContainer_input" type="text"
                           defaultValue={invoiceRef || ""} disabled/>
                </div>
                <div className="editingInfos__firstSection_interContainer">
                    <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie par :</h3>
                    <input value={(interlocutor?.first_name || "") + (interlocutor?.first_name && interlocutor?.last_name ? " " : "") + (interlocutor?.last_name || "")} readOnly={true}
                            className="editingInfos__firstSection_interContainer_select" name="interlocutorSelect" placeholder={"Pas d'interlocuteur"}
                            id="interlocutorSelect">
                    </input>
                </div>
            </div>
            <div className={"editingInfos__secondSection"}>


                <div>
                    <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé du bon :</h3>
                    <input className="editingInfos__firstSection_nameContainer_input" type="text"
                           value={subject || ""}
                           onChange={(e) => setSubject(e.target.value)}
                    />
                </div>
                <div>
                    <h3 className="editingInfos__firstSection_interContainer_title">Nom du client :</h3>
                    <input value={(societyContact?.society || "") + (societyContact?.firstName || "") + (societyContact?.firstName && societyContact?.lastName ? " - " : "") + (societyContact?.lastName || "")} readOnly={true}
                            className="editingInfos__firstSection_interContainer_select" name="interlocutorSelect" placeholder={"Pas de client"}
                            id="interlocutorSelect" >
                    </input>
                </div>
            </div>
        </div>

    )
        ;
}

export default DeliveryInfos;
