import React, { useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { useAuthState } from "../../context/authContext";
import {
  faAddressBook,
  faEraser,
  faIdCard,
  faMapLocationDot,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function NewUser() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const adress1Ref = useRef();
  const adress2Ref = useRef();
  const postalRef = useRef();
  const cityRef = useRef();
  const mobilePhoneRef = useRef();
  const landlinePhoneRef = useRef();
  const emailRef = useRef();
  const societyNameRef = useRef();
  const [isClicked, setIsClicked] = useState(false);

  const { roleId } = useAuthState();

  const postApiCalls = useContext(PostApiContext);

  const [formState, setFormState] = useState({
    societyName: "Pas de société",
    firstName: "Pas de prénom",
    lastName: "Pas de nom",
    society: "Pas de société",
    function: "Pas de fonction",
    civility: "Pas de civilité",
    adress1: "Pas d'adresse",
    adress2: "Pas de seconde adresse",
    postal: "Pas de code postal",
    city: "Pas de ville",
    mobilePhone: "Pas de mobile",
    landlinePhone: "Pas de fixe",
    faxPhone: "Pas de fax",
    email: "Pas de mail",
    type: "Pas de type",
    comment: "",
  });

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onValidNewAdmin = async (e) => {
    e?.preventDefault();
    const values = {
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      societyName: societyNameRef.current?.value,
      adress1: adress1Ref.current?.value,
      adress2: adress2Ref.current?.value,
      postal: postalRef.current?.value,
      city: cityRef.current?.value,
      mobilePhone: mobilePhoneRef.current?.value,
      landlinePhone: landlinePhoneRef.current?.value,
      email: emailRef.current?.value,
    };
    console.log("log de value ", values);
    postApiCalls
      .createAdmin(values)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const headerButtonsList = {
    mainButton: {
      buttonIcon: faArrowLeft,
      buttonText: "Créer l'administrateur",
      buttonAction: onValidNewAdmin,
      className: "hide-text-650"
    },
  };

  const deletInputContent = () => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
    setTimeout(() => {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      societyNameRef.current.value = "";
      adress1Ref.current.value = "";
      adress2Ref.current.value = "";
      postalRef.current.value = "";
      cityRef.current.value = "";
      mobilePhoneRef.current.value = "";
      landlinePhoneRef.current.value = "";
      emailRef.current.value = "";
    }, 500);
  };

  return (
    <>
      <EditingMainHeader
        headerTitle="Nouvel administrateur"
        headerButtonsList={headerButtonsList}
        roleId={roleId}
      />
      <div className="listPage">
        <form id="form" onSubmit={(e) => onValidNewAdmin(e)}>
          <div className="contract">
            <div className="allContactContent">
              <div className="contactContent">
                <div>
                  <h3 className="title">INFORMATIONS</h3>
                  <div className="blabla" onClick={deletInputContent}>
                    <p className={isClicked ? "hehe" : "erase"}>Effacer</p>
                    <div
                      className={
                        isClicked ? "icon-container clicked" : "icon-container"
                      }
                    >
                      <FontAwesomeIcon icon={faEraser} className="icon" />
                    </div>
                  </div>
                </div>
                <div className="inputs">
                  <div className="clientInfos">
                    <div className="form-group">
                      <input
                        className="input "
                        name="lastName"
                        onChange={handleChangeValue}
                        required
                        ref={lastNameRef}
                        type="text"
                        placeholder="Nom*"
                      />
                      <input
                        className="input "
                        name="firstName"
                        onChange={handleChangeValue}
                        required
                        ref={firstNameRef}
                        type="text"
                        placeholder="Prenom*"
                      />
                      <input
                        className="input "
                        name="societyName"
                        onChange={handleChangeValue}
                        required
                        ref={societyNameRef}
                        type="text"
                        placeholder="Société*"
                      />
                      <input
                        className="input "
                        type="text"
                        name="adress1"
                        required
                        onChange={handleChangeValue}
                        ref={adress1Ref}
                        placeholder="Adresse*"
                      />
                      <input
                        className="input "
                        type="text"
                        name="adress2"
                        onChange={handleChangeValue}
                        ref={adress2Ref}
                        placeholder="Adresse 2"
                      />
                      <input
                        className="input "
                        type="text"
                        name="postal"
                        required
                        onChange={handleChangeValue}
                        ref={postalRef}
                        placeholder="Code postal*"
                      />
                      <input
                        className="input "
                        type="text"
                        name="city"
                        required
                        onChange={handleChangeValue}
                        ref={cityRef}
                        placeholder="Ville*"
                      />
                      <input
                        className="input"
                        type="text"
                        name="mobilePhone"
                        onChange={handleChangeValue}
                        ref={mobilePhoneRef}
                        placeholder="Téléphone portable"
                      />
                      <input
                        className="input"
                        type="text"
                        name="landlinePhone"
                        onChange={handleChangeValue}
                        ref={landlinePhoneRef}
                        placeholder="Téléphone fixe"
                      />
                      <input
                        className="input"
                        type="email"
                        name="email"
                        onChange={handleChangeValue}
                        ref={emailRef}
                        placeholder="Email"
                      />

                      <p className="Infos">
                        *Informations obligatoires{" "}
                        <span className="underline"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contactContentResum">
                <p className="title">Apercu du profil :</p>
                <div className="profileVueContent">
                  <FontAwesomeIcon className="descIcons" icon={faIdCard} />
                  <div className="infoGroup">
                    <p
                      className={firstNameRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Nom:</span>&nbsp;{" "}
                      {lastNameRef?.current?.value
                        ? lastNameRef?.current?.value
                        : formState.lastName}
                    </p>
                    <p
                      className={lastNameRef?.current?.value ? "p" : "Nothing"}
                    >
                      <span className="profileVueSpan">Prenom:</span>&nbsp;{" "}
                      {firstNameRef?.current?.value
                        ? firstNameRef?.current?.value
                        : formState.firstName}
                    </p>
                    <p
                      className={
                        societyNameRef?.current?.value ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Société:</span>&nbsp;{" "}
                      {societyNameRef?.current?.value
                        ? societyNameRef?.current?.value
                        : formState.societyName}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="descIcons"
                    icon={faMapLocationDot}
                  />
                  <div className="infoGroup">
                    <p className={adress1Ref?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Adresse:</span>&nbsp;{" "}
                      {adress1Ref?.current?.value
                        ? adress1Ref?.current?.value
                        : formState.adress1}{" "}
                    </p>
                    <p className={adress2Ref?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Adresse 2:</span>&nbsp;{" "}
                      {adress1Ref?.current?.value
                        ? adress2Ref?.current?.value
                        : formState.adress2}{" "}
                    </p>
                    <p className={cityRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Ville:</span>&nbsp;{" "}
                      {cityRef?.current?.value
                        ? cityRef?.current?.value
                        : formState.city}
                    </p>
                    <p className={postalRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Code postal:</span>
                      &nbsp;{" "}
                      {postalRef?.current?.value
                        ? postalRef?.current?.value
                        : formState.postal}{" "}
                    </p>
                  </div>
                  <FontAwesomeIcon className="descIcons" icon={faAddressBook} />
                  <div className="infoGroup">
                    <p
                      className={
                        mobilePhoneRef?.current?.value ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Mobile:</span>&nbsp;{" "}
                      {mobilePhoneRef?.current?.value
                        ? mobilePhoneRef?.current?.value
                        : formState.mobilePhone}
                    </p>
                    <p
                      className={
                        landlinePhoneRef?.current?.value ? "p" : "Nothing"
                      }
                    >
                      <span className="profileVueSpan">Fixe:</span>&nbsp;{" "}
                      {landlinePhoneRef?.current?.value
                        ? landlinePhoneRef?.current?.value
                        : formState.landlinePhone}
                    </p>
                    <p className={emailRef?.current?.value ? "p" : "Nothing"}>
                      <span className="profileVueSpan">Mail:</span>&nbsp;{" "}
                      {emailRef?.current?.value
                        ? emailRef?.current?.value
                        : formState.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
