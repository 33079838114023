import { useContext, useRef, useState } from "react";
import DeletButton from "../../../addOns/deletButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MainButton from "../../../addOns/buttonComponent/MainButton";
import BasicInput from "../../../addOns/inputComponents/basicInput";
import { PostApiContext } from "../../../../context/apiCalls/postApiCalls";
import { toast } from "react-toastify";

export default function CatalogCRUDPop({
  catalogs,
  action,
  setShowCatalogCRUD,
  refresh,
  setRefresh,
}) {
  const catalogNewName = useRef();
  const [catalogSelected, setCatalogSelected] = useState("selector");
  const [selectorError, setSelectorError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const postApiContext = useContext(PostApiContext);

  const handleEditSubmit = (e) => {
    e?.preventDefault();
    const isSelectorError = catalogSelected === "selector";
    const isNameError = !catalogNewName.current.value;

    setSelectorError(isSelectorError);
    setNameError(isNameError);

    if ((isSelectorError && action === "edit") || isNameError) {
      return;
    }

    const values = {
      id: action === "edit" ? catalogSelected || null : null,
      name: catalogNewName.current.value,
    };

    postApiContext
      .postPersonalCatalog(values)
      .then((response) => {
        response.status === "success" ? values.id ? toast.success("Le catalogue a bien été enregistré") : toast.success("Le catalogue a bien été créé") : toast.error("Erreur lors de la création du catalogue");
        // console.log("response post deposit", response);
        setShowCatalogCRUD(false);
        setRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validPopContent = {
    title: "Suppression",
    desc: "Voulez-vous vraiment supprimer ce catalogue ?",
  };

  return (
    <>
      <div
        onClick={() => setShowCatalogCRUD(false)}
        className="bluredDiv"
      ></div>
      <section className="itemPop" style={{ padding: "2rem" }}>
        <div className="popHeader">
          <div className="options">
            <h3>
              {action === "create"
                ? "Créer un catalogue"
                : action === "edit"
                ? "Modifier un catalogue"
                : ""}
            </h3>
          </div>
          <FontAwesomeIcon
            className="cross"
            onClick={() => setShowCatalogCRUD(false)}
            icon={faXmark}
          />
        </div>
        {action === "create" ? (
          <div className="articleContent" style={{ marginTop: "2rem" }}>
            <div className={"content"}>
              <BasicInput
                className="articleInput"
                placeholder={"Nom du catalogue"}
                refs={catalogNewName}
              ></BasicInput>
              <MainButton
                buttonText={"Créer le catalogue"}
                buttonAction={() => handleEditSubmit()}
                buttonIcon="sauvegarder"
                className={"hide-text-650"}
              />
            </div>
          </div>
        ) : action === "edit" ? (
          <div className="articleContent">
            <div className={"content"} style={{ marginTop: "2rem" }}>
              <div className={"catalogSelector"} style={{ width: "100%" }}>
                <select
                  value={catalogSelected}
                  onChange={(e) => {
                    setCatalogSelected(e.target.value);
                    setSelectorError(false);
                  }}
                  style={{ width: "100%" }}
                >
                  <option value={"selector"}>Choisir un catalogue</option>
                  {catalogs &&
                    catalogs.map((catalog, index) => {
                      return (
                        <option
                          key={index}
                          className="catalogueItem"
                          value={catalog.id}
                        >
                          {catalog.name}
                        </option>
                      );
                    })}
                </select>
                {selectorError && (
                  <p className={"error-message"}>
                    Veuillez choisir un catalogue
                  </p>
                )}
              </div>
              <div className={"catalogSelector"} style={{ width: "100%" }}>
                <BasicInput
                  className="articleInput"
                  placeholder={"Nouveau nom du catalogue"}
                  refs={catalogNewName}
                  inputStyle={{ boxSizing: "border-box", width: "100%", height: "55px !important" }} 
                ></BasicInput>
                {nameError && (
                  <p className={"error-message"}>Veuillez renseigner un nom</p>
                )}
              </div>
              <DeletButton
                className={"articleDelete"}
                id={catalogSelected}
                url={"c-delete-personal-catalog"}
                refresh={refresh}
                setRefresh={setRefresh}
                validPopContent={validPopContent}
              />
              <MainButton
                buttonText={"Enregistrer les modifications"}
                buttonAction={() => handleEditSubmit()}
                className={"hide-text-650"}
                buttonIcon="sauvegarder"
              />
            </div>
          </div>
        ) : (
          () => console.log("error")
        )}
      </section>
    </>
  );
}
