import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/layouts/layouts";
import { contactPaths } from "./paths/contactPaths";
import { quotesPath } from "./paths/quotePaths";
import { invoicePaths } from "./paths/invoicePaths";
import { marginPaths } from "./paths/marginPaths";
import { orderPaths } from "./paths/orderPaths";
import { creditPaths } from "./paths/creditPaths";
import { depositPaths } from "./paths/depositPaths";
import { catalogPaths } from "./paths/catalogPaths";
import { settingsPaths } from "./paths/settings";
import { supPaths } from "./paths/supPaths";
import { adminPaths } from "./paths/adminPaths";
import Authentification from "./routes/Authentification";
import ForgotPassword from "./routes/connexion/ForgotPassword";
import { ToastContainer } from "react-toastify";
function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Authentification />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/",
      element: <Layout />,
      children: invoicePaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: quotesPath,
    },
    {
      path: "/",
      element: <Layout />,
      children: contactPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: marginPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: orderPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: creditPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: depositPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: catalogPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: settingsPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: supPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: adminPaths,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        className="toast-container"
      />
    </>
  );
}

export default App;
