import { useContext, useState, useCallback, useEffect, useRef } from "react";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import Loader from "../../components/addOns/loader";
import MainHeader from "../../components/addOns/headers/MainHeader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import { TableContainer } from "@mui/material";
import Paging from "../../components/addOns/Paging";
import supprimerRouge from "../../assets/images/icons/Picto/supprimer-rouge.svg";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import AdminCatalogCreate from "../../components/admin/catalog/AdminCatalogCreate";
import ValidPop from "../../components/addOns/popComponents/validPop";
import { useNavigate } from "react-router-dom";

export default function AdminCatalogs() {
  const [catalogsList, setCatalogsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [nbPages, setNbPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showValidPop, setShowValidPop] = useState(false);
  const [catalogPopState, setCatalogPopState] = useState("create");
  const [catalogToEdit, setCatalogToEdit] = useState(null);

  const catalogNameRef = useRef(null);

  const navigate = useNavigate();
  const postApiCalls = useContext(PostApiContext);

  const onValidFilter = useCallback(
    async (e) => {
      e?.preventDefault();
      setLoading(true);

      const values = {
        name: catalogNameRef.current.value,
      };

      try {
        const response = await postApiCalls.adminCatalogsList(
          values,
          currentPage,
          perPage
        );
        setCatalogsList(response.catalogsList);
        setNbPages(response.nbPages);
      } catch (error) {
        // console.log(error);
      }

      setLoading(false);
      setRefresh(false);
    },
    //eslint-disable-next-line
    [postApiCalls, currentPage, perPage, refresh]
  );

  useEffect(() => {
    onValidFilter();
  }, [onValidFilter]);

  const searchContentObject = {
    ContentName: "Article",
    Content: [
      {
        name: "Nom du catalogue",
        title: "Nom du catalogue",
        type: "text",
        ref: catalogNameRef,
      },
    ],
  };

  const refreshForm = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    catalogNameRef.current.value = "";
    setRefresh(true);
  };

  const headerButtons = [
    {
      title: "Nouveau catalogue",
      className: "hide-text-650",
      icon: faFileMedical,
      action: () => {
        setCatalogPopState("create");
        setCatalogToEdit(null);
        navigate("/admin-catalog/new");
      },
      showByRoleId: false,
    },
  ];

  const handleCheckboxChange = (id) => {
    if (selectedCatalogs.includes(id)) {
      setSelectedCatalogs(selectedCatalogs.filter((item) => item !== id));
    } else {
      setSelectedCatalogs([...selectedCatalogs, id]);
    }
  };

  useEffect(() => {
    setShowDeleteButton(selectedCatalogs.length > 0);
  }, [selectedCatalogs]);

  const handleDeleteCatalogs = async (selectedCatalogs) => {
    setLoading(true);
    try {
      const values = {
        catalogsList: selectedCatalogs,
      };
      await postApiCalls.adminDeleteCatalogs(values);
      setRefresh(true);
      setSelectedCatalogs([]);
      setShowValidPop(false);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: () => setShowValidPop(false),
    },
    {
      text: "Continuer",
      className: "continueButton",
      action: () => handleDeleteCatalogs(selectedCatalogs),
    },
  ];

  return (
    <>
      {showValidPop && (
        <ValidPop
          showValidPop={showValidPop}
          setShowValidPop={setShowValidPop}
          title={"Suppression"}
          desc={
            "Voulez-vous vraiment supprimer ce catalogue et tous ses articles ?"
          }
          buttonsObject={buttonsObject}
          bgcAction={() => setShowValidPop(false)}
        />
      )}
      <AdminCatalogCreate
        refresh={refresh}
        setRefresh={setRefresh}
        selectedCatalog={catalogToEdit}
        catalogPopState={catalogPopState}
      />
      {loading && <Loader />}
      <MainHeader
        headerTitle="Articles"
        objectButtons={headerButtons}
        // roleId={roleId}
      />
      <div className="listPage">
        <SearchComponent
          contentObject={searchContentObject}
          onSearchSubmit={onValidFilter}
          title={"Rechercher"}
          defaultShowFilter={true}
          refresh={refreshForm}
        />
        {showDeleteButton && (
          <button
            className="admin-articles__delete-button"
            onClick={() => setShowValidPop(true)}
          >
            <img src={supprimerRouge} alt={"poubelle"} />
            <p>Supprimer la sélection</p>
          </button>
        )}
        <TableContainer
          className="admin-connections"
          style={{ maxHeight: "80vh", overflowY: "auto" }}
        >
          <table className="admin-connections__table">
            <thead className="admin-connections__table_head">
              <tr>
                <th></th>
                <th>Nom du catalogue</th>
                <th>Date du catalogue fournisseur</th>
                <th>Date de mise à jour</th>
                <th>Nombre de références</th>
                <th>Bloc notes</th>
              </tr>
            </thead>
            <tbody className="admin-connections__table_body">
              {catalogsList?.map((catalog) => (
                <tr
                  key={catalog.id}
                  className="admin-connections__table_body_row"
                  onClick={() => {
                    setCatalogPopState("edit");
                    navigate(`/admin-catalog/${catalog.id}`);
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedCatalogs.includes(catalog.id)}
                      onChange={() => handleCheckboxChange(catalog.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td className="lightFont">{catalog.name}</td>
                  <td className="lightFont">{catalog.date}</td>
                  <td className="lightFont">{""}</td>
                  <td className="lightFont">{catalog.ref}</td>
                  <td className="lightFont">{catalog.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <Paging
        perPage={perPage}
        setPerPage={setPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        nbPages={nbPages}
        perPageOptions={[10, 50, 100]}
      />
    </>
  );
}
