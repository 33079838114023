import React, { useContext, useEffect, useState } from "react";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
  const getApiContext = useContext(GetApiContext);

  const [lastActivedAccounts, setLastActivedAccounts] = useState([]);
  const [lastModifiedCatalogs, setLastModifiedCatalogs] = useState([]);
  const [lastConnections, setLastConnections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getApiContext
      .getAdminDashboard()
      .then((response) => {
        setLastActivedAccounts(response.lastActivedAccounts);
        setLastModifiedCatalogs(response.lastUpdatedCatalogs);
        setLastConnections(response.lastConnectedUsers);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [getApiContext]);

  return (
    <>
      <EditingMainHeader headerTitle="Tableau de bord" headerButtonsList={{}} />
      <div className="adminDashboard listPage">
        <div className="adminDashboard__container">
          <div className="adminDashboard__container_tile">
            <h3 className="adminDashboard__container_tile_title">
              5 derniers comptes activés
            </h3>
            <ul className="adminDashboard__container_tile_table">
              {lastActivedAccounts.map((account, index) => (
                <li
                  key={index}
                  className="adminDashboard__container_tile_table_row"
                >
                  <span
                    className="adminDashboard__container_tile_table_row_cell"
                    onClick={() => navigate(`/admin-customer/${account.id}`)}
                  >
                    {account.first_name} {account.last_name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="adminDashboard__container_tile">
            <h3 className="adminDashboard__container_tile_title">
              5 derniers catalogues modifiés
            </h3>
            <ul>
              {lastModifiedCatalogs?.map((catalog, index) => (
                <li
                  key={index}
                  className="adminDashboard__container_tile_table_row"
                >
                  <span
                    className="adminDashboard__container_tile_table_row_cell"
                    onClick={() => navigate(`/admin-catalog/${catalog.id}`)}
                  >
                    {catalog.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="adminDashboard__container_tile">
            <h3 className="adminDashboard__container_tile_title">
              5 dernières connexions
            </h3>
            <ul>
              {lastConnections?.map((connection, index) => (
                <li
                  key={index}
                  className="adminDashboard__container_tile_table_row"
                >
                  <span
                    className="adminDashboard__container_tile_table_row_cell"
                    onClick={() =>
                      navigate(`/admin-customer/${connection.user_id}`)
                    }
                  >
                    {connection.u_first_name} {connection.u_last_name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
