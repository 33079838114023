import { useEffect, useState, useRef } from "react";
import "../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DeletButton from "../../addOns/deletButton";
import article_kart from "../../../assets/images/icons/Picto/Achat.svg";
import eraser from "../../../assets/images/picto/eraser_picto.svg";
import refresh from "../../../assets/images/picto/refresh.svg";

function CreditLine(props) {
  const [designation, setDesignation] = useState(
    props?.designation || props?.name
  );
  const [quantity, setQuantity] = useState(
    parseFloat(props?.line.quantity).toFixed(2)
  );
  const [unitPrice, setUnitPrice] = useState(
    parseFloat(props?.line.unitPrice).toFixed(2)
  );
  const [totalHT, setTotalHT] = useState(
    props.line.unitPrice * props.line.quantity || 0
  );

  const firstRender = useRef(true);

  useEffect(() => {
    // Synchroniser les props.line avec les états locaux à chaque mise à jour des props.line
    if (!firstRender.current) {
      setDesignation(props.line.name);
      setQuantity(parseFloat(props.line.quantity).toFixed(2));
      setUnitPrice(parseFloat(props.line.unitPrice).toFixed(2));
      setTotalHT((props.line.quantity * props.line.unitPrice).toFixed(2));
    } else {
      firstRender.current = false;
    }
  }, [props.line]);

  const onChange = (e) => {
    let value = e.target.value;
    let updatedLine = { ...props.line };

    switch (e.target.name) {
      case "quantity":
        setQuantity(value);
        updatedLine.quantity = parseFloat(value).toFixed(2);
        break;
      case "unitPrice":
        setUnitPrice(value);
        updatedLine.unitPrice = parseFloat(value).toFixed(2);
        break;
      case "tva":
        updatedLine.vatId = parseInt(value);
        break;
      case "unit":
        updatedLine.unitId = parseInt(value);
        break;
      case "designation":
        setDesignation(value);
        updatedLine.name = value;
        break;
      default:
        break;
    }

    const newTotalHT = (updatedLine.quantity * updatedLine.unitPrice).toFixed(
      2
    );
    setTotalHT(newTotalHT);
    updatedLine.totalHT = newTotalHT;

    props.updateGroup(updatedLine);
  };

  const formatInput = (e) => {
    let value = e.target.value.replace(",", ".");
    let updatedLine = { ...props.line };

    switch (e.target.name) {
      case "quantity":
        const formattedQuantity = parseFloat(value).toFixed(2);
        setQuantity(isNaN(formattedQuantity) ? "1.00" : formattedQuantity);
        updatedLine.quantity = isNaN(formattedQuantity)
          ? "1.00"
          : formattedQuantity;
        break;
      case "unitPrice":
        const formattedPrice = parseFloat(value).toFixed(2);
        setUnitPrice(isNaN(formattedPrice) ? "0.00" : formattedPrice);
        updatedLine.unitPrice = isNaN(formattedPrice) ? "0.00" : formattedPrice;
        break;
      case "designation":
        setDesignation(value);
        updatedLine.name = value;
        break;
      default:
        break;
    }

    const newTotalHT = (updatedLine.quantity * updatedLine.unitPrice).toFixed(
      2
    );
    setTotalHT(newTotalHT);
    updatedLine.totalHT = newTotalHT;

    props.updateGroup(updatedLine);
  };

  const validPopContent = {
    title: "Confirmer la suppression",
    desc: `Êtes-vous sûr de vouloir supprimer cette ligne ?`,
    toasterMessage: "La ligne a été supprimée avec succès",
  };

  const resetLine = () => {
    const newLine = {
      ...props.line,
      quantity: 1,
      unitPrice: 0,
      unitId: 1,
      vatId: 0,
      name: "",
      itemId: null,
    };

    props.updateGroup(newLine); // Mise à jour en une seule fois
    setQuantity(1);
    setUnitPrice(0);
    setTotalHT(0);
  };

  return (
    <div className="invoiceLine" id={props.line.numero}>
      <div className="invoiceLine__leftContent">
        <div className="invoiceLine__leftContent_header">
          <div className="invoiceLine__leftContent_header_leftPart">
            <p className="invoiceLine__leftContent_header_leftPart_number">
              {props.line.numero}
            </p>
            {props.line?.itemId ? (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button change-article-button"
                onClick={() => {
                  props.openCatalogues(props.index, props.grpIndex);
                }}
              >
                <img src={refresh} alt="" />
                Changer d'article
              </button>
            ) : (
              <button
                className="invoiceLine__leftContent_header_leftPart_article-button add-article-button"
                onClick={() => {
                  props.openCatalogues(props.index, props.grpIndex);
                }}
              >
                <img src={article_kart} alt="article_kart" />
                Ajouter un article
              </button>
            )}
          </div>
          <div className="invoiceLine__leftContent_header_arrowContainer">
            <FontAwesomeIcon
              onClick={() => props.changeOrderLine(props.index, "up")}
              style={
                props.line.numero <= 1
                  ? { opacity: 0.3, cursor: "not-allowed" }
                  : {}
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronUp}
            />
            <FontAwesomeIcon
              onClick={() => props.changeOrderLine(props.index, "down")}
              style={
                props.line.numero === props.linesLength.toString()
                  ? {
                      opacity: 0.3,
                      cursor: "not-allowed",
                    }
                  : {}
              }
              className="invoiceLine__leftContent_header_arrowContainer_arrow"
              icon={faChevronDown}
            />
            <DeletButton
              id={props.line.id}
              className={
                "invoiceLine__leftContent_header_arrowContainer_deleteButton"
              }
              url={"c-delete-credit-line"}
              validPopContent={validPopContent}
              deleteItemByNotId={props.removeItem}
              color={"red"}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
            />
          </div>
        </div>

        <textarea
          value={designation || ""}
          className="invoiceLine__leftContent_textarea"
          name="designation"
          placeholder="Description de l'article"
          title="Désignation"
          onChange={onChange}
        />
        <div className="invoiceLine__leftContent_erasedContainer">
          <button
            className="invoiceLine__leftContent_erasedContainer_button"
            onClick={() => {
              resetLine();
            }}
          >
            <img src={eraser} alt="eraser" />
            Effacer l'article
          </button>
        </div>
      </div>

      <div className="invoiceLine__rightContent">
        <div className="invoiceLine__rightContent_inputContainer">
          <label className="invoiceLine__rightContent_inputContainer_label">
            Unité
          </label>
          <select
            name="unit"
            value={props.line.unitId || 1}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {props.listUnit.map((unit, i) => (
              <option key={i} value={unit.id}>
                {unit.unit_name}
              </option>
            ))}
          </select>
        </div>

        <div className="invoiceLine__rightContent_inputContainer">
          <label className="invoiceLine__rightContent_inputContainer_label">
            Quantité
          </label>
          <input
            type="number"
            placeholder="Quantité"
            name="quantity"
            title="Quantité"
            value={quantity}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          />
        </div>

        <div className="invoiceLine__rightContent_inputContainer">
          <label className="invoiceLine__rightContent_inputContainer_label">
            Prix HT
          </label>
          <input
            type="number"
            placeholder="Prix HT"
            name="unitPrice"
            title="Prix HT"
            value={unitPrice}
            onChange={onChange}
            onBlur={formatInput}
            className="invoiceLine__rightContent_inputContainer_input"
          />
        </div>

        <div className="invoiceLine__rightContent_inputContainer">
          <label className="invoiceLine__rightContent_inputContainer_label">
            TVA
          </label>
          <select
            name="tva"
            value={props.line.vatId || ""}
            onChange={onChange}
            className="invoiceLine__rightContent_inputContainer_input"
          >
            {props.listTva.map((tva, i) => (
              <option key={i} value={tva.id}>
                {tva.rate} %
              </option>
            ))}
          </select>
        </div>

        <div className="invoiceLine__rightContent_totalContainer">
          <p className="invoiceLine__rightContent_totalContainer_total">
            Montant HT : &nbsp;<span>{totalHT || 0.0}&nbsp;€</span>
          </p>
          <p className="invoiceLine__rightContent_totalContainer_total line-discount-total">
            Montant après remise : &nbsp;<span> {totalHT || 0.0}&nbsp;€</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CreditLine;
