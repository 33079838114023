import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/addOns/loader";
import Paging from "../../components/addOns/Paging";
import TotalAmonts from "../../components/addOns/TotalAmonts";
import {
  faCircleCheck,
  faCopy,
  faDownload,
  faFileMedical,
  faPaperPlane,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import { GetApiContext } from "../../context/apiCalls/getApiCalls";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { PdfContext } from "../../context/PdfContext";
import Card from "../../components/addOns/card";
import ValidPop from "../../components/addOns/popComponents/validPop";
import { AppContext } from "../../context/appContext";
import MailPop from "../../components/addOns/MailPop";

function OrderList() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const orderFilterRef = useRef();
  const [dateRange, setDateRange] = useState([]);
  const societyRef = useRef();

  const [orderList, setOrderList] = useState();
  const [interlocutors, setInterlocutors] = useState();
  const [orderState, setOrderState] = useState("all");
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("datedown");
  const [interloSearch, setInterloSearch] = useState();
  const [listRefresh, setListRefresh] = useState(false);
  const [showValidPop, setShowValidPop] = useState(false);

  const [orderId, setOrderId] = useState();
  const [nbPages, setNbPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [totalTTC, setTotalTTC] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [mailData, setMailData] = useState({ id: null, title: "" });

  const getApiContext = useContext(GetApiContext);
  const postApiContext = useContext(PostApiContext);
  const pdfContext = useContext(PdfContext);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const stateObject = [
    { valid: "all", name: "toutes les dépenses" },
    { valid: "valid", name: "vos dépenses validées" },
    { valid: "in-progress", name: "vos dépenses en cours" },
    { valid: "canceled", name: "vos dépenses annulées" },
  ];

  const contentObject = {
    ContentName: "Dépense",
    Content: [
      { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
      { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
      {
        name: "refDepense",
        title: "Référence Dépense",
        type: "text",
        ref: orderFilterRef,
      },
      {
        name: "dateRange",
        title: "Date de création",
        type: "dateRangePicker",
        ref: dateRange,
      },
      { name: "society", title: "Société", type: "text", ref: societyRef },
    ],
  };

  const sortOptions = {
    options: [
      { value: "datedown", content: "Date décroissante" },
      { value: "dateup", content: "Date croissante" },
      { value: "refup", content: "Références croissantes" },
      { value: "refdown", content: "Références décroissantes" },
    ],
  };

  const onSearchSelectChange = (e) => {
    console.log(e.target.value);
    setInterloSearch(e.target.value);
  };    

  const onValidFilter = useCallback(
    async (e) => {
      e?.preventDefault();
      setOrderList(null);
      setLoading(true);

      const values = {
        lastName: lastNameRef?.current?.value,
        firstName: firstNameRef?.current?.value,
        society: societyRef?.current?.value,
        orderRef: orderFilterRef?.current?.value,
        interlocuteur: interloSearch,
        startDate: dateRange[0]
          ? dateRange[0].toLocaleDateString().split("T")[0]
          : null,
        endDate: dateRange[1]
          ? dateRange[1].toLocaleDateString().split("T")[0]
          : null,
        sort: sort,
        state: orderState,
      };

      try {
        const response = await postApiContext.postOrderFilterList(values, currentPage, perPage);
        // console.log(response);
        setOrderList(response.orderFormsList);
        setNbPages(response.nbPages);
        setPerPage(response.perPage);
        setCurrentPage(response.currentPage);
        setInterlocutors(response.listInterlocutors);
        setTotalHT(response.totalAmonts.total_ht);
        setTotalTTC(response.totalAmonts.total_ttc);
        setTotalVAT(response.totalAmonts.total_vat);
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [interloSearch, dateRange, sort, orderState, postApiContext, currentPage, perPage]
  );

  useEffect(() => {
    if (isFirstLoad) {
      onValidFilter();
      setIsFirstLoad(false);
    } else if (listRefresh) {
      onValidFilter();
      setListRefresh(false);
    }
  }, [onValidFilter, listRefresh, isFirstLoad]);

  const openEmptyOrder = () => {
    navigate("/ordersList/order");
  };

  const handleOpenValidItem = (id) => {
    setShowValidPop(true);
    setOrderId(id);
  };

  const handleOpenOrder = () => {
    navigate(`/ordersList/order/${orderId}`);
  };

  const buttonsObject = [
    {
      text: "Retour",
      className: "backButton",
      action: (e) => setShowValidPop(false),
    },
    { text: "Continuer", className: "continueButton", action: handleOpenOrder },
  ];

  const headerButtons = [
    {
      title: "Nouvelle dépense",
      className: "hide-text-650",
      icon: faFileMedical,
      action: openEmptyOrder,
      showByRoleId: true,
    },
  ];

  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setDateRange([]);
    setInterloSearch(null);
    setListRefresh(true);
  };

  const duplicateOrder = (e) => {
    const dupId = e.target.parentNode.parentNode.id;
    getApiContext.getOrder("", dupId).then((response) => {
      if (response) {
        const order = response.order;
        navigate(`/ordersList/order/${order?.id}`);
      }
    });
  };

  return (
    <>
      {(loading || pdfContext.loading) && <Loader />}
      {showValidPop && (
        <ValidPop
          bgcAction={(e) => setShowValidPop(false)}
          showValidPop={showValidPop}
          title={"Continuer vers cette dépense ?"}
          desc={"Cette dépense est validé, voulez-vous vraiment la modifier ?"}
          buttonsObject={buttonsObject}
        />
      )}
      <MailPop
        endpoint={"c-order-send-email"}
        id={mailData.id}
        itemName={"Depense"}
        title={mailData.title}
       />
      <MainHeader headerTitle={"DÉPENSES"} objectButtons={headerButtons} />
      <div className="listPage">
        <div className="listPage__filterContainer">
          <StateFilter
            setState={setOrderState}
            stateObject={stateObject}
            state={orderState}
            setListRefresh={setListRefresh}
          />
          <SearchComponent
            refresh={refresh}
            onSearchSelectChange={onSearchSelectChange}
            onSearchSubmit={onValidFilter}
            contentObject={contentObject}
            interlocutor={interlocutors}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setListRefresh={setListRefresh}
          />
          <SortByFilter
            setSort={setSort}
            sortOptions={sortOptions}
            setListRefresh={setListRefresh}
          />
        </div>
        <div className="cards-list">
          {orderList &&
            orderList.map((order, k) => {
              const element = {
                id: order.order_form_id,
                valid: order.valid,
                state: order.state,
                date: order.order_date,
                ref: order.ref,
                subject: order.subject,
                lastName: order.LastName || "",
                firstName: order.FirstName || "",
                society: order.society,
                totalHt: order.total_ht,
                totalTtc: order.total_ttc,
                directionPath: "/ordersList/order/",
                initials: order.initials,
              };
              return (
                <Card
                  key={k}
                  tableName="order_form"
                  setListRefresh={setListRefresh}
                  deleteEndPoint="c-deactive-order"
                  element={element}
                  otherButtonList={[
                    {
                      title: "Télécharger",
                      icon: faDownload,
                      className: "edit",
                      action: () =>
                        pdfContext.handlePDF("order", order.order_form_id, false),
                    },
                    {
                      title: "Envoyer",
                      icon: faPaperPlane,
                      className: "delete",
                      action: (e) => {
                        setMailData({ id: order.order_form_id, title: order.subject });
                        appContext.setShowMailPop(true) }},
                    {
                      title: "Imprimer",
                      icon: faPrint,
                      className: "edit",
                      action: () =>
                        pdfContext.handlePDF("order", order.order_form_id, true),
                    },
                    {
                      title: "Dupliquer",
                      icon: faCopy,
                      className: "delete",
                      action: (e) => duplicateOrder(e),
                    },
                  ]}
                  stateButtonsList={[
                    {
                      title: "Valider",
                      icon: faCircleCheck,
                      color: "stateGreen",
                      name: "valid",
                    },
                    {
                      title: "En cours",
                      icon: faCircleCheck,
                      color: "stateOrange",
                      name: "in-progress",
                    },
                    {
                      title: "Annulé",
                      icon: faCircleCheck,
                      color: "stateRed",
                      name: "canceled",
                    },
                  ]}
                  handleOpenValidItem={handleOpenValidItem}
                  roleVerif={true}
                  elementTitle={"DÉPENSE N°"}
                />
                /*<OrderCard
                                key={k}
                                orderId={order.order_form_id}
                                refOrder={order.ref}
                                totalHT={order.total_ht}
                                totalTTC={order.total_ttc}
                                orderDate={order.order_date}
                                contactId={order.societyContactId}
                                contactLastName={order.LastName}
                                contactFirstName={order.FirstName}
                                societyContact={order.society}
                                state={order.state}
                            />*/
              );
            })}
        </div>
        <TotalAmonts
          totalTTC={totalTTC}
          totalHT={totalHT}
          totalVAT={totalVAT}
        />
        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
          setListRefresh={setListRefresh}
        />
      </div>
    </>
  );
}

export default OrderList;
