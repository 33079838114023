import { useContext, useEffect, useRef, useState } from "react";
import MainHeader from "../../../components/addOns/headers/MainHeader";
import SortByFilter from "../../../components/addOns/filterComponent/SortByFilter";
import SearchComponent from "../../../components/addOns/filterComponent/searchComponent";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";
import { PdfContext } from "../../../context/PdfContext";
import {
  faCopy,
  faDownload,
  faPaperPlane,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/addOns/loader";
import Card from "../../../components/addOns/card";
import roleVerify from "../../../utils/roleVerify";
import { useAuthState } from "../../../context/authContext";
import Paging from "../../../components/addOns/Paging";
import { AppContext } from "../../../context/appContext";
import MailPop from "../../../components/addOns/MailPop";
import { useNavigate } from "react-router-dom";

function DeliveriesList() {

  const postApiContext = useContext(PostApiContext);
  const pdfContext = useContext(PdfContext);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { roleId } = useAuthState();
  const roleList = ["1", "2", "3", "4", "5", "6"];
  const roleVerif = roleVerify(roleList, roleId);

  const [deliveries, setDeliveries] = useState();
  const [reload, setReload] = useState();
  const [sort, setSort] = useState("datedown");
  const [loading, setLoading] = useState(true);

  // Pagination
  const [nbPages, setNbPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [mailData, setMailData] = useState();

  const refresh = () => {
    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    //setSort("refup");
    setReload(true);
  };

  //const for research component
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const societyRef = useRef();
  const invoiceFilterRef = useRef();
  const deliveryRef = useRef();

  const headerContent = {
    ContentName: "BON DE LIVRAISON",
    Content: [
      { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
      { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
      { name: "society", title: "Société", type: "text", ref: societyRef },
      {
        name: "refFacture",
        title: "Référence facture",
        type: "text",
        ref: invoiceFilterRef,
      },
      {
        name: "refDelivery",
        title: "Référence bon de livraison",
        type: "text",
        ref: deliveryRef,
      },
    ],
  };

  useEffect(() => {
    //setMargins(null);
    setLoading(true);

    const values = {
      firstName: firstNameRef?.current?.value,
      lastName: lastNameRef?.current?.value,
      society: societyRef?.current?.value,
      refInvoice: invoiceFilterRef?.current?.value,
      deliveryRef: deliveryRef?.current?.value,
      sort: sort,
    };

    postApiContext
      .postDeliveriesFilterList(values, currentPage, perPage)
      .then((response) => {
        // console.log("response post", response)
        setDeliveries(response.deliveriesList);
        setLoading(false);
        setPerPage(response.perPage);
        setCurrentPage(response.currentPage);
        setNbPages(response.nbPages);
        //setNbPages(response.data.nbPages);
      })
      .catch((error) => {
        console.log("erreur :", error);
      });
    setReload(false);
  }, [sort, reload, postApiContext, currentPage, perPage]);

  const onValidFilter = async (e) => {
    e.preventDefault();
    setReload(true);
  };

  /**
   * options for SortByFilter
   */
  const sortOptions = {
    options: [
      { value: "refup", content: "Références croissantes" },
      { value: "refdown", content: "Références décroissantes" },
      { value: "dateup", content: "Date de création croissante" },
      { value: "datedown", content: "Date de création décroissante" },
    ],
  };

  const objectButtons = [
    // {
    //   title: "Nouveau bon de livraison",
    //   className: "",
    //   icon: faFileMedical,
    //   action: () => navigate("delivery/"),
    //   showByRoleId: true,
    // },
  ];

  const otherButtonList = [
    { title: "Télécharger", icon: faDownload, action: (e) => pdfContext.handlePDF("delivery", e.target.parentNode.parentNode.id, false) },
    { title: "Envoyer", icon: faPaperPlane, action: (e) => {
      let selectedDelivery = deliveries.find((delivery) => delivery.id === e.target.parentNode.parentNode.id);
      setMailData({ id: selectedDelivery.id, title: selectedDelivery.subject });
      appContext.setShowMailPop(true) }},
    { title: "Imprimer", icon: faPrint, action: (e) => pdfContext.handlePDF("delivery", e.target.parentNode.parentNode.id, true) },
    { title: "Dupliquer", icon: faCopy, action: (e) => duplicateDelivery(e.target.parentNode.parentNode.id) },
  ];

  const duplicateDelivery = (id) => {

      postApiContext
        .postDelivery(null, id, null)
        .then((response) => {
          navigate("/invoicesList/deliveriesList/delivery/" + response?.delivery?.id);
        })
        .catch((error) => {
          console.log("erreur post delivery:", error);
        });
    };

  return (
    <>
      <MailPop
        endpoint={"c-delivery-send-email"}
        id={mailData?.id}
        itemName={"Bon_de_livraison"}
        title={mailData?.title}
      />
      <MainHeader
        headerTitle={"BONS DE LIVRAISON"}
        objectButtons={objectButtons}
      />

      {(loading || pdfContext.loading) && <Loader />}

      <div className="listPage">
        <div className="listPage__filterContainer">
          <SearchComponent
            refresh={refresh}
            onSearchSelectChange={""}
            onSearchSubmit={onValidFilter}
            contentObject={headerContent}
            interlocutor={""}
            className={"without-stateFilter"}
          />

          <SortByFilter
            sortOptions={sortOptions}
            setSort={setSort}
            onFilterSubmit={onValidFilter}
          />
        </div>

        {deliveries &&
          deliveries?.map((delivery, index) => {
            const element = {
              id: delivery.id,
              date: delivery.date,
              ref: delivery.ref,
              subject: delivery.subject,
              lastName: delivery.contact?.lastName,
              firstName: delivery.contact?.firstName,
              society: delivery.contact?.society,
              directionPath: "/invoicesList/deliveriesList/delivery/",
              totalHt: delivery?.invoice?.totalHt,
              totalTtc: delivery?.invoice?.totalTtc,
              //initials: quote.userInitials,
            };

            return (
              <Card
                roleVerif={roleVerif}
                key={index}
                element={element}
                tableName={"delivery"}
                stateButtonsList={[]}
                deleteEndPoint={"c-deactive-delivery"}
                otherButtonList={otherButtonList}
                elementTitle={"BON N°"}
                setListRefresh={setReload}
              />
            );
          })}
        <Paging
          perPage={perPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          nbPages={nbPages}
        />
      </div>
    </>
  );
}
export default DeliveriesList;
