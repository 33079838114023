import React, { useEffect } from "react";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import { useRef, useContext, useCallback, useState } from "react";
import { TableContainer } from "@mui/material";
import { PostApiContext } from "../../context/apiCalls/postApiCalls";
import { useNavigate } from "react-router-dom";

export default function AdminConnections() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const nbConnexionRef = useRef();
  const [dateRange, setDateRange] = useState([]);

  const [users, setUsers] = useState([]);
  const [listRefresh, setListRefresh] = useState(false);

  const navigate = useNavigate();
  const postApiCalls = useContext(PostApiContext);

  const onValidFilter = useCallback(
    async (e) => {
      e?.preventDefault();

      const values = {
        lastName: lastNameRef.current?.value,
        firstName: firstNameRef.current?.value,
        nbConnexion: nbConnexionRef.current?.value,
        startDate:
          dateRange && dateRange[0]
            ? dateRange[0].toLocaleDateString().split("T")[0]
            : null,
        endDate:
          dateRange && dateRange[1]
            ? dateRange[1].toLocaleDateString().split("T")[0]
            : null,
      };

      try {
        const response = await postApiCalls.adminConnexionsList(values);
        // console.log(response);
        setUsers(response.users);
      } catch (error) {
        console.log(error);
      }
      // setLoading(false);
    },
    [postApiCalls, dateRange]
  );

  useEffect(() => {
    onValidFilter();
  }, [onValidFilter]);

  useEffect(() => {
    if (listRefresh) {
      onValidFilter();
      setListRefresh(false);
    }
  }, [onValidFilter, listRefresh]);

  const refresh = () => {
    console.log("refresh");

    const formulaire = document.getElementById("searchFilterForm");
    formulaire.reset();
    setDateRange([]);
  };

  return (
    <>
      <EditingMainHeader headerTitle="Connexions" headerButtonsList={{}} />
      <div className="listPage">
        <SearchComponent
          refresh={refresh}
          onSearchSelectChange={onValidFilter}
          onSearchSubmit={onValidFilter}
          setListRefresh={setListRefresh}
          dateRange={dateRange}
          setDateRange={setDateRange}
          contentObject={{
            ContentName: "Client",
            Content: [
              {
                name: "lastName",
                title: "Nom",
                type: "text",
                ref: lastNameRef,
              },
              {
                name: "firstName",
                title: "Prénom",
                type: "text",
                ref: firstNameRef,
              },
              {
                name: "nbConnexion",
                title: "Nombre de connections ( <= ) ",
                type: "text",
                ref: nbConnexionRef,
              },
              {
                name: "dateRange",
                title: "Période de connexion",
                type: "dateRangePicker",
                ref: dateRange,
              },
            ],
          }}
        />
        <h2>Nombre total de connections sur la période : </h2>
        <TableContainer className="admin-connections">
          <table className="admin-connections__table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Société</th>
                <th>Dernière connexion</th>
                <th>Nombre de connections</th>
              </tr>
            </thead>
            <tbody className="admin-connections__table_body">
              {users?.map((user, index) => (
                <tr
                  key={index}
                  className="admin-connections__table_body_row"
                  onClick={() => {
                    navigate(`/admin-customer/${user.user_id}`);
                  }}
                >
                  <td>{user.user_id}</td>
                  <td>{user.u_last_name}</td>
                  <td>{user.u_first_name}</td>
                  <td>{user.society_name}</td>
                  <td>
                    {new Date(user.date_connection).toLocaleDateString("fr-FR")}
                  </td>
                  <td>{user.number_connections}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}
