import { AppContext } from "./../../context/appContext";
import { useContext, useRef, useState } from "react";
import { SendMailApiContext } from "./../../context/apiCalls/sendMailApiCalls";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

export default function MailPop({ endpoint, id, itemName, title }) {
  const appContext = useContext(AppContext);

  const recipientRef = useRef("");
  const subjectRef = useRef("");
  const bodyRef = useRef("");
  const fileNameRef = useRef();

  const [mailSending, setMailSending] = useState(false);

  const SendMailApiCalls = useContext(SendMailApiContext);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    setMailSending(true);

    const values = {
      id: id,
      destinataire: recipientRef.current.value,
      subject: subjectRef.current.value,
      message: bodyRef.current.value,
      filename:
        fileNameRef.current.value && fileNameRef.current.value.trim() !== ""
          ? fileNameRef.current.value
          : itemName
          ? itemName + "_" + title.replace(/ /g, "_")
          : "",
    };

    let response = SendMailApiCalls.sendMail(endpoint, values);
    toast.promise(response, {
      pending: "Envoi du mail en cours ...",
      success: "Mail envoyé avec succès",
      error: "Erreur lors de l'envoi du mail",
    }
    )
    try {
      if (response) {
        appContext.setShowMailPop(false);
        setMailSending(false);
      }
    } catch (error) {
      console.log("error", error);
      appContext.setShowMailPop(false);
      setMailSending(false);
    }
  };

  const showMailPop = (id) => {
    appContext.setShowMailPop(!appContext.showMailPop);
    appContext.setMailId(id);
  };

  return (
    <>
      {appContext.showMailPop && (
        <>
          <div
            onClick={() => appContext.setShowMailPop(false)}
            className="bluredDiv"
          ></div>
          <form className="mailpop itemPop" onSubmit={handleSubmit}>
            <div className="mailpopHeader">
              <h3>Envoyer un mail</h3>
              <FontAwesomeIcon
                className="cross"
                onClick={showMailPop}
                icon={faXmark}
              />
            </div>
            <div className="mailpopContainer">
              <label>Mail : </label>
              <input
                ref={recipientRef}
                type="email"
                placeholder="Destinataire"
                required
              />

              <label>Objet : </label>
              <input
                ref={subjectRef}
                type="text"
                placeholder="Sujet"
              />

              <label>Nom du fichier : </label>
              <input
                ref={fileNameRef}
                type="text"
                placeholder={
                  itemName && title
                    ? itemName + "_" + title?.replace(/ /g, "_")
                    : "Nom du fichier"
                }
              />
              <label>Message : </label>
              <textarea ref={bodyRef} placeholder="Message ..." />

              <button type="submit" disabled={mailSending}>
                Envoyer
                </button>
            </div>
          </form>
        </>
      )}
    </>
  );
}
